import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useAuth } from "../auth/AuthContext";
import LicenceTable from "./LicenceTable";
import axios from "axios";
import LicenceIcon from "../../images/Award.png";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { Icon } from "@iconify/react";
import starIcon from "@iconify/icons-el/star";
import { withTranslation } from "react-i18next";
import "../i18n";

const LicenceRegistration = withTranslation()((props) => {
  const [adding, setAdding] = React.useState(false);
  const [emailInput, setEmailInput] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [licences, setLicences] = React.useState([]);
  const [licenceQt, setLicenceQt] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [inputRole, setInputRole] = React.useState("teacher");
  const { getToken, accountInfo, sendSignInLinkToEmail } = useAuth();
  const getLicences = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            accountInfo.org +
            "/licences/",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          var tempLicences = [];
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].email != "none") {
              tempLicences.push(response.data[index]);
            }
          }

          setLicenceQt(response.data.length);
          setLicences(tempLicences);
          setLoading(false);
          return;
        });
    });
  };

  const handleClickOpen = () => {
    setLoading(true);
    getLicences();
    setOpen(true);
  };

  const handleClose = () => {
    props.closeDrawer();
    setOpen(false);
  };

  const handleLicenceActivation = () => {
    setAdding(false);
    // for later
    var body = { email: emailInput, role: inputRole, used: 0 };
    getToken().then((idToken) => {
      axios
        .put(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            accountInfo.org +
            "/licences",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "No Licences available") {
            sendSignInLinkToEmail([emailInput]);
            setLicences([
              ...licences,
              {
                id: response.data.id,
                email: emailInput,
                role: inputRole,
                used: 0,
              },
            ]);
            //setLicenceQt(licenceQt );
          }
        });
    });
  };

  const handleLicenceDeactivation = (id) => {
    var body = { email: "none", role: "none", used: 0, name: "none", org: 0 };
    getToken().then((idToken) => {
      axios
        .put(
          process.env.REACT_APP_RESOURCE_SERVER + "/api/licences/" + id,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "No Licences available") {
            setLicences([...licences.filter((licence) => licence.id != id)]);
            setLicenceQt(licenceQt);
          }
        });
    });
  };

  const handleInputRoleChange = (event) => {
    setInputRole(event.target.value);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <img src={LicenceIcon} style={{ marginLeft: "1px" }}></img>
        </ListItemIcon>
        <ListItemText primary={props.t("licenceReg")} />
      </ListItem>
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span>{props.t("licenceReg")}</span>{" "}
          <span className="department-name">
            <Icon
              icon={starIcon}
              style={{
                marginLeft: "1px",
                color: "#f50057",
                fontSize: "22px",
              }}
            />
            {accountInfo.name}
          </span>
        </DialogTitle>
        {!loading && (
          <DialogContent>
            {props.t("youHaveGiven") +
              " " +
              licences.length +
              " " +
              props.t("licencesOf")}{" "}
            {licenceQt}
            <LicenceTable
              inputRole={inputRole}
              handleLicenceDeactivation={handleLicenceDeactivation}
              handleLicenceActivation={handleLicenceActivation}
              handleInputRoleChange={handleInputRoleChange}
              licences={licences}
              email={emailInput}
              setEmailInput={setEmailInput}
              adding={adding}
              setAdding={setAdding}
            ></LicenceTable>
          </DialogContent>
        )}
        {loading && (
          <DialogContent>
            <Typography variant="h3">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="h3">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="h3">
              <Skeleton width="100%" />
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary"></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default LicenceRegistration;
