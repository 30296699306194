import React from "react";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import StudentTable from "../StudentTable";
import DialogLayout from "../layout/DialogLayout";
import { TextField as YlTextField, SelectField } from "../InputFields";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  primaryBtn: {
    background: theme.palette.primary.main,
  },
}));

const AddCollectionDialog = withTranslation()((props) => {
  useAuth();
  const [error, setError] = React.useState(false);
  const [collectionName, setCollectionName] = React.useState("");
  const { getToken } = useAuth();

  const classes = useStyles();
  const saveVideo = (id) => {
    console.log(id);
    console.log(props.video);
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${id}/videos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            oldId: props.video.id,
            name: dbFormat(props.video.name),
            youtubeId: props.video.youtube_id,
            subcollectionId: -2,
            start: props.video.start,
            ending: props.video.ending,
            description: props.video.description,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          getToken()
            .then((idToken) => {
              fetch(
                `${process.env.REACT_APP_RESOURCE_SERVER}/api/accounts/${props.currentUser.uid}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                  },
                  body: JSON.stringify({
                    videoId: data.id,
                    collectionId: id,
                    category: "personal",
                  }),
                }
              ).then((res) => {
                if (res.ok) {
                  window.location.href = process.env.REACT_APP_FRONT_END;
                }
              });
            })
            .catch((err) => {
              /* eslint-disable no-console */
              console.error(err);
            });
        });
    });
  };
  const handleClose = () => {
    setCollectionName("");
    props.setOpen(false);
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${props.currentUser.uid}/collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            name: dbFormat(collectionName),
            category: "personal",
            email: "",
            org_name: "misc",
            type: 1,
            students: [],
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          saveVideo(data.id);
        })
        .catch((err) => {});
      props.setOpen(false);
      setCollectionName("");
    });
  };

  return (
    <DialogLayout
      handleClose={handleClose}
      handleSave={handleSave}
      displayPrimaryBtn={true}
      displaySecondaryBtn={true}
      primaryBtnText={props.t("save")}
      secondaryBtnText={props.t("cancel")}
      title={props.t("add") + " " + props.t("collection")}
      maxWidth="md"
      open={props.open}
    >
      <React.Fragment>
        <p>{props.t("chooseColName")}</p>
        <form onSubmit={handleSave}>
          <YlTextField
            onSubmit={handleSave}
            autoFocus
            margin="dense"
            id="name"
            label={props.t("name")}
            type="text"
            helperText={error ? props.t("chooseName") : ""}
            value={collectionName}
            onChange={setCollectionName}
            fullWidth
          />
        </form>
      </React.Fragment>
    </DialogLayout>
  );
});

export default AddCollectionDialog;
