import React from "react";
import ListItem from "@material-ui/core/ListItem";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

export default function Attachment(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadLink = () => {
    if (props.link) {
      var link = "";
      if (props.link.includes("drive.google.com")) {
        var splitLink = props.link.split("/");
        if (splitLink[splitLink.length - 2]) {
          var fileId = splitLink[splitLink.length - 2];
        } else {
          return;
        }
        link =
          "https://drive.google.com/u/0/uc?id=" + fileId + "&export=download";
      }
      if (props.link.includes("dropbox")) {
        const linkArr = props.link.split("?dl=0");
        if (linkArr.length == 2) {
          link = linkArr[0] + "?dl=1" + linkArr[1];
        } else {
          link = props.link.slice(0, -1) + "1";
        }
      }
      if (link === "") {
        return;
      }
      const newWindow = window.open(link, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
    handleClose();
  };

  const deleteLink = () => {
    props.deleteAttachment(props.id);
    handleClose();
  };

  const openLink = () => {
    if (props.link) {
      var link = "";

      if (props.link.includes("drive.google.com")) {
        var splitLink = props.link.split("/");
        if (splitLink[splitLink.length - 2]) {
          var fileId = splitLink[splitLink.length - 2];
        } else {
          return;
        }
        link =
          "https://drive.google.com/file/d/" + fileId + "/view?usp=sharing";
      } else if (props.link.includes("dropbox")) {
        link = props.link.slice(0, -1) + "0";
      } else if (props.link.includes("1drv.ms")) {
        link = props.link;
      }
      if (link === "") {
        return;
      }
      const newWindow = window.open(link, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
    handleClose();
  };

  return (
    <div>
      <ListItem
        button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.name}
      </ListItem>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={openLink}>Opna</MenuItem>
        <MenuItem onClick={downloadLink}>Sækja</MenuItem>
        {!props.student && <MenuItem onClick={deleteLink}>Eyða</MenuItem>}
      </Menu>
    </div>
  );
}
