import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <Grid container>
        {/*
          <Grid item xs={12} sm={3}>
            <Typography variant="caption" component="h3">
              WE WOULD LOVE TO GET YOUR FEEDBACK!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" component="p">
              This tool is made by enthusiastic and motivated guitarist that
              really needed that dududuud ududud Andri skrifar eitthvað hér voða
              sexí dududuud ududud Andri skrifar eitthvað hér voða sexí meira
              yay
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" component="p">
              If you like this tool and would like to support our development
              dudududu
            </Typography>
          </Grid>
          */}
        </Grid>
      </div>
    );
  }
}

export default Footer;
