import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import turtleIcon from "@iconify/icons-emojione-monotone/turtle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Icon } from "@iconify/react";
import IconButton from "@material-ui/core/IconButton";
import rabbitIcon from "@iconify/icons-mdi/rabbit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { isIPad13 } from "react-device-detect";
// boolean

const allSpeeds = [
  Number(0.25),
  Number(0.3),
  Number(0.35),
  Number(0.4),
  Number(0.45),
  Number(0.5),
  Number(0.55),
  Number(0.6),
  Number(0.65),
  Number(0.7),
  Number(0.75),
  Number(0.8),
  Number(0.85),
  Number(0.9),
  Number(0.95),
  Number(1),
  Number(1.05),
  Number(1.1),
  Number(1.15),
  Number(1.2),
  Number(1.25),
  Number(1.3),
  Number(1.35),
  Number(1.4),
  Number(1.45),
  Number(1.5),
  Number(1.55),
  Number(1.6),
  Number(1.65),
  Number(1.7),
  Number(1.75),
  Number(1.8),
  Number(1.85),
  Number(1.9),
  Number(1.95),
  Number(2),
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    // marginTop: "8px",
    // marginRight: "0px",
    // paddingRight: "0px !important",
    // paddingTop: "0px",
    // paddingBottom: "0px",
    border: "none",
  },
  selectEmpty: {
    marginTop: 20,
  },
  icon: {
    display: "none",
  },
  outlined: {
    paddingRight: "14px !important",
  },
}));

const SpeedButton = withStyles({
  root: {
    margin: "0 0.5%",
    borderRadius: "50%",
    backgroundColor: "transparent",
    borderStyle: "Solid",
    borderColor: "#605DF9",
    borderWidth: "2px",
    height: "16px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
})(IconButton);

const SpeedSelect = withStyles({
  root: {
    borderColor: "black",
  },
  icon: {
    display: "none",
  },
  select: {
    paddingBottom: "10px",
    paddingTop: "2px",
    paddingRight: "0px",
    //textAlign: 'center',
    fontWeight: "bold",
  },
})(Select);

export default function SpeedControls(props) {
  const classes = useStyles();

  return (
    <div className="speed-control-container">
      <SpeedButton
        onClick={() => props.decrease()}
        style={{ marginRight: "-7px", zIndex: "1000" }}
      >
        {isIPad13 ? (
          <RemoveIcon style={{ fontSize: "20px", marginTop: 0 }} />
        ) : (
          <RemoveIcon style={{ fontSize: "20px" }} />
        )}
      </SpeedButton>
      <FormControl
        color="primary"
        variant="outlined"
        //style={{paddingRight: "14px !important"}}
        className={classes.formControl}
      >
        {/* <InputLabel style={{ marginLeft: "3.5px" }}>hraði</InputLabel> */}
        <SpeedSelect
          native
          icon="false"
          value={props.speed}
          onChange={props.changeSpeed}
          label="Speed"
          inputProps={{
            name: "speed",
            id: "outlined-speed-native-simple",
          }}
        >
          {allSpeeds.map((speed, index) => (
            <option key={index} value={speed}>
              {" "}
              {Math.round(speed * 100) + "%"}
            </option>
          ))}
        </SpeedSelect>
      </FormControl>
      <SpeedButton
        onClick={() => props.increase()}
        style={{ marginLeft: "-7px", zIndex: "1000" }}
      >
        {isIPad13 ? (
          <AddIcon style={{ fontSize: "20px", marginTop: 0 }} />
        ) : (
          <AddIcon style={{ fontSize: "20px" }} />
        )}
        {/* <Icon icon={AddIcon} style={{ color: "#605df9", fontSize: "20px" }} /> */}
      </SpeedButton>
    </div>
  );
}
