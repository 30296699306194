import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";

const SortingOptions = (props) => {
  return (
    <PopupState variant="popover" popupId="segment-sort-popover">
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>
            <SortByAlphaIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box>
              <List>
                {props.optionList.map((opt, index) => (
                  <ListItem
                    key={index}
                    dense
                    onClick={() => props.changeSelected(index)}
                    button
                  >
                    <ListItemIcon>
                      {props.selected === index ? <CheckIcon /> : ""}
                    </ListItemIcon>
                    <ListItemText primary={opt} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default SortingOptions;
