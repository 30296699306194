import React from "react";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import StudentTable from "../StudentTable";
import DialogLayout from "../layout/DialogLayout";
import { TextField as YlTextField, SelectField } from "../InputFields";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  primaryBtn: {
    background: theme.palette.primary.main,
  },
}));

const AddCollectionDialog = withTranslation()((props) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [collectionName, setCollectionName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [collective, setCollective] = React.useState(props.t("privateLessons"));
  const [choosing, setChoosing] = React.useState(true);
  const options = [props.t("groupLessons"), props.t("privateLessons")];
  const [students, setStudents] = React.useState([]);
  const { getToken, sendSignInLinkToEmail, accountInfo, displayMessage } =
    useAuth();

  const classes = useStyles();
  const handleClickOpen = () => {
    setTimeout(() => {
      if (document.getElementById("confirm-type")) {
        document.getElementById("confirm-type").focus();
      }
    }, 200);
    setOpen(true);
  };

  const handleClose = () => {
    setChoosing(true);
    setStudents([]);
    setCollectionName("");
    setEmail("");
    setOpen(false);
  };

  const handleAddStudent = () => {
    setEmail("");
    setStudents([...students, email]);
  };

  const handleStudentRemove = (removeEmail) => {
    setEmail("");
    setStudents([
      ...students.filter((student) => {
        return student != removeEmail;
      }),
    ]);
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    var type = 0;
    if (collective == props.t("privateLessons")) {
      type = 1;
    }
    if (collective == props.t("groupLessons")) {
      type = 2;
    }

    if (collectionName == "") {
      displayMessage("error", props.t("chooseName"));
      return;
    }
    if (type == 1 && props.category == "students") {
      if (email == "") {
        displayMessage("error", props.t("pleaseAddStud"));
        return;
      }
    }
    if (type == 2) {
      if (students.length == 0 && props.category == "students") {
        displayMessage("error", props.t("pleaseAddLeastOneStud"));
        return;
      }
    }
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${props.userId}/collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            name: dbFormat(collectionName),
            category: props.category,
            org_name: accountInfo.name,
            email: email,
            students: students,
            type: type,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          if (students.length != 0) {
            sendSignInLinkToEmail(students);
          } else if (email != "") {
            sendSignInLinkToEmail([email]);
          }
          if (props.category == "personal") {
            displayMessage(
              "success",
              '"' +
                collectionName +
                '" ' +
                props.t("wasAddedTo") +
                ' "' +
                props.t("personal") +
                '"'
            );
          } else if (props.category == "students") {
            displayMessage(
              "success",
              '"' + collectionName + '" ' + props.t("wasAdded")
            );
          } else {
            const currDep = accountInfo.departments.filter((dep) => {
              return dep.id == props.category;
            });
            if (currDep.length != 0) {
              displayMessage(
                "success",
                '"' +
                  collectionName +
                  '" ' +
                  props.t("wasAddedTo") +
                  ' "' +
                  currDep[0].name +
                  '"'
              );
            }
          }
          props.addCollection({
            teacher: true,
            name: collectionName,
            id: data.id,
          });
        })
        .catch((err) => {});
      setChoosing(true);
      setStudents([]);
      setEmail("");
      setOpen(false);
      setCollectionName("");
    });
  };

  return (
    <div className="add-collection-btn">
      <Tooltip title={props.t("newCol")} arrow>
        <IconButton
          onClick={handleClickOpen}
          color="white"
          size="medium"
          className="add-collection-btn"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={!(choosing && props.category == "students")}
        displaySecondaryBtn={true}
        primaryBtnText={props.t("save")}
        secondaryBtnText={props.t("cancel")}
        title={props.t("add") + " " + props.t("collection")}
        maxWidth="md"
        open={open}
      >
        {choosing && props.category == "students" && (
          <React.Fragment>
            <DialogContentText>{props.t("studColType")}</DialogContentText>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setChoosing(false);
              }}
            >
              <Select
                helperText={error ? props.t("selectCol") : ""}
                labelId="collection-select-label"
                id="collection-select"
                value={collective}
                onChange={(e) => {
                  setTimeout(() => {
                    if (document.getElementById("confirm-type")) {
                      document.getElementById("confirm-type").focus();
                    }
                  }, 100);
                  setCollective(e.target.value);
                }}
              >
                {choosing &&
                  options.map((option) => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
              </Select>
            </form>
            <br></br>
            <Button
              id="confirm-type"
              className={classes.primaryBtn}
              style={{ marginTop: "10px" }}
              onClick={() => {
                setChoosing(false);
              }}
              color="primary"
            >
              {props.t("choose")}
            </Button>
          </React.Fragment>
        )}
        {!(choosing && props.category == "students") && (
          <React.Fragment>
            <p>{props.t("chooseColName")}</p>
            <form onSubmit={handleSave}>
              <YlTextField
                onSubmit={handleSave}
                autoFocus
                margin="dense"
                id="name"
                label={props.t("name")}
                type="text"
                helperText={error ? props.t("chooseName") : ""}
                value={collectionName}
                onChange={setCollectionName}
                fullWidth
              />
            </form>
            {props.category == "students" &&
              collective == props.t("privateLessons") && (
                <React.Fragment>
                  <p>
                    {props.t("enterStudEmail")}
                    <br></br>
                  </p>
                  <form onSubmit={handleSave}>
                    <YlTextField
                      margin="dense"
                      id="name"
                      label="Netfang"
                      type="email"
                      value={email}
                      onChange={setEmail}
                      fullWidth
                    />
                  </form>
                </React.Fragment>
              )}
            {props.category == "students" &&
              collective == props.t("groupLessons") && (
                <React.Fragment>
                  <p>
                    {props.t("enterStudsEmail")}
                    <br></br>
                    {props.t("editLater")}
                  </p>
                  <StudentTable
                    students={students}
                    setEmail={setEmail}
                    email={email}
                    setStudents={setStudents}
                    handleAddStudent={handleAddStudent}
                    handleStudentRemove={handleStudentRemove}
                  ></StudentTable>
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      </DialogLayout>
    </div>
  );
});

export default AddCollectionDialog;
