import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StudentTable from "../StudentTable";
import MenuItem from "@material-ui/core/MenuItem";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import "../i18n";
import { withTranslation } from "react-i18next";

const ShareDialog = withTranslation()((props) => {
  const [fetching, setFetching] = React.useState(true);
  const [students, setStudents] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const { currentUser, getToken, accountInfo } = useAuth();
  const [changes, setChanges] = React.useState(false);

  const handleClickOpen = () => {
    setFetching(true);
    setEmail("");
    props.setContextState(initialState);
    setOpen(true);
    setStudents(null);
    setChanges(false);
    getStudent();
  };

  const handleAddStudent = () => {
    setEmail("");
    setStudents([...students, email]);
    setChanges(true);
  };

  const handleStudentRemove = (removeEmail) => {
    setEmail("");
    setStudents([
      ...students.filter((student) => {
        return student != removeEmail;
      }),
    ]);
    setChanges(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const getStudent = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/student_collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            uid: "get",
            name: dbFormat(props.rightClickedItemName),
            shared: true,
            email: email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            setFetching(false);
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          var studentArray = [];
          for (let index = 0; index < data.students.length; index++) {
            studentArray.push(data.students[index].user_id);
          }
          setStudents(studentArray);
          setFetching(false);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleSave = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/student_collections/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            uid: currentUser.uid,
            name: dbFormat(props.rightClickedItemName),
            shared: true,
            email: email,
            students: students,
            org_name: accountInfo.name,
          }),
        }
      ).then((result) => {
        setChanges(false);
      });
    });
  };

  return (
    <React.Fragment>
      {props.category == "personal" && (
        <MenuItem onClick={handleClickOpen}>{props.t("shareCol")}</MenuItem>
      )}
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.t("shareAccessTo")}"{props.rightClickedItemName}"
        </DialogTitle>
        {!fetching && (
          <DialogContent>
            <StudentTable
              max={false}
              students={students}
              setEmail={setEmail}
              email={email}
              setStudents={setStudents}
              handleAddStudent={handleAddStudent}
              handleStudentRemove={handleStudentRemove}
            ></StudentTable>
            <Button
              disabled={!changes}
              style={{ marginTop: "10px" }}
              onClick={handleSave}
              color="primary"
            >
              {props.t("saveChanges")}
            </Button>
          </DialogContent>
        )}
        {fetching && (
          <DialogContent>
            <Typography style={{ marginBottom: "10px" }} variant="body1">
              <Skeleton width="50%" />
            </Typography>
            <Typography variant="caption">
              <Skeleton width="100%" />
            </Typography>
            <Typography style={{ marginBottom: "-5px" }} variant="h3">
              <Skeleton width="20%" />
            </Typography>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default ShareDialog;
