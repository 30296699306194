import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/youtube-logo.svg";
import { ReactComponent as YouTubeLogoOff } from "../../assets/icons/youtube-off-logo.svg";
import "../i18n";
import { withTranslation } from "react-i18next";

const SettingsMenu = withStyles({
  zIndex: 5000,
  list: {
    padding: 0,
  },

  paper: {
    // border: "1px solid #d3d4d5",
    zIndex: 5000,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
));

const SettingsMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    // "&:focus": {
    //   backgroundColor: theme.palette.primary.main,
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

const SettingsButton = withStyles({
  root: {
    // marginLeft: "-8px",
    // marginRight: "0.7%",
    padding: 7,
  },
})(IconButton);

const VideoSettings = withTranslation()((props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleYTControls = () => {
    props.toggleYTControls();
    handleClose();
  };

  return (
    <div>
      <SettingsButton
        aria-controls="settings-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        component="span"
        onClick={handleClick}
      >
        <SettingsIcon />
      </SettingsButton>
      <SettingsMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <Grid item md={6}>
                  <StdButton
                    onClick={this.toggleControls}
                    className="YT-text-btn"
                  >
                    YT spilari:
                    {this.state.controls ? <b> Kveikt</b> : <b> Slökkt</b>}
                  </StdButton>
                  <Tooltip title="Býður uppá ýtarlegri hraðamöguleika" arrow>
                    <InfoOutlinedIcon
                      Filled
                      color="primary"
                      style={{ marginBottom: "-3px", fontSize: "15px" }}
                    ></InfoOutlinedIcon>
                  </Tooltip>
                </Grid> */}
        <SettingsMenuItem onClick={toggleYTControls}>
          <ListItemIcon>
            {props.YTControlsOn ? <YouTubeLogoOff /> : <YouTubeLogo />}
          </ListItemIcon>
          <ListItemText
            primary={
              props.YTControlsOn ? props.t("ytCtrlOff") : props.t("ytCtrlOn")
            }
          />

          <ListItemSecondaryAction>
            <Tooltip title={props.t("enablesCustomSpeed")} arrow>
              <InfoOutlinedIcon
                Filled
                style={{
                  marginBottom: "-5px",
                  fontSize: "20px",
                  fill: "#a4a3ee",
                }}
              ></InfoOutlinedIcon>
            </Tooltip>
          </ListItemSecondaryAction>
        </SettingsMenuItem>
      </SettingsMenu>
    </div>
  );
});

export default VideoSettings;
