import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputPlaylistUrl from "./InputPlaylistUrl";
import youtube from "../apis/youtube";
import { dynamicSort } from "../../helpers";
import { TextField as YlTextField } from "../InputFields";
import { ptFormatToSeconds } from "../../helpers";
import DialogLayout from "../layout/DialogLayout";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
}));

const AddSubcollection = withTranslation()((props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [subcollectionName, setSubcollectionName] = useState("");
  const [playListId, setPlayListId] = useState(null);
  const [alpha, setAlpha] = useState(false);

  const fetchVideoDetailsHelper = async (items, prevBuffer, vids) => {
    var buffer = prevBuffer + 50;
    var final = false;
    if (items.length <= buffer) {
      buffer = items.length;
      final = true;
    }
    var idString = "";
    for (let index = prevBuffer; index < buffer; index++) {
      idString += items[index].snippet.resourceId.videoId + ",";
    }
    youtube
      .get("/videos", {
        params: {
          part: "contentDetails, snippet",
          id: idString.slice(0, -1),
          maxResults: 50,
        },
      })
      .then((result) => {
        if (final) {
          props.addSubcollection(
            subcollectionName,
            formatPlaylist(vids.concat(result.data.items))
          );
        } else {
          fetchVideoDetailsHelper(
            items,
            buffer,
            vids.concat(result.data.items)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatPlaylist = (playList) => {
    if (!playList) {
      return null;
    }
    var formattedPlaylist = [];
    for (let index = 0; index < playList.length; index++) {
      formattedPlaylist.push({
        name: playList[index].snippet.title,
        start: 0,
        ending: ptFormatToSeconds(playList[index].contentDetails.duration),
        youtubeId: playList[index].id,
      });
    }
    if (alpha) {
      formattedPlaylist.sort(dynamicSort("name"));
    }
    return formattedPlaylist;
  };

  const fetchVideoDetails = async (items) => {
    var buffer = 50;
    var final = false;
    if (items.length <= 50) {
      buffer = items.length;
      final = true;
    }
    var idString = "";
    for (let index = 0; index < buffer; index++) {
      idString += items[index].snippet.resourceId.videoId + ",";
    }
    youtube
      .get("/videos", {
        params: {
          part: "contentDetails, snippet",
          id: idString.slice(0, -1),
          maxResults: 50,
        },
      })
      .then((result) => {
        if (final) {
          props.addSubcollection(
            subcollectionName,
            formatPlaylist(result.data.items)
          );
        } else {
          fetchVideoDetailsHelper(items, 50, result.data.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPlayListItemsHelper = async (id, token, videoArray) => {
    return youtube
      .get("/playlistItems", {
        params: {
          playlistId: id,
          maxResults: 50,
          pageToken: token,
        },
      })
      .then((result) => {
        if (result.data && result.data.items) {
          if (result.data.nextPageToken) {
            return fetchPlayListItemsHelper(
              id,
              result.data.nextPageToken,
              videoArray.concat(result.data.items)
            );
          } else {
            fetchVideoDetails(videoArray.concat(result.data.items));
            return videoArray.concat(result.data.items);
          }
        }
      });
  };

  const fetchPlayListItems = (id) => {
    youtube
      .get("/playlistItems", {
        params: {
          playlistId: id,
          maxResults: 50,
        },
      })
      .then((result) => {
        if (result.data && result.data.items) {
          if (result.data.nextPageToken) {
            return fetchPlayListItemsHelper(
              id,
              result.data.nextPageToken,
              result.data.items
            );
          } else {
            fetchVideoDetails(result.data.items);
            return result.data.items;
          }
        }
      });
  };

  useEffect(() => {
    if (props.open === true) setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setPlayListId(null);
    setSubcollectionName("");
    setOpen(false);
    props.handleClose();
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (subcollectionName == "") {
      setError(true);
      return;
    }
    if (playListId) {
      fetchPlayListItems(playListId);
    } else {
      props.addSubcollection(subcollectionName);
    }

    handleClose();
  };

  return (
    <DialogLayout
      handleClose={handleClose}
      handleSave={handleSave}
      displayPrimaryBtn={subcollectionName}
      displaySecondaryBtn={true}
      primaryBtnText={props.t("save")}
      secondaryBtnText={props.t("cancel")}
      title={props.t("addNewSub")}
      maxWidth="md"
      open={open}
    >
      <p>{props.t("chooseSubName")}</p>
      <form onSubmit={(e) => handleSave(e)}>
        <YlTextField
          autoFocus
          margin="dense"
          id="name"
          label={props.t("name")}
          type="text"
          helperText={error ? props.t("cancel") : ""}
          value={subcollectionName}
          onChange={setSubcollectionName}
          fullWidth
        />
      </form>
      {
        <React.Fragment>
          <p>{props.t("pastePlaylistUrl")}</p>
          <div>
            <InputPlaylistUrl
              handleSave={handleSave}
              setPlayListId={setPlayListId}
              setSubcollectionName={setSubcollectionName}
            />
            {playListId && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alpha}
                    color={"primary"}
                    onChange={(e) => setAlpha(e.target.checked)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={props.t("arrangeAlpha")}
              />
            )}
          </div>
        </React.Fragment>
      }
    </DialogLayout>
  );
});

export default AddSubcollection;
