import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import axios from "axios-jsonp-pro";
import { IconButton } from "@material-ui/core";
import "../i18n";
import { withTranslation } from "react-i18next";

const AutocompleteSearch = withTranslation()((props) => {
  const [options, setOptions] = React.useState([]);
  const change = (e) => {
    //props.setSearchString(e.target.value);
    axios
      .jsonp(
        "https://clients1.google.com/complete/search?client=youtube&gs_ri=youtube&ds=yt&q=" +
          e.target.value
      )
      .then((response) => {
        setOptions(
          response[1]
            .map((item) => {
              return item[0];
            })
            .slice(0, 7)
        );
      })
      .catch((error) => {});
  };
  const adornment = (
    <IconButton style={{ verticalAlign: "middle" }}>
      <SearchIcon onClick={() => props.selectSearch(props.searchString)} />
    </IconButton>
  );
  return (
    <Autocomplete
      freeSolo
      value={props.searchString}
      id="free-solo"
      onInputChange={(e, v, reason) => {
        props.setSearchString(v);
        if (reason == "reset") {
          props.selectSearch(v);
        }
      }}
      //startAdornment="true"
      options={options}
      renderInput={(params) => {
        params.InputProps.endAdornment = { SearchIcon };
        return (
          <TextField
            {...params}
            className="search-box"
            value={props.searchString}
            autoFocus
            margin="normal"
            placeholder={props.t("ytSearch")}
            variant="standard"
            onChange={change}
            InputProps={{
              ...params.InputProps,
              type: "search",
              endAdornment: adornment,
            }}
          />
        );
      }}
    />
  );
});

export default AutocompleteSearch;
