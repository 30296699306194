/**
 * Helper function that watches the authenticate state, then applies it
 * as a boolean (authenticated) as well as attaches the userinfo data.
 */
async function checkAuthentication() {
  const authenticated = await this.props.auth.isAuthenticated();
  if (authenticated !== this.state.authenticated) {
    if (authenticated && !this.state.userinfo) {
      const userinfo = await this.props.auth.getUser();
      this.setState({ authenticated, userinfo });
    } else {
      this.setState({ authenticated });
    }
  }
}

// The format of the string has to be hh:mm:ss
export function timeToSeconds(timeString) {
  var timeStringArr = timeString.split(":");
  var totalSeconds = 0;
  if (timeStringArr.length === 3) {
    totalSeconds =
      Number(timeStringArr[0]) * 60 * 60 +
      Number(timeStringArr[1]) * 60 +
      Number(timeStringArr[2]);
  } else if (timeStringArr.length === 2) {
    totalSeconds = Number(timeStringArr[0]) * 60 + Number(timeStringArr[1]);
  }

  return totalSeconds;
}
function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase();
}
export function parseSegments(description) {
  var descArray = description.split(/\r?\n/);
  var segments = [];
  for (let index = 0; index < descArray.length; index++) {
    var timeStamp = descArray[index].split(" ")[0];
    var timeValues = timeStamp.split(":");
    var seconds = -1;
    if (timeValues.length == 2) {
      seconds = Number(timeValues[0]) * 60 + Number(timeValues[1]);
    } else if (timeValues.length == 3) {
      seconds =
        Number(timeValues[0]) * 60 * 60 +
        Number(timeValues[1]) * 60 +
        Number(timeValues[2]);
    }
    if (seconds != -1 && !isNaN(seconds)) {
      var name = timeStamp;
      for (let indexTwo = 0; indexTwo < descArray[index].length; indexTwo++) {
        if (isLetter(descArray[index][indexTwo])) {
          name = descArray[index].slice(indexTwo);
          break;
        }
      }
      segments.push({ start: seconds, name: name });
    }
  }
  if (segments.length > 1) {
    return segments;
  } else {
    segments = [];
    for (let index = 0; index < descArray.length; index++) {
      var tempArray = descArray[index].split(" ");
      timeStamp = tempArray[tempArray.length - 1];
      timeValues = timeStamp.split(":");
      seconds = -1;
      if (timeValues.length == 2) {
        seconds = Number(timeValues[0]) * 60 + Number(timeValues[1]);
      } else if (timeValues.length == 3) {
        seconds =
          Number(timeValues[0]) * 60 * 60 +
          Number(timeValues[1]) * 60 +
          Number(timeValues[2]);
      }
      if (seconds != -1 && !isNaN(seconds)) {
        name = descArray[index].slice(
          0,
          descArray[index].length - timeStamp.length
        );

        segments.push({ start: seconds, name: name });
      }
    }
  }
  if (segments.length > 1) {
    return segments;
  } else {
    return null;
  }
}

export function ptFormatToSeconds(pt) {
  var hSplit = pt.slice(2, -1).split("H");
  if (hSplit.length > 1) {
    var minSec = hSplit[1].split("M");
    return (
      Number(hSplit[0][0]) * 60 * 60 +
      Number(minSec[0]) * 60 +
      Number(minSec[1])
    );
  } else {
    var minSec = hSplit[0].split("M");
    if (minSec.length > 1) {
      return Number(minSec[0]) * 60 + Number(minSec[1]);
    } else {
      return Number(minSec[0]);
    }
  }
}

export function timeToString(timeSeconds) {
  if (timeSeconds < 0) {
    return "00:00";
  }
  var timeUnits = [];
  var centiSeconds = timeSeconds % 1;
  timeSeconds -= centiSeconds;
  centiSeconds *= 60;
  centiSeconds = Math.round(centiSeconds);
  timeUnits.push(centiSeconds);
  var seconds = timeSeconds % 60;
  timeSeconds -= seconds;
  timeUnits.push(seconds);
  var minutes = timeSeconds % 3600;
  timeSeconds -= minutes;
  minutes /= 60;
  timeUnits.push(minutes);
  var hours = timeSeconds;
  hours /= 3600;
  timeUnits.push(hours);
  for (let index = 0; index < timeUnits.length; index++) {
    if (timeUnits[index] < 10) {
      timeUnits[index] = "0" + timeUnits[index].toString();
    } else {
      timeUnits[index] = timeUnits[index].toString();
    }
  }
  var timeString =
    (timeUnits[3] !== "00" ? timeUnits[3] + ":" : "") +
    timeUnits[2] +
    ":" +
    timeUnits[1];
  return timeString;
}

export function timeToWholeString(timeSeconds) {
  var timeUnits = [];
  var centiSeconds = timeSeconds % 1;
  timeSeconds -= centiSeconds;
  centiSeconds *= 60;
  centiSeconds = Math.round(centiSeconds);
  timeUnits.push(centiSeconds);
  var seconds = timeSeconds % 60;
  timeSeconds -= seconds;
  timeUnits.push(seconds);
  var minutes = timeSeconds % 3600;
  timeSeconds -= minutes;
  minutes /= 60;
  timeUnits.push(minutes);
  var hours = timeSeconds;
  hours /= 3600;
  timeUnits.push(hours);
  for (let index = 0; index < timeUnits.length; index++) {
    if (timeUnits[index] < 10) {
      timeUnits[index] = "0" + timeUnits[index].toString();
    } else {
      timeUnits[index] = timeUnits[index].toString();
    }
  }
  var timeString = timeUnits[3] + ":" + timeUnits[2] + ":" + timeUnits[1];

  return timeString;
}

export function dbFormat(string) {
  var splitString = string.split("'");
  var dbString = "";
  for (let index = 0; index < splitString.length; index++) {
    if (splitString.length - 1 == index) {
      dbString += splitString[index];
    } else {
      dbString += splitString[index] + "''";
    }
  }
  return dbString;
}

export function normalFormat(string) {
  var splitString = string.split("''");
  var dbString = "";
  for (let index = 0; index < splitString.length; index++) {
    if (splitString.length - 1 == index) {
      dbString += splitString[index];
    } else {
      dbString += splitString[index] + "'";
    }
  }
  return dbString;
}

export function getFileType(value) {
  if (value.includes("dropbox")) {
    var link = value;
    var splitLink = link.split("/");
    if (splitLink.length >= 4) {
      if (splitLink[3] == "sh") {
        return "folder";
      }
    }
    if (splitLink.length >= 6) {
      var nameSplit = splitLink[5].split(".");
      return nameSplit[nameSplit.length - 1].split("?")[0];
    }
  } else {
    return "std";
  }
}

export function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base",
    });
    /* next line works with strings and numbers */

    var result = collator.compare(a[property], b[property]);
    return result * sortOrder;
  };
}

/* eslint-disable import/prefer-default-export */
export { checkAuthentication };
