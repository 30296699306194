import React from "react";
import DialogLayout from "../layout/DialogLayout";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth } from "../auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
}));

const DeleteDialog = withTranslation()((props) => {
  const [open, setOpen] = React.useState(false);
  const { displayMessage } = useAuth();
  const handleClickOpen = () => {
    //console.log(props.type)
    setTimeout(() => {
      if (document.getElementById("confirm")) {
        document.getElementById("confirm").focus();
      }
    }, 200);
    props.setContextState(initialState);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const renderTitle = () => {
    return (
      <React.Fragment>
        {props.t("delete")} "{props.name}"
      </React.Fragment>
    );
  };

  const handleSave = () => {
    props.handleDelete();
    displayMessage("success", '"' + props.name + '" ' + props.t("wasDeleted"));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>{props.t("delete")}</MenuItem>
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={true}
        displaySecondaryBtn={true}
        primaryBtnText={props.t("delete")}
        secondaryBtnText={props.t("cancel")}
        title={renderTitle()}
        maxWidth="md"
        open={open}
      >
        <p>
          {props.t("sureDelete")} "{props.name}"?{" "}
          {props.type == "video" && props.t("allSegmentsDeleted")}
          {(props.type == "subcollection" || props.type == "collection") &&
            props.t("allContentsDeleted")}
        </p>
      </DialogLayout>
    </React.Fragment>
  );
});

export default DeleteDialog;
