import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth } from "../auth/AuthContext";
import DialogLayout from "../layout/DialogLayout";
import axios from "axios";
import { TextField as YlTextField } from "../InputFields";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
}));
const RenameItemDialog = withTranslation()((props) => {
  const { getToken, displayMessage } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [description, setDescription] = React.useState(null);
  const [error, setError] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    props.setContextState(initialState);
    setDescription(null);
    if (props.item.type === "video" || "link") {
      fetchDesc(props.item.id);
    }
    setOpen(true);
  };

  const nameUpdate = (name) => {
    props.setRightClickedItemName(name);
  };

  const fetchDesc = (id) => {
    if (id) {
      const videoId = id;
      getToken().then((idToken) => {
        axios
          .get(
            process.env.REACT_APP_RESOURCE_SERVER + "/api/videos/" + videoId,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((result) => {
            if (
              result.data.description &&
              result.data.description != undefined &&
              result.data.description != null &&
              result.data.description != "undefined" &&
              result.data.description != "null"
            ) {
              setDescription(result.data.description);
            } else {
              setDescription("");
            }
          });
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (props.rightClickedItemName == "") {
      setError(true);
      return;
    }
    getToken().then((idToken) => {
      if (props.item.type == "video" || props.item.type == "link") {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${props.item.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              attribute: "name",
              value: props.rightClickedItemName,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then(() => {
            fetch(
              `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${props.item.id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                  attribute: "description",
                  value: description ? description : "",
                }),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return Promise.reject();
                }
                return response.json();
              })
              .then(() => {
                displayMessage("success", "Breytingar vistaðar");
                props.setEditObj({
                  changed: !props.editObj.changed,
                  id: Number(props.item.id),
                  name: props.rightClickedItemName,
                  type: props.item.type,
                });
              })
              .catch((err) => {
                displayMessage("error", "Villa kom upp");
                /* eslint-disable no-console */
                console.error(err);
              });
          })
          .catch((err) => {
            displayMessage("error", "Villa kom upp");
            /* eslint-disable no-console */
            console.error(err);
          });
      }
      if (props.item.type === "subcollection") {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${props.item.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              attribute: "name",
              value: props.rightClickedItemName,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then(() => {
            displayMessage(
              "success",
              "Nafni breytt í: " + props.rightClickedItemName
            );
            props.setEditObj({
              changed: !props.editObj.changed,
              id: Number(props.item.id),
              name: props.rightClickedItemName,
              type: props.item.type,
            });
          })
          .catch((err) => {
            /* eslint-disable no-console */
            displayMessage("error", "Villa kom upp");
            console.error(err);
          });
      }
    });
    setOpen(false);
  };
  const renderTitle = () => {
    return (
      <React.Fragment>
        {props.t("edit")} "{props.item.name}"
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>{props.t("edit")}</MenuItem>
      <DialogLayout
        title={renderTitle()}
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={true}
        displaySecondaryBtn={true}
        primaryBtnText={props.t("save")}
        secondaryBtnText={props.t("cancel")}
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <form onSubmit={handleSave} className={classes.input}>
          <YlTextField
            autoFocus
            margin="dense"
            id="name"
            label={props.t("Name")}
            helperText={error ? props.t("Type In New Name") : ""}
            type="text"
            value={props.rightClickedItemName}
            onChange={nameUpdate}
            fullWidth
          />
        </form>
        <br></br>
        {true && (
          <React.Fragment>
            <form onSubmit={handleSave} className={classes.input}>
              <YlTextField
                margin="dense"
                id="name"
                label={props.t("description")}
                value={description}
                multiline
                rowsMax={4}
                rows={3}
                onChange={setDescription}
                fullWidth
              />
            </form>
          </React.Fragment>
        )}
      </DialogLayout>
    </React.Fragment>
  );
});
export default RenameItemDialog;
