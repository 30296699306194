import React, { useState, useEffect } from "react";
import FullScreenDialog from "../FullscreenDialog.js";
import SignIn from "../auth/SignIn.js";
import { makeStyles } from "@material-ui/core/styles";
import AddVideoDialog from "../videos/AddVideoDialog.jsx";
import MenuDrawer from "../user-menu/MenuDrawer.jsx";
import { useAuth } from "../auth/AuthContext";
import OrgSelect from "../OrgSelect";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { isMobileOnly } from "react-device-detect";
import { ReactComponent as Lock } from "../../assets/icons/login-icon.svg";
import i18n from "../i18n";
import { Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PublicIcon from "@material-ui/icons/Public";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

const useStyles = makeStyles((theme) => ({
  header: {
    color:
      theme.palette.type === "light"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
}));
const Header = withTranslation()((props) => {
  const classes = useStyles();
  const { currentUser, userValidated, pending, accountInfo } = useAuth();

  //console.log(useAuth());
  // const login = async () => authService.login("/");
  // const logout = async () => authService.logout("/");
  //const [practiceMode, setPracticeMode] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState(false);
  const [addNewVideoOpen, setAddNewVideoOpen] = useState(false);

  const handleCloseAddNewVideo = (e) => {
    setAddNewVideoOpen(false);
  };

  const handleClickAddNewVideo = (e) => {
    setAddNewVideoOpen(true);
  };

  useEffect(() => {
    if (currentUser) {
      setUserName(currentUser.displayName);
      if (currentUser.email == "andri.gitar@gmail.com") {
        setUserEmail(true);
      }
    } else {
      setUserName("");
    }
  }, [currentUser]);

  return (
    <div className="header">
      {(props.landingPage || !isMobileOnly) && (
        <div className={"logo " + classes.header}>
          {/* {" "}
        <img
          src={logo}
          alt=""
          style={{
            marginRight: "2px",
            marginLeft: "-17px",
            marginTop: "-100px",
            marginBottom: "-10px",
            width: "35px",
          }}
        ></img>{" "} */}
          <Logo />
          YouLearn
        </div>
      )}
      {/* Search */}
      {/* User settings icon */}
      {userValidated && currentUser ? (
        <div className="right">
          {/*<FormControlLabel
            className={
              "practice-mode " + (practiceMode === true ? "checked" : "")
            }
            control={
              <Checkbox
                icon={<SpaOutlinedIcon />}
                checkedIcon={<SpaIcon />}
                name="checkedH"
                checked={practiceMode}
                onChange={(e) => setPracticeMode(!practiceMode)}
              />
            }
            label={
              practiceMode === true ? "practice mode on" : "practice mode off"
            }
          />*/}
          {!isMobileOnly && (
            <span>
              {accountInfo.orgs && accountInfo.orgs.length > 1 && (
                <OrgSelect></OrgSelect>
              )}
              {!(accountInfo.orgs && accountInfo.orgs.length > 1) &&
                !props.explore && (
                  <Button
                    onClick={() => {
                      window.location =
                        process.env.REACT_APP_FRONT_END + "/explore";
                    }}
                  >
                    <PublicIcon
                      fontSize="small"
                      style={{
                        marginRight: "5px",
                        color: "rgb(164, 163, 238)",
                      }}
                    ></PublicIcon>{" "}
                    Explore
                  </Button>
                )}
              {!(accountInfo.orgs && accountInfo.orgs.length > 1) &&
                props.explore && (
                  <Button
                    onClick={() => {
                      window.location = process.env.REACT_APP_FRONT_END;
                    }}
                  >
                    <FolderOpenIcon
                      fontSize="small"
                      style={{
                        marginRight: "5px",
                        color: "rgb(164, 163, 238)",
                      }}
                    ></FolderOpenIcon>{" "}
                    My Collections
                  </Button>
                )}
              <MenuDrawer
                changeLang={() => i18n.changeLanguage("en")}
                userName={userName}
                handleThemeChange={props.handleThemeChange}
                darkState={props.darkState}
                userEmail={userEmail}
              >
                {props.children}
              </MenuDrawer>
            </span>
          )}

          {/*<Button
            classes={{ root: "fancy-btn", label: "button-label" }}
            onClick={(e) => handleClickAddNewVideo(e)}
          >
            nýtt myndband
          </Button>*/}
          <AddVideoDialog
            setAddedVid={props.setAddedVid}
            open={addNewVideoOpen}
            handleClose={handleCloseAddNewVideo}
          />
          {isMobileOnly && (
            <Grid container>
              <Grid item xs={4} md={3}></Grid>
              <Grid item xs={4}>
                {accountInfo.orgs && accountInfo.orgs.length > 1 && (
                  <OrgSelect></OrgSelect>
                )}
              </Grid>
              <Grid item xs={4}>
                <MenuDrawer
                  userName={userName}
                  handleThemeChange={props.handleThemeChange}
                  darkState={props.darkState}
                  userEmail={userEmail}
                >
                  {props.children}
                </MenuDrawer>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        <div className="right">
          {!pending && (
            <FullScreenDialog
              open={props.openSignIn}
              setOpen={props.setOpenSignIn}
              buttonText={props.t("myAccount")}
              buttonIcon={<Lock />}
            >
              <SignIn />
            </FullScreenDialog>
          )}
        </div>
      )}
    </div>
  );
});

export default Header;
