import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import NativeSelect from "@material-ui/core/NativeSelect";
import AddIcon from "@material-ui/icons/Add";
import removeIcon from "../../images/AddNewCategory.png";
import { Icon } from "@iconify/react";
import checkCircleFill from "@iconify/icons-ph/check-circle-fill";
import { TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import "../i18n";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const LicenceTable = withTranslation()((props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{props.t("email")}</TableCell>
              <TableCell align="right">{props.t("name")}</TableCell>
              <TableCell align="right">{props.t("type")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.licences.map((licence) => (
              <TableRow key={licence.id}>
                <TableCell component="th" scope="row">
                  {licence.email}
                </TableCell>
                <TableCell align="right">
                  {licence.display_name
                    ? licence.display_name
                    : props.t("pending")}
                </TableCell>
                <TableCell align="right">
                  {licence.role == "teacher"
                    ? props.t("teacher")
                    : props.t("admin")}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => props.handleLicenceDeactivation(licence.id)}
                    color="white"
                    size="medium"
                  >
                    <img src={removeIcon}></img>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {props.adding && (
              <TableRow
                style={{
                  border: "none",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                key={-1}
              >
                <TableCell
                  scope="row"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "12px",
                  }}
                >
                  <TextField
                    //className="editSeg"
                    //ref={inputRef}
                    label={props.t("email")}
                    type="text"
                    onChange={(e) => props.setEmailInput(e.target.value)}
                    value={props.emailInput}
                    required
                  />
                </TableCell>
                <TableCell
                  className=""
                  align="right"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <InputLabel htmlFor="age-native-helper"></InputLabel>
                  <NativeSelect
                    native
                    value={props.inputRole}
                    onChange={props.handleInputRoleChange}
                    inputProps={{
                      name: "age",
                      id: "age-native-helper",
                    }}
                  >
                    <option value="teacher">{props.t("teacher")}</option>
                    <option value="admin">{props.t("admin")}</option>
                  </NativeSelect>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  align="right"
                >
                  <IconButton
                    onClick={props.handleLicenceActivation}
                    color="white"
                    size="medium"
                    //className="add-collection-btn"
                  >
                    <Icon
                      icon={checkCircleFill}
                      style={{
                        color: "#605df9",
                        fontSize: "30px",
                        paddingBottom: "0px",
                      }}
                    />
                  </IconButton>
                  {props.t("add")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Tooltip title={props.t("newLicenceHolder")} arrow>
        <IconButton
          onClick={() => props.setAdding(!props.adding)}
          color="white"
          size="medium"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
});

export default LicenceTable;
