import axios from 'axios';
const KEY = 'AIzaSyAS4IWof-9XcC3ny6cLQfEN3cW9m1YaXrg'

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3/',
    params: {
        part: 'snippet',
        maxResults: 10,
        key: KEY
    }
})