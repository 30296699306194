import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddSubcollectionDialog from "./AddSubcollectionDialog";
import AddVideoDialog from "../videos/AddVideoDialog";
import AddDownloadLink from "./AddDownloadLink";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/youtube-yl-logo.svg";
import Backdrop from "@material-ui/core/Backdrop";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    width: 260,
    height: 40,
    borderRadius: 50,
    background: theme.palette.background.paper,
  },
  speedDial: {
    position: "fixed",
    width: 40,
    bottom: 0,
    // marginBottom: "-200px",
  },
  backdrop: {
    background: theme.palette.background.backdrop,
  },
  tooltipLabel: {
    background: theme.palette.background.paper,
  },
}));

const SpeedDialAddButton = withTranslation()((props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = React.useState(true);
  const [subCollectionDialogOpen, setSubCollectionDialogOpen] =
    React.useState(false);
  const [downloadLinkDialogOpen, setDownloadLinkDialogOpen] =
    React.useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setSubCollectionDialogOpen(false);
    setVideoDialogOpen(false);
    setDownloadLinkDialogOpen(false);
  };

  const handleOpen = () => {
    if (hide) {
      setHide(false);
      setTimeout(() => {
        setOpen(true);
      }, 100);
    } else if (!hide) {
      setOpen(false);
      setTimeout(() => {
        setHide(true);
      }, 200);
    }
  };

  const handleNewSubcollection = () => {
    setOpen(false);
    setSubCollectionDialogOpen(true);
  };

  const handleNewVideo = () => {
    setOpen(false);
    setVideoDialogOpen(true);
  };

  const handleNewDownloadLink = () => {
    setOpen(false);
    setDownloadLinkDialogOpen(true);
  };

  const actions = [
    {
      icon: <YouTubeLogo style={{ width: 27 }} />,
      name: props.t("ytVid"),
      handleClick: handleNewVideo,
    },
    {
      icon: <GetAppIcon />,
      name: props.t("dbLink"),
      handleClick: handleNewDownloadLink,
    },
    {
      icon: <CreateNewFolderIcon />,
      name: props.t("subcollection"),
      handleClick: handleNewSubcollection,
    },
  ];

  return (
    <>
      <Backdrop open={open} onClick={handleOpen} className={classes.backdrop} />
      <div
        className={classes.root + " add-new-container" + (open ? " open" : "")}
      >
        <SpeedDial
          ariaLabel="Add new"
          className={classes.speedDial + " add-new-btn"}
          icon={<SpeedDialIcon />}
          // onClose={handleClose}
          onClick={handleOpen}
          open={open}
          direction="up"
        >
          {!hide &&
            actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="right"
                classes={{ staticTooltipLabel: classes.tooltipLabel }}
                tooltipOpen
                onClick={(e) => action.handleClick()}
              />
            ))}
        </SpeedDial>
        {
          <React.Fragment>
            <AddSubcollectionDialog
              addSubcollection={props.addSubcollection}
              collectionName={props.collectionName}
              droppable="true"
              open={subCollectionDialogOpen}
              handleClose={handleClose}
            ></AddSubcollectionDialog>
            <AddVideoDialog
              collectionId={props.collectionId}
              open={videoDialogOpen}
              handleClose={handleClose}
              setAddedVid={props.newVidAdded}
            />
            <AddDownloadLink
              setAddLinkOpen={setDownloadLinkDialogOpen}
              collectionId={props.collectionId}
              open={downloadLinkDialogOpen}
              handleClose={handleClose}
              setAddedVid={props.newVidAdded}
            />
          </React.Fragment>
        }
        <div className="add-new-text">{props.t("addToCollection")}</div>
      </div>
    </>
  );
});

export default SpeedDialAddButton;
