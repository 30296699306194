import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StudentsList from "./StudentList";
import axios from "axios";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useAuth } from "../auth/AuthContext";
import educationIcon from "@iconify/icons-carbon/education";
import { Icon } from "@iconify/react";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { withTranslation } from "react-i18next";
import "../i18n";

const StudentDialog = withTranslation()((props) => {
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const { currentUser, getToken, accountInfo } = useAuth();
  const [loading, setLoading] = React.useState(true);

  const getStudents = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            accountInfo.org +
            "/user/" +
            currentUser.uid +
            "/student_collections/",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          setStudents(response.data);
          setLoading(false);
          //console.log(response.data);
        });
    });
  };

  const removeRelation = (id) => {
    getToken().then((idToken) => {
      axios
        .delete(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/student_collections/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data == "ok") {
            getStudents();
          }
        });
    });
  };
  const handleClickOpen = () => {
    setLoading(true);
    getStudents();
    setOpen(true);
  };

  const handleClose = () => {
    //setSubcollectionName("");
    setOpen(false);
    props.closeDrawer();
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <Icon
            icon={educationIcon}
            style={{
              marginLeft: "2px",
              fontSize: "22px",
            }}
          />
        </ListItemIcon>
        <ListItemText primary={props.t("myStudents")} />
      </ListItem>
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.t("myStudents")}
        </DialogTitle>
        {!loading && (
          <DialogContent>
            <StudentsList removeRelation={removeRelation} students={students} />
          </DialogContent>
        )}
        {loading && (
          <DialogContent>
            <Typography variant="caption">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="caption">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="caption">
              <Skeleton width="100%" />
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default StudentDialog;
