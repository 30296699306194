import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import SaveVidIcon from "@material-ui/icons/Save";
import AddCollVideoDialog from "./AddCollVideoDialog";
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useAuth } from "../auth/AuthContext";
import { dbFormat } from "../../helpers";
import { Divider } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

const PaginationBtn = withStyles((theme) => ({
  root: {
    color: "#A4A3EE",
    [theme.breakpoints.down("sm")]: {
      // width: 28,
      padding: 8,
      "& svg": {
        height: 25,
      },
    },
  },
}))(IconButton);

export default function SimpleMenu(props) {
  const { getToken, currentUser } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [collections, setCollections] = React.useState(null);
  const [tipOpen, setTipOpen] = React.useState(true);
  const [newSaveColDialogOpen, setNewSaveColDialogOpen] = React.useState(false);
  const handleClick = (event) => {
    if (!currentUser) {
      setTipOpen(false);
      props.setOpenSignIn(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveVideo = (id) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${id}/videos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            oldId: props.video.id,
            name: dbFormat(props.video.name),
            youtubeId: props.video.youtube_id,
            subcollectionId: -2,
            start: props.video.start,
            ending: props.video.ending,
            description: props.video.description,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          getToken()
            .then((idToken) => {
              fetch(
                `${process.env.REACT_APP_RESOURCE_SERVER}/api/accounts/${currentUser.uid}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                  },
                  body: JSON.stringify({
                    videoId: data.id,
                    collectionId: id,
                    category: "personal",
                  }),
                }
              ).then((res) => {
                if (res.ok) {
                  window.location.href = process.env.REACT_APP_FRONT_END;
                }
              });
            })
            .catch((err) => {
              /* eslint-disable no-console */
              console.error(err);
            });
        });
    });
  };
  useEffect(() => {
    if (!collections && currentUser) {
      getToken().then((idToken) => {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${currentUser.uid}/collections/personal`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            setCollections(data);
          });
      });
    }
  }, [currentUser]);

  return (
    <div>
      <Tooltip
        title={"Save To Collection"}
        arrow
        open={tipOpen}
        placement="top"
      >
        <PaginationBtn
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            marginTop: "-15px",
            float: "right",
            width: "auto",
          }}
        >
          <SaveVidIcon></SaveVidIcon>
        </PaginationBtn>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {collections &&
          collections.map((collection) => (
            <MenuItem
              onClick={() => saveVideo(collection.id)}
              value={collection.id}
            >
              {collection.name}
            </MenuItem>
          ))}
        <Divider></Divider>
        <MenuItem
          onClick={() => {
            setNewSaveColDialogOpen(true);
            handleClose();
          }}
          color="white"
          size="medium"
        >
          <p>New Collection</p>
        </MenuItem>
      </Menu>
      <AddCollVideoDialog
        video={props.video}
        currentUser={currentUser}
        setOpen={setNewSaveColDialogOpen}
        open={newSaveColDialogOpen}
      ></AddCollVideoDialog>
    </div>
  );
}
