import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withTranslation } from "react-i18next";
import "./i18n";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClipFromSegmentDialog from "./videos/ClipFromSegmentDialog";

const RefButton = withStyles((theme) => ({
  root: {
    borderRadius: "0px !important",
    opacity: "0 !important",
    padding: "20px 0 20px 9px !important",
    minWidth: "10px !important",
    position: "absolute",
    marginTop: "-20px",
    marginLeft: "40px",
    zIndex: "1000 !important",
    display: "block",
  },
}))(Button);

const EditButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&:focus, &:hover, &$active": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));

const MenuListComposition = withTranslation()((props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (e) => {
    if (
      (anchorRef.current && anchorRef.current.contains(e.target)) ||
      dialogOpen
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(e) {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpen(false);
    }
  }

  function handleEdit(e) {
    e.stopPropagation();
    setTimeout(function () {
      props.focusInput();
    }, 10);
    setOpen(false);
  }

  function handleChangeTime(e) {
    e.stopPropagation();
    props.handleSelectSegment();
    props.handleChangeSegmentTime(true);
    setOpen(false);
  }

  function handleDel(e) {
    if (e) {
      e.stopPropagation();
    }
    props.del(props.id);
    props.setSegmentAction("none");
    setOpen(false);
  }

  useEffect(() => {
    props.updateNameInput(props.segmentAction);
    if (props.segmentAction != "none" && props.tempId == props.segmentToEdit) {
      if (props.segmentAction == "delete") {
        handleDel();
      }
      if (props.segmentAction == "copy") {
        setDialogOpen(true);
        props.setSegmentAction("none");
      }
      if (props.segmentAction == "rename") {
        setTimeout(function () {
          props.focusInput();
        }, 10);
        props.setSegmentAction("none");
      }
      if (props.segmentAction == "time") {
        props.handleSelectSegment();
        props.handleChangeSegmentTime(true);
        props.setSegmentAction("none");
      }
    }
  }, [props.segmentAction]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={"edit-btn-container " + classes.root}>
      {/*<EditButton
        disabled={!props.teacher}
        className="edit-btn"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        size="small"
      >
        <MoreVertIcon />
      </EditButton>*/}
      <ClipFromSegmentDialog
        note={props.note}
        open={dialogOpen}
        setAddedVid={props.setAddedVid}
        setMenuOpen={setOpen}
        setOpen={setDialogOpen}
        start={props.start}
        ending={props.ending}
        name={props.name}
        youtube_id={props.youtube_id}
      ></ClipFromSegmentDialog>
      <RefButton ref={anchorRef}></RefButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleEdit}>{props.t("rename")}</MenuItem>
                  <MenuItem onClick={handleChangeTime}>
                    {props.t("editTimestamp")}
                  </MenuItem>
                  <MenuItem onClick={handleDel}>{props.t("delete")}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
});

export default MenuListComposition;
