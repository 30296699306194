import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import AddIcon from "@material-ui/icons/Add";
import removeIcon from "../../images/AddNewCategory.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { Icon, InlineIcon } from "@iconify/react";
import checkCircleFill from "@iconify/icons-ph/check-circle-fill";
import { TextField } from "@material-ui/core";
// or

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function DepartmentAdminTable(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.copyList ? (
                <TableCell>afritanlegar deildir út "Tónlistaskólar"</TableCell>
              ) : (
                <TableCell>deildir innan stofnunar</TableCell>
              )}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.departments &&
              props.departments.map((department, index) => (
                <TableRow key={department.id}>
                  <TableCell component="th" scope="row">
                    {department.name}
                  </TableCell>
                  <TableCell align="right">
                    {props.copyList ? (
                      <Button onClick={() => props.handleDepartmentCopy(index)}>
                        afrita
                      </Button>
                    ) : (
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() =>
                          props.handleDepartmentDelete(department.id)
                        }
                        color="white"
                        size="medium"
                      >
                        <img src={removeIcon}></img>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {props.adding && (
              <TableRow
                style={{
                  border: "none",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                key={-1}
              >
                <TableCell
                  scope="row"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "12px",
                  }}
                >
                  <TextField
                    label="Nafn deildar"
                    type="text"
                    onChange={(e) =>
                      props.setDepartmentNameInput(e.target.value)
                    }
                    value={props.departmentNameInput}
                    required
                  />
                </TableCell>
                <TableCell
                  className=""
                  align="right"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  align="right"
                >
                  <IconButton
                    onClick={props.handleDepartmentAdd}
                    color="white"
                    size="medium"
                    //className="add-collection-btn"
                  >
                    <Icon
                      icon={checkCircleFill}
                      style={{
                        color: "#605df9",
                        fontSize: "30px",
                        paddingBottom: "0px",
                      }}
                    />
                  </IconButton>
                  Bæta Við
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.copyList && (
        <Tooltip title="Ný Deild" arrow>
          <IconButton
            onClick={() => props.setAdding(!props.adding)}
            color="white"
            size="medium"
            //className="add-collection-btn"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  );
}
