import React, { useEffect, useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import { timeToString, timeToSeconds } from "../helpers";

const useStyles = makeStyles(() => ({
  display: "inlineBlock",
  width: "60px",
  root: {
    textAlign: "center !important",
    "& > *": {
      backgroundColor: "rgba(164, 163, 238, 0.19)",
      textAlign: "center !important",
      borderRadius: "100px",
      display: "inlineBlock",
      minWidth: "70px",
      height: "35px",
      margin: "0 10px",
      transition: "width .3s ease",
    },
  },
}));

export default function IntervalDisplayBox(props) {
  const classes = useStyles();
  const [value, setValueInput] = useState(timeToString(0));

  useEffect(() => {
    setValueInput(timeToString(props.disp - props.min));
  }, [props.disp, props.min]);

  const handleKeyDown = (e) => {
    var newValue = 0;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      newValue = e.key === "ArrowUp" ? props.disp + 1 : props.disp - 1;
      if (newValue >= props.min && newValue <= props.max) {
        props.handleChange(newValue);
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const newValue = timeToSeconds(value);
    if (newValue >= props.min && newValue <= props.max) {
      props.handleChange(newValue);
    } else {
      setValueInput(timeToString(props.disp - props.min));
    }
  };

  return (
    <form
      style={{ display: "inline-block", margin: 0 }}
      className={classes.root}
      onSubmit={(e) => handleSave(e)}
    >
      <OutlinedInput
        className="interval-display"
        type="text"
        variant="outlined"
        style={{ width: props.disp - props.min >= 3600 ? "92px" : "70px" }}
        onChange={(e) => setValueInput(e.target.value)}
        inputProps={{
          onKeyDown: (e) => handleKeyDown(e),
        }}
        value={value}
      />
    </form>
  );
}
