import React from "react";

import IntervalDisplayBox from "./IntervalDisplayBox";

export default function IntervalDisplay(props) {
  const handleStartChange = (newStartValue) => {
    props.handleChange(newStartValue, props.end);
  };
  const handleEndChange = (newEndValue) => {
    props.handleChange(props.start, newEndValue);
  };
  return (
    <div style={{ display: "inline-block" }}>
      {props.showFrom && <p style={{ display: "inline-block", margin: "0px 4px 0px -25px" }}>Frá</p>}
      <IntervalDisplayBox
        start
        disp={props.start}
        min={props.min}
        max={props.end}
        handleChange={handleStartChange}
      />
      <p style={{ display: "inline-block", margin: "14px 4px" }}>til</p>
      <IntervalDisplayBox
        disp={props.end}
        min={props.min}
        max={props.max}
        handleChange={handleEndChange}
      />
    </div>
  );
}
