import Button from "@material-ui/core/Button";
import { dbFormat } from "../helpers";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Grid";
import CollectionDrawer from "./collections/CollectionDrawer";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Layout from "./layout/Layout";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddStudentDialog from "./collections/AddStudentDialog";
import ShareDialog from "./collections/ShareDialog";
import CopyItemDialog from "./collections/CopyItemDialog";
import RenameItemDialog from "./collections/RenameItemDialog.js";
import DeleteDialog from "./collections/DeleteDialog.js";
import RenameCollectionDialog from "./collections/RenameCollectionDialog.js";
import AddVideoDialog from "./videos/AddVideoDialog";
import { useAuth } from "./auth/AuthContext";
import AddAttachmentDialog from "./AddAttachmentDialog";
import MoveItemDialog from "./collections/MoveToDialog";
import CustomSnackBar from "./CustomSnackBar";
import AddDownloadLink from "./collections/AddDownloadLink";
import { Link } from "@material-ui/core";
import FullScreenDialog from "./FullscreenDialog.js";
import VideoBackground from "./VideoBackground";
import { ReactComponent as Lock } from "./../assets/icons/login-icon.svg";
import SignIn from "./auth/SignIn.js";
import AlertMessage from "./AlertMessage";
import { withTranslation } from "react-i18next";
import i18n from "./i18n";
import { ReactComponent as LandingPageImage } from "../images/landing-page-image.svg";
import { ReactComponent as ArrowRight } from "./../assets/icons/arrow-right.svg";
import { ReactComponent as NoAccountImage } from "../images/no-account-image.svg";
import { withStyles } from "@material-ui/core/styles";
import { changeLanguage } from "i18next";
import VideoContainer from "./PlainVideoContainer";
import InputYoutubeSearch from "./videos/InputYoutubeSearch";

const LinkButton = withStyles({
  root: {
    display: "inline",
    color: "#a4a3ee !important",
  },
  button: {
    display: "inline",
    color: "#a4a3ee !important",
    marginBottom: "2px",
  },
})(Link);

const initialState = {
  mouseX: null,
  mouseY: null,
};

const Home = withTranslation()((props) => {
  const [AddAttachOpen, setAddAttachOpen] = useState(false);
  const [contextState, setContextState] = React.useState(initialState);
  const [segmentContextState, setSegmentContextState] =
    React.useState(initialState);
  const [segmentAction, setSegmentAction] = useState("none");
  const [segmentToEdit, setSegmentToEdit] = React.useState();
  const [rightClickedItem, setRightClickedItem] = useState({});
  const [subMenuState, setSubMenuState] = useState(0);
  const [copyColMenuState, setCopyColMenuState] = useState(0);
  const [rightClickedItemName, setRightClickedItemName] = useState("");
  const [category, setCategory] = React.useState(null);
  const [addedVid, setAddedVid] = useState(null);
  const [editObj, setEditObj] = useState({});
  const [addVidOpen, setAddVidOpen] = useState(false);
  const [addLinkOpen, setAddLinkOpen] = useState(false);
  const [colEdit, setColEdit] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [videoId, setVideoId] = useState("jAp3a300Z90");
  const [licks, setLicks] = useState([]);
  const [videoObj, setVideoObj] = useState({
    title: "10 Tasty Licks",
    description: "",
  });
  const [attachmentChange, setAttachmentChange] = useState(false);
  const { currentUser, userValidated, pending, displayMessage, accountInfo } =
    useAuth();
  var heldLong = false;
  var heldTimeout = null;

  const handleClick = (e) => {
    var selectedElement = e.target;
    var segmentId = -1;
    if (selectedElement.classList.contains("editable")) {
      if (selectedElement.id) {
        segmentId = selectedElement.id.split("-")[1];
        //console.log(selectedElement.id.split("-")[1]);
      }
    } else if (e.target.parentElement.classList.contains("editable")) {
      if (e.target.parentElement.id) {
        segmentId = e.target.parentElement.id.split("-")[1];
        //console.log(e.target.parentElement.id.split("-")[1]);
      }
    } else if (
      e.target.parentElement.parentElement.classList.contains("editable")
    ) {
      if (e.target.parentElement.parentElement.id) {
        //console.log(e.target.parentElement.parentElement.id.split("-")[1]);
        segmentId = e.target.parentElement.parentElement.id.split("-")[1];
      }
    }

    if (segmentId != -1) {
      setSegmentToEdit(segmentId);
      setSegmentContextState({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
      });
      e.preventDefault();
      return;
    }

    if (selectedElement.classList.contains("teacher")) {
      setColEdit(true);
      setRightClickedItem({
        type: "collection",
        index: selectedElement.getAttribute("index"),
        name: selectedElement.getAttribute("name"),
      });
      setRightClickedItemName(selectedElement.getAttribute("name"));
      setContextState({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
      });
      e.preventDefault();
      return;
    }

    setColEdit(false);
    var clickedId = e.target.id;

    if (!clickedId) {
      selectedElement = e.target.parentElement;
      clickedId = selectedElement.id;
    }
    if (!clickedId) {
      selectedElement = e.target.parentElement.parentElement;
      clickedId = selectedElement.id;
    }
    if (!selectedElement.classList.contains("teacher-item")) {
      return;
    }
    var name = "";
    var type = "subcollection";
    if (selectedElement.classList.contains("video-link")) {
      if (selectedElement.classList.contains("item-link")) {
        type = "link";
      } else {
        type = "video";
      }
      name = selectedElement.children[0].children[0].innerHTML;
    } else if (
      selectedElement.classList.contains("sub-collection") ||
      selectedElement.classList.contains("makeStyles-subText-1749")
    ) {
      for (let index = 0; index < selectedElement.children.length; index++) {
        if (
          selectedElement.children[index].classList.contains(
            "MuiListItemText-root"
          )
        ) {
          name = selectedElement.children[index].innerHTML;
        }
      }
    } else {
      return;
    }
    const itemObj = { id: clickedId, name: name, type: type };
    setRightClickedItem(itemObj);
    setRightClickedItemName(name);
    if (itemObj.id !== "") {
      e.preventDefault();
      setContextState({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
      });
    }
  };

  const handleTouch = (e) => {
    heldLong = false;

    heldTimeout = setTimeout(() => {
      heldLong = true;
    }, 700);
  };

  const handleTouchMove = (e) => {
    clearTimeout(heldTimeout);
    heldLong = false;
  };

  const handleTouchEnd = (e) => {
    if (heldLong) {
      var selectedElement = e.target;
      var segmentId = -1;
      if (selectedElement.classList.contains("editable")) {
        if (selectedElement.id) {
          segmentId = selectedElement.id.split("-")[1];
          //console.log(selectedElement.id.split("-")[1]);
        }
      } else if (e.target.parentElement.classList.contains("editable")) {
        if (e.target.parentElement.id) {
          segmentId = e.target.parentElement.id.split("-")[1];
          //console.log(e.target.parentElement.id.split("-")[1]);
        }
      } else if (
        e.target.parentElement.parentElement.classList.contains("editable")
      ) {
        if (e.target.parentElement.parentElement.id) {
          //console.log(e.target.parentElement.parentElement.id.split("-")[1]);
          segmentId = e.target.parentElement.parentElement.id.split("-")[1];
        }
      }

      if (segmentId != -1) {
        setSegmentToEdit(segmentId);
        setSegmentContextState({
          mouseX: selectedElement.getBoundingClientRect().x + 20,
          mouseY: selectedElement.getBoundingClientRect().y + 20,
        });
        e.preventDefault();
        return;
      }
      if (selectedElement.classList.contains("teacher")) {
        setColEdit(true);
        setRightClickedItem({
          type: "collection",
          index: selectedElement.getAttribute("index"),
          name: selectedElement.getAttribute("name"),
        });
        setRightClickedItemName(selectedElement.getAttribute("name"));
        setContextState({
          mouseX: selectedElement.getBoundingClientRect().x + 20,
          mouseY: selectedElement.getBoundingClientRect().y + 20,
        });
        e.preventDefault();
        return;
      }
      setColEdit(false);
      var clickedId = e.target.id;

      if (!clickedId) {
        selectedElement = e.target.parentElement;
        clickedId = selectedElement.id;
      }
      if (!clickedId) {
        selectedElement = e.target.parentElement.parentElement;
        clickedId = selectedElement.id;
      }
      if (!selectedElement.classList.contains("teacher-item")) {
        return;
      }
      var name = "";
      var type = "subcollection";
      if (selectedElement.classList.contains("video-link")) {
        if (selectedElement.classList.contains("item-link")) {
          type = "link";
        } else {
          type = "video";
        }
        name = selectedElement.children[0].children[0].innerHTML;
      } else if (
        selectedElement.classList.contains("sub-collection") ||
        selectedElement.classList.contains("makeStyles-subText-1749")
      ) {
        for (let index = 0; index < selectedElement.children.length; index++) {
          if (
            selectedElement.children[index].classList.contains(
              "MuiListItemText-root"
            )
          ) {
            name = selectedElement.children[index].innerHTML;
          }
        }
      } else {
        return;
      }
      const itemObj = { id: clickedId, name: name, type: type };
      setRightClickedItem(itemObj);
      setRightClickedItemName(name);
      if (itemObj.id !== "") {
        e.preventDefault();
        setContextState({
          mouseX: selectedElement.getBoundingClientRect().x + 20,
          mouseY: selectedElement.getBoundingClientRect().y + 20,
        });
      }
    }
  };

  const handleDelete = () => {
    var newEditObj = rightClickedItem;
    if (newEditObj.type === "collection") {
      setEditObj({
        changed: !editObj.changed,
        name: null,
        type: newEditObj.type,
        index: Number(newEditObj.index),
      });
    } else {
      setEditObj({
        changed: !editObj.changed,
        name: null,
        type: newEditObj.type,
        id: Number(newEditObj.id),
      });
    }
    setContextState(initialState);
  };

  const handleVideoChange = (id, vidObj) => {
    setVideoObj(vidObj);
    setVideoId(id);
  };

  const handleRemove = () => {
    var newEditObj = rightClickedItem;

    setEditObj({
      changed: !editObj.changed,
      name: -1,
      type: newEditObj.type,
      id: Number(newEditObj.id),
    });
    setContextState(initialState);
  };

  const handleClose = () => {
    setSubMenuState(0);
    setContextState(initialState);
    setSegmentContextState(initialState);
  };

  useEffect(() => {
    if (copyColMenuState == 0) {
      setContextState(initialState);
    }
  }, [copyColMenuState]);

  useEffect(() => {
    if (subMenuState == 0) {
      setContextState(initialState);
    }
  }, [subMenuState]);

  return (
    <Layout
      explore={props.explore}
      openSignIn={openSignIn}
      setOpenSignIn={setOpenSignIn}
      setAddedVid={setAddedVid}
      handleThemeChange={props.handleThemeChange}
      darkState={props.darkState}
      maxWidth={userValidated && currentUser ? "xl" : "lg"}
      landingPage={!currentUser && !pending && !props.explore}
    >
      <Container
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouch}
        onTouchEnd={handleTouchEnd}
        onContextMenu={handleClick}
        className="home"
      >
        {pending && !props.explore && (
          <Grid container spacing={2}>
            <Grid item>
              <Skeleton width={100} height={60} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton width={70} height={60} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton width={120} height={60} animation="wave" />
            </Grid>
          </Grid>
        )}

        {props.explore && (
          <CollectionDrawer
            setOpenSignIn={setOpenSignIn}
            share={props.share}
            explore={props.explore}
            segmentToEdit={segmentToEdit}
            setSegmentAction={setSegmentAction}
            segmentAction={segmentAction}
            setCopyColMenuState={setCopyColMenuState}
            copyColMenuState={copyColMenuState}
            contextItemIndex={rightClickedItem.index}
            contextItemId={rightClickedItem.id}
            setSubMenuState={setSubMenuState}
            subMenuState={subMenuState}
            contextState={contextState}
            category={category}
            setCategory={setCategory}
            editObj={editObj}
            addedVid={addedVid}
            setAddedVid={setAddedVid}
            userId={"n6dkzNQneoXkbuCHgd4nG9iHtjh2"}
            attachmentChange={attachmentChange}
          ></CollectionDrawer>
        )}

        {currentUser && !props.explore && !pending && (
          <div>
            <CollectionDrawer
              segmentToEdit={segmentToEdit}
              setSegmentAction={setSegmentAction}
              segmentAction={segmentAction}
              setCopyColMenuState={setCopyColMenuState}
              copyColMenuState={copyColMenuState}
              contextItemIndex={rightClickedItem.index}
              contextItemId={rightClickedItem.id}
              setSubMenuState={setSubMenuState}
              subMenuState={subMenuState}
              contextState={contextState}
              category={category}
              setCategory={setCategory}
              editObj={editObj}
              addedVid={addedVid}
              setAddedVid={setAddedVid}
              userId={currentUser.uid}
              attachmentChange={attachmentChange}
            ></CollectionDrawer>
          </div>
        )}

        {/* Logged in user - with no subscription */}
        {!props.explore &&
          false &&
          currentUser &&
          !pending &&
          accountInfo.role === "none" &&
          accountInfo.student === false && (
            <div id="no-account-page">
              <VideoBackground>
                <h2>Þú ert því miður ekki með virkan aðgang</h2>
                <p>
                  Fáðu tilboð með því að hafa samband við{" "}
                  <a href="mailto:youlearn@youlearn.is">youlearn@youlearn.is</a>
                </p>
                <AlertMessage
                  type="info"
                  message="Sjálfsafgreiðsla YouLearn er í vinnslu."
                ></AlertMessage>
                <NoAccountImage />
              </VideoBackground>
            </div>
          )}

        {/* Landing page - when not logged in */}
        {!currentUser && !pending && !props.explore && (
          <div className="landing-page">
            <Grid container className="top-banner">
              <Grid item lg={7} md={6} sm={12}>
                <Typography variant="h1" component="h1">
                  {props.t("chaosToBliss")}
                </Typography>
                <Typography variant="body2" component="p">
                  {props.t("productive")}
                </Typography>
                <Typography variant="body2" component="p">
                  <LinkButton
                    variant="body2"
                    component="button"
                    root={{ color: "#a4a3ee !important" }}
                    onClick={() => {
                      setOpenSignIn(true);
                    }}
                  >
                    {" "}
                    Sign In
                  </LinkButton>{" "}
                  to add videos and music from YouTube, Vimeo or DropBox to your
                  collections
                </Typography>
                <Typography variant="body2" component="p">
                  Or{" "}
                  <LinkButton
                    variant="body2"
                    component="button"
                    root={{ color: "#a4a3ee !important" }}
                    onClick={() => {
                      window.location =
                        process.env.REACT_APP_FRONT_END + "/explore";
                    }}
                  >
                    {" "}
                    Try It Now{" "}
                  </LinkButton>{" "}
                  with a collection of great instructional videos
                </Typography>
                <div className="actions">
                  <a href="mailto:youlearn@youlearn.is?subject=Bóka kynningarfund á YouLearn kennslukerfi">
                    <Button endIcon={<ArrowRight />} className="register-btn">
                      {props.t("contactUs")}
                    </Button>
                  </a>
                </div>
              </Grid>
              <Grid item lg={5} md={6} sm={12}>
                <LandingPageImage id="landing-page-image" />
                {/* <img
                  src={LandingPageImage}
                  alt="Image of YouLearn in desktop and mobile"
                /> */}
              </Grid>
            </Grid>
            {/*<div>
              <InputYoutubeSearch
                setLicks={setLicks}
                handleVideoChange={handleVideoChange}
                searchString={searchString}
                landing={true}
                setSearchString={setSearchString}
              ></InputYoutubeSearch>
              <Grid container>
                <Grid item lg={9} md={9} sm={9}>
                  <h2>{videoObj.title}</h2>
                  <p>{videoObj.description}</p>
                </Grid>
                <Grid lg={3} md={3} sm={3}></Grid>
              </Grid>
              <VideoContainer
                licks={licks}
                teacher={true}
                t={props.t}
                landing={true}
                trimmingVideo={true}
                addDialog={true}
                videoId={videoId}
                setSegmentAction={setSegmentAction}
                segmentAction={segmentAction}
                segmentToEdit={segmentToEdit}
              ></VideoContainer>
            </div>*/}
          </div>
        )}
      </Container>
      <Menu
        keepMounted
        open={segmentContextState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          segmentContextState.mouseY !== null &&
          segmentContextState.mouseX !== null
            ? {
                top: segmentContextState.mouseY,
                left: segmentContextState.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setSegmentContextState(initialState);
            if (props.explore) {
              alert("Sign in man!");
              setContextState(initialState);
            } else {
              setSegmentAction("rename");
            }
          }}
        >
          {props.t("rename")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.explore) {
              alert("Sign in man!");
              setContextState(initialState);
            } else {
              displayMessage("info", props.t("selectTimestamp"));
              setSegmentAction("time");
            }
            setSegmentContextState(initialState);
          }}
        >
          {props.t("editTimestamp")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSegmentContextState(initialState);
            if (props.explore) {
              alert("Sign in man!");
            } else {
              setSegmentAction("copy");
            }
          }}
        >
          {props.t("copyAsClip")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.explore) {
              alert("Sign in man!");
            } else {
              setSegmentAction("delete");
            }
            setSegmentContextState(initialState);
          }}
        >
          {props.t("delete")}
        </MenuItem>
      </Menu>

      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >
        {colEdit ? (
          <>
            <RenameCollectionDialog
              setContextState={setContextState}
              item={rightClickedItem}
              setRightClickedItemName={setRightClickedItemName}
              rightClickedItemName={rightClickedItemName}
              editObj={editObj}
              setEditObj={setEditObj}
            ></RenameCollectionDialog>
            <MenuItem
              onClick={() => {
                if (props.explore) {
                  alert("Sign in man!");
                  setContextState(initialState);
                } else {
                  setCopyColMenuState(1);
                }
              }}
            >
              {props.t("copyTo") + " ..."}
            </MenuItem>
            <AddStudentDialog
              category={category}
              setContextState={setContextState}
              rightClickedItemName={rightClickedItemName}
            />

            <ShareDialog
              category={category}
              setContextState={setContextState}
              rightClickedItemName={rightClickedItemName}
            />
          </>
        ) : (
          <>
            <RenameItemDialog
              setContextState={setContextState}
              item={rightClickedItem}
              setRightClickedItemName={setRightClickedItemName}
              rightClickedItemName={rightClickedItemName}
              editObj={editObj}
              setEditObj={setEditObj}
            ></RenameItemDialog>
            <CopyItemDialog
              setAddedVid={setAddedVid}
              item={rightClickedItem}
              setContextState={setContextState}
            ></CopyItemDialog>
          </>
        )}
        {rightClickedItem.type == "subcollection" && (
          <>
            <MenuItem
              onClick={() => {
                if (contextState.mouseX != null) {
                  if (props.explore) {
                    alert("Sign in man!");
                  } else {
                    setAddVidOpen(true);
                  }
                }
              }}
            >
              {props.t("addYtVideo")}
              <AddVideoDialog
                setAddedVid={setAddedVid}
                setAddVidOpen={setAddVidOpen}
                setContextState={setContextState}
                open={addVidOpen}
                subcollectionId={rightClickedItem.id}
                subcollectionName={rightClickedItemName}
              ></AddVideoDialog>{" "}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (contextState.mouseX != null) {
                  if (props.explore) {
                    alert("Sign in man!");
                  } else {
                    setAddLinkOpen(true);
                  }
                }
              }}
            >
              {" "}
              <AddDownloadLink
                setAddedVid={setAddedVid}
                setAddLinkOpen={setAddLinkOpen}
                open={addLinkOpen}
                setContextState={setContextState}
                subcollectionId={rightClickedItem.id}
                subcollectionName={rightClickedItemName}
              ></AddDownloadLink>
              {props.t("addLink")}
            </MenuItem>
            <MoveItemDialog
              setAddedVid={setAddedVid}
              item={rightClickedItem}
              setContextState={setContextState}
              handleRemove={handleRemove}
            ></MoveItemDialog>
          </>
        )}
        {(rightClickedItem.type == "link" ||
          rightClickedItem.type == "video") && (
          <React.Fragment>
            <MenuItem
              onClick={() => {
                if (contextState.mouseX != null) {
                  setAddAttachOpen(true);
                }
              }}
            >
              {" "}
              <AddAttachmentDialog
                setAddAttachOpen={setAddAttachOpen}
                attachmentChange={attachmentChange}
                setAttachmentChange={setAttachmentChange}
                setContextState={setContextState}
                open={AddAttachOpen}
                videoId={rightClickedItem.id}
                videoName={rightClickedItemName}
              ></AddAttachmentDialog>{" "}
              {props.t("addAttatchment")}{" "}
            </MenuItem>
            <MoveItemDialog
              setAddedVid={setAddedVid}
              item={rightClickedItem}
              setContextState={setContextState}
              handleRemove={handleRemove}
            ></MoveItemDialog>
            <MenuItem onClick={() => setSubMenuState(1)}>
              {props.t("moveToSubcollection")}
            </MenuItem>
          </React.Fragment>
        )}
        <DeleteDialog
          type={rightClickedItem.type}
          name={rightClickedItemName}
          setContextState={setContextState}
          handleDelete={handleDelete}
        ></DeleteDialog>
      </Menu>
      <CustomSnackBar></CustomSnackBar>
    </Layout>
  );
});
export default Home;
