import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import LicenceTable from "./LicenceTable";
import axios from "axios";
import DepartmentAdminTable from "./DepartmentAdminTable.js";

export default function AdminDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [orgName, setOrgName] = React.useState("");
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState(null);
  const [licences, setLicences] = React.useState([]);
  const [licenceQtInput, setLicenceQtInput] = React.useState(0);
  const [licenceQt, setLicenceQt] = React.useState();
  const [adding, setAdding] = React.useState(false);
  const [inputRole, setInputRole] = React.useState("teacher");
  const [emailInput, setEmailInput] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [departmentNameInput, setDepartmentNameInput] = React.useState("");
  const [addingDepartment, setAddingDepartment] = React.useState();
  const [copyableDepartments, setCopyableDepartments] = React.useState();
  const { getToken, sendSignInLinkToEmail } = useAuth();

  const getOrgs = () => {
    getToken().then((idToken) => {
      axios
        .get(process.env.REACT_APP_RESOURCE_SERVER + "/api/organizations/", {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .then((response) => {
          setOrgs(response.data);
          if (response.data.length != 0) {
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].id == 2) {
                setOrg(response.data[index].id);
              }
            }
          }
          return;
        });
    });
  };

  const clearUnusedLicences = () => {
    getToken().then((idToken) => {
      axios
        .delete(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/licences",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          setLicenceQt(licenceQt - response.data.deleted);
        })
        .catch((error) => {});
    });
  };

  const handleDepartmentCopy = (index) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/organizations/${org}/departments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },

          body: JSON.stringify({
            oldId: copyableDepartments[index].id,
            name: copyableDepartments[index].name,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          getDepartments();
          return response.json();
        })
        .then((data) => {})
        .catch((err) => {
          //console.log(err);
          /* eslint-disable no-console */
        });
    });
  };

  const getDepartments = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/departments",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "vesen") {
            setDepartments(response.data);
          }
        });
    });
  };

  const getCopyableDepartments = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            2 +
            "/departments",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "vesen") {
            setCopyableDepartments(response.data);
          }
        });
    });
  };

  const getLicences = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/licences",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          var tempLicences = [];
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].email != "none") {
              tempLicences.push(response.data[index]);
            }
          }
          setLicenceQt(response.data.length);
          setLicences(tempLicences);
          if (response.data.length != 0) {
            //console.log("engin læsens");
            //setOrg(response.data[0].id)
          }
          return;
        });
    });
  };

  const handleClickOpen = () => {
    getCopyableDepartments();
    getOrgs();
    setOpen(true);
  };

  const handleClose = () => {
    props.closeDrawer();
    setOpen(false);
  };

  const handleOrgAdd = () => {
    var body = { name: dbFormat(orgName) };
    getToken().then((idToken) => {
      axios
        .post(
          process.env.REACT_APP_RESOURCE_SERVER + "/api/organizations/",
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {});
    });
  };

  const handleLicenceAdd = () => {
    var body = { quantity: licenceQtInput, keyword: "tonakfih" };

    getToken().then((idToken) => {
      axios
        .post(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/licences",
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          setLicenceQt(licenceQt + response.data.added);
        });
    });
  };

  const handleDepartmentAdd = () => {
    var body = { name: departmentNameInput };

    getToken().then((idToken) => {
      axios
        .post(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/departments",
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          var newDepartments = departments;
          newDepartments.push({
            id: response.data.id,
            name: departmentNameInput,
          });
          setAddingDepartment(false);
          setDepartmentNameInput("");
          setDepartments([...newDepartments]);
        });
    });
  };

  const handleDepartmentDelete = (id) => {
    getToken().then((idToken) => {
      axios
        .delete(
          process.env.REACT_APP_RESOURCE_SERVER + "/api/departments/" + id,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          const newDepartments = departments.filter((department) => {
            return department.id != id;
          });
          setDepartments([...newDepartments]);
        });
    });
  };

  const handleLicenceActivation = () => {
    setAdding(false);
    // for later
    var body = { email: emailInput, role: inputRole, used: 0 };
    getToken().then((idToken) => {
      axios
        .put(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            org +
            "/licences",
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "No Licences available") {
            sendSignInLinkToEmail([emailInput]);
            setLicences([
              ...licences,
              {
                id: response.data.id,
                email: emailInput,
                role: inputRole,
                used: 0,
              },
            ]);
          }
        });
    });
  };

  const handleLicenceDeactivation = (id) => {
    var body = { email: "none", role: "none", used: 0, name: "none", org: 0 };
    getToken().then((idToken) => {
      axios
        .put(
          process.env.REACT_APP_RESOURCE_SERVER + "/api/licences/" + id,
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data != "No Licences available") {
            setLicences([...licences.filter((licence) => licence.id != id)]);
          }
        });
    });
  };

  const handleInputRoleChange = (event) => {
    setInputRole(event.target.value);
  };

  useEffect(() => {
    getDepartments();
    getLicences();
  }, [org]);

  return (
    <React.Fragment>
      <ListItem button onClick={handleClickOpen}>
        <ListItemText primary="Admin viðmót" />
      </ListItem>
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Admin panel</DialogTitle>
        <DialogContent>
          <DialogContentText>Organizations</DialogContentText>
          <Select
            labelId="collection-select-label"
            id="collection-select"
            value={org}
            onChange={(e) => setOrg(e.target.value)}
          >
            {orgs &&
              orgs.map((org) => <MenuItem value={org.id}>{org.name}</MenuItem>)}
          </Select>
          <form onSubmit={(e) => handleLicenceAdd(e)}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Add licences"
              type="number"
              value={licenceQtInput}
              onChange={(e) => setLicenceQtInput(e.target.value)}
              fullWidth
            />
            <DialogActions></DialogActions>
            <DialogActions>
              <Button onClick={(e) => handleLicenceAdd(e)} color="primary">
                Staðfesta
              </Button>
            </DialogActions>
          </form>
          Þú hefur veitt {licences.length} leyfi af {licenceQt}
          <LicenceTable
            inputRole={inputRole}
            handleLicenceDeactivation={handleLicenceDeactivation}
            handleLicenceActivation={handleLicenceActivation}
            handleInputRoleChange={handleInputRoleChange}
            licences={licences}
            email={emailInput}
            setEmailInput={setEmailInput}
            adding={adding}
            setAdding={setAdding}
          ></LicenceTable>
          <br></br>
          <Button onClick={clearUnusedLicences}> Eyða ónotuðum leyfum </Button>
          <DepartmentAdminTable
            departments={departments}
            departmentNameInput={departmentNameInput}
            setDepartmentNameInput={setDepartmentNameInput}
            handleDepartmentAdd={handleDepartmentAdd}
            handleDepartmentDelete={handleDepartmentDelete}
            adding={addingDepartment}
            setAdding={setAddingDepartment}
          />
          <DepartmentAdminTable
            departments={copyableDepartments}
            departmentNameInput={departmentNameInput}
            setDepartmentNameInput={setDepartmentNameInput}
            handleDepartmentAdd={handleDepartmentAdd}
            handleDepartmentDelete={handleDepartmentDelete}
            handleDepartmentCopy={handleDepartmentCopy}
            adding={false}
            copyList={true}
            setAdding={setAddingDepartment}
          />
          <DialogContentText>add new organization</DialogContentText>
          <form onSubmit={(e) => handleOrgAdd(e)}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="email"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              fullWidth
            />
            <DialogActions>
              <Button onClick={(e) => handleOrgAdd(e)} color="primary">
                Staðfesta
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Hætta
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
