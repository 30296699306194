/**
 * Layout component for each page with
 * header and footer
 */
import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import Header from "./Header";
import Footer from "./Footer";
import { withStyles } from "@material-ui/core/styles";

const MainContainer = withStyles((theme) => ({
  root: {
    background: theme.palette.background.gradient,
    overflowX: "hidden",
  },
}))(Paper);

const Layout = (props) => {
  return (
    <MainContainer className="main" square={true}>
      <Header
        explore={props.explore}
        openSignIn={props.openSignIn}
        setOpenSignIn={props.setOpenSignIn}
        setAddedVid={props.setAddedVid}
        handleThemeChange={props.handleThemeChange}
        darkState={props.darkState}
        landingPage={props.landingPage}
      />
      <Container maxWidth={props.maxWidth ? props.maxWidth : "lg"}>
        {props.children}
        <Footer />
      </Container>
    </MainContainer>
  );
};

export default Layout;
