import React, { useEffect, useRef } from "react";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Icon } from "@iconify/react";
import chalkboardTeacher from "@iconify/icons-la/chalkboard-teacher";
import organizationLine from "@iconify/icons-clarity/organization-line";
import bxHomeHeart from "@iconify/icons-bx/bx-home-heart";
import { useAuth } from "../auth/AuthContext";
import "../i18n";
import { withTranslation } from "react-i18next";
import axios from "axios";
import i18n from "../i18n";

/* eslint-disable no-unused-expressions */

const OrganizationMenu = withTranslation()((props) => {
  const { accountInfo } = useAuth();
  const orgListEndRef = useRef(null);
  const [exploreCategories, setExploreCategories] = React.useState(null);

  const select = (elem) => {
    const selected = document.getElementsByClassName("selected-org-item")[0];
    if (selected) {
      selected.classList.remove("selected-org-item");
    }
    const nowSelected = document.getElementsByClassName(elem)[0];
    if (nowSelected) {
      nowSelected.classList.add("selected-org-item");
    }
    props.setCategory(elem);
  };

  // búa til fall sem sækir explore catecoríin og gera skeletons meðan verið er að ná í draslið!

  const scrollToBottom = () => {
    orgListEndRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [accountInfo.departments]);

  useEffect(() => {
    if (props.explore) {
      if (!exploreCategories) {
        axios
          .get(
            process.env.REACT_APP_RESOURCE_SERVER + "/api/explore/categories"
          )
          .then((response) => {
            setExploreCategories(response.data);
            select(254);
          });
      }
    } else if (!props.category) {
      if (
        accountInfo.category &&
        accountInfo.category != "null" &&
        accountInfo.category != "false"
      ) {
        select(accountInfo.category);
      } else {
        if (accountInfo.role == "student") {
          select("courses");
        }
      }
    }
    const isOrgs = [1, 28, 45, 87, 90, 96, 97, 101, 103];
    if (
      isOrgs.some((org) => {
        return org == accountInfo.org;
      })
    ) {
      i18n.changeLanguage("is");
    } else {
      i18n.changeLanguage("en");
    }
  }, [accountInfo.org]);

  return (
    <List className="organization-list">
      {accountInfo.departments &&
        !props.explore &&
        accountInfo.departments.map((department) => {
          return (
            <ListItem
              className={department.id + " org-item"}
              id="head"
              button
              onClick={() => {
                select(department.id);
              }}
            >
              <ListItemIcon
                style={{
                  fontSize: "23px",
                  marginRight: "-18px",
                  marginLeft: "-17px",
                }}
              >
                <Icon icon={organizationLine}></Icon>
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "24px !important" }}
                primary={department.name}
              ></ListItemText>
            </ListItem>
          );
        })}
      {exploreCategories &&
        exploreCategories.map((department) => {
          return (
            <ListItem
              className={department.id + " org-item"}
              id="head"
              button
              onClick={() => {
                select(department.id);
              }}
            >
              <ListItemIcon
                style={{
                  fontSize: "23px",
                  marginRight: "-18px",
                  marginLeft: "-17px",
                }}
              >
                <Icon icon={organizationLine}></Icon>
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "24px !important" }}
                primary={department.name}
              ></ListItemText>
            </ListItem>
          );
        })}
      {!props.explore && (
        <ListItem
          className={"shared org-item"}
          id="head"
          button
          onClick={() => {
            select("shared");
          }}
        >
          <ListItemIcon
            style={{
              fontSize: "23px",
              marginRight: "-18px",
              marginLeft: "-17px",
            }}
          >
            <Icon icon={organizationLine}></Icon>
          </ListItemIcon>
          <ListItemText
            style={{ fontSize: "24px !important" }}
            primary={props.t("sharedWithMe")}
          ></ListItemText>
        </ListItem>
      )}
      {(accountInfo.role == "teacher" || accountInfo.role == "admin") &&
        !props.explore && (
          <ListItem
            className=" students org-item"
            button
            onClick={() => {
              select("students");
            }}
          >
            <ListItemIcon
              style={{
                fontSize: "23px",
                marginRight: "-18px",
                marginLeft: "-17px",
              }}
            >
              <Icon icon={chalkboardTeacher}></Icon>
            </ListItemIcon>
            <ListItemText
              primary={props.t("studentsCollections")}
            ></ListItemText>
          </ListItem>
        )}
      {accountInfo.role == "student" && !props.explore && (
        <ListItem
          className="selected-org-item courses org-item"
          button
          onClick={() => {
            select("courses");
          }}
        >
          <ListItemIcon
            style={{
              fontSize: "24px",
              marginRight: "-18px",
              marginLeft: "-17px",
            }}
          >
            <Icon icon={chalkboardTeacher}></Icon>
          </ListItemIcon>
          <ListItemText primary="Mín námskeið"></ListItemText>
        </ListItem>
      )}

      {(accountInfo.role == "teacher" ||
        accountInfo.role == "admin" ||
        accountInfo.role == "none" ||
        accountInfo.role == "student" ||
        accountInfo.role == "personal") &&
        !props.explore && (
          <ListItem
            className="selected-org-item personal org-item"
            button
            onClick={() => {
              select("personal");
            }}
          >
            <ListItemIcon
              style={{
                fontSize: "24px",
                marginRight: "-16px",
                marginLeft: "-18px",
              }}
            >
              <Icon icon={bxHomeHeart} />
            </ListItemIcon>
            <ListItemText primary={props.t("personal")}></ListItemText>
          </ListItem>
        )}
      <div ref={orgListEndRef}></div>
    </List>
  );
});

export default OrganizationMenu;
