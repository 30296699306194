import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Signup from "./Signup.js";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImg from "../../images/piano-girl.png";
import logo from "../../images/logo.png";

function Copyright() {
  return (
    <Typography
      style={{ color: "black" }}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      <Link style={{ color: "black" }} color="inherit" href="/">
        Youlearn
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundImage: `url(${backgroundImg})`,
    // backgroundImage: `url("../../images/piano-girl.png")`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginBottom: "-15px",
    backgroundColor: "transparent",
    height: 70,
    width: 70,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
    backgroundColor: "white !important",
    boxShadow: "none",
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  // const [values, handleChange] = useForm({ email: "", password: "" });
  // const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        className={classes.background}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img src={logo} style={{ marginLeft: "3.5px" }} />
          </Avatar>
          <Typography component="h1" variant="h5"></Typography>
          {/*<div id="sign-in-widget" />*/}
          <Signup />
          {/* <Copyright /> */}
        </div>
      </Grid>
    </Grid>
  );
}
