import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Logout from "../Logout";
import { Switch, FormControlLabel } from "@material-ui/core";
import LicenceDialog from "./LicenceDialog";
import DepartmentAccessDialog from "./DepartmentAccessDialog";
import AdminDialog from "./AdminDialog";
import { useAuth } from "../auth/AuthContext";
import StudentsDialog from "./StudentsDialog";
import StudentsAdminDialog from "./StudentsAdminDialog";
//import educationIcon from "@iconify/icons-carbon/education";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  userName: {
    color: theme.palette.primary.lightHeaderColor,
    fontSize: 16,
    fontWeight: 600,
  },
  departmentName: {
    fontWeight: 600,
  },
}));

export default function MenuDrawer(props) {
  const { accountInfo } = useAuth();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const close = () => {
    setState({ ...state, right: false });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, true)}
      // onKeyDown={toggleDrawer(anchor, true)}
    >
      <div className="header">
        <span className="title">Notandastillingar</span>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          className="close-button"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <List>
        <ListItem className="user-name">
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={props.userName}
            classes={{ primary: classes.userName }}
          />
        </ListItem>
        <Divider />
        <StudentsAdminDialog closeDrawer={close}></StudentsAdminDialog>

        {(accountInfo.role == "admin" || props.userEmail) && (
          <>
            <LicenceDialog closeDrawer={close} />
            <DepartmentAccessDialog closeDrawer={close} />
          </>
        )}
        {accountInfo.role != "student" &&
          accountInfo.role != "none" &&
          accountInfo.role != "personal" && (
            <StudentsDialog closeDrawer={close}></StudentsDialog>
          )}
      </List>
      <Divider />
      {
        //  <List>
        //       <ListItem button onClick={props.handleThemeChange}>
        //         <FormControlLabel
        //           control={
        //             <Switch
        //               checked={props.darkState}
        //               onChange={props.handleThemeChange}
        //               color="default"
        //             />
        //           }
        //         />
        //         <ListItemText primary="Dökkt viðmót" />
        //       </ListItem>
        //     </List>
      }
      <List>
        <Logout />
      </List>
      {props.userEmail && <AdminDialog closeDrawer={close} />}
    </div>
  );

  return (
    <>
      <IconButton
        onClick={toggleDrawer("right", true)}
        style={{ color: "#A4A3EE", float: "right !important" }}
        className="user-menu-button"
      >
        <AccountCircleIcon />
      </IconButton>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          className="user-menu-drawer"
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </>
  );
}
