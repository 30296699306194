import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CollectionSideMenu from "./CollectionSideMenu";
import OrganizationMenu from "./OrganizationMenu";
import { dynamicSort, getFileType } from "../../helpers";
import SpeedDialAddButton from "./SpeedDialAddButton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import LoadingCol from "./LoadingCol";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AlertMessage from "../AlertMessage";
import "../i18n";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  subPoser: {
    fontFamily: "Raleway",
    padding: 0,
    marginBottom: 7,
    position: "absolute",
    top: "-200px",
    borderBottom: "2px solid #605DF9",
  },
  poser: {
    position: "absolute",
    top: "-100px",
    //borderBottom: "2px solid #605DF9",
  },
  subText: {
    fontWeight: "bold",
  },
}));

const CollectionContainer = withTranslation()((props) => {
  const { user_id, item_type, item_id, share_record_id, millisec } =
    useParams();
  const classes = useStyles();
  const [items, setItems] = useState(null); //Videos
  const [categoryChange, setCategoryChange] = useState(false);
  const {
    getToken,
    setCurrSubCollection,
    currentUser,
    accountInfo,
    displayMessage,
  } = useAuth();
  const [contextSubcols, setContextSubcols] = useState([]);
  const [subCopy, setSubcopy] = useState(false);

  const updateBrowseValue = () => {
    var next = selectNext(true);
    var prev = selectPrev(true);
    if (next && prev) {
      props.setBrowseVids("both");
    }
    if (next && !prev) {
      props.setBrowseVids("next");
    }
    if (!next && prev) {
      props.setBrowseVids("prev");
    }
    if (!next && !prev) {
      props.setBrowseVids("none");
    }
  };

  const deleteItem = (id, type) => {
    if (props.explore) {
      alert("sign in man!");
      return;
    }
    let isLast = false;
    if (props.selected) {
      if (id == props.selected.id) {
        if (selectNext(true)) {
          selectNext(false);
        } else {
          isLast = true;
        }
      }
    }

    getToken().then((idToken) => {
      var newArray = removeItem(id);
      if (isLast) {
        setCategoryChange(true);
      }
      if (!newArray) {
        return;
      }
      setItems([...newArray]);
      updateBrowseValue();
      if (type === "subcollection") {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .catch((err) => {});
      }
      if (type === "video" || type === "link") {
        fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .catch((err) => {});
      }
    });
  };
  const updateVidList = (vidList, subId, vidId) => {
    var newArray = removeItem(vidId);
    var subIndex = newArray.findIndex((element) => element.id === subId);
    newArray[subIndex].vidList = vidList;
    setItems([...newArray]);
    updateBrowseValue();
  };

  const dragEnter = () => {
    var hovered = document.getElementsByClassName("sub-drag-hover");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("sub-drag-hover");
    }
    hovered = document.getElementsByClassName("drag-hover-top");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-top");
    }
    hovered = document.getElementsByClassName("drag-hover-bottom");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-bottom");
    }
  };

  const clearAndSetSelected = (selected) => {
    if (selected.id != props.selected.id) {
      const currSelected = document.getElementsByClassName("selected-item");
      for (var i = 0; i < currSelected.length; i++) {
        currSelected[i].classList.remove("selected-item");
      }
      props.setSelected(selected);
    }
  };

  const updateSelected = () => {
    const currSelected = document.getElementsByClassName("selected-item");
    for (var i = 0; i < currSelected.length; i++) {
      currSelected[i].classList.remove("selected-item");
    }
    var selElement = document.getElementById(props.selected.id.toString());
    if (selElement) {
      selElement.classList.add("selected-item");
    }
  };

  const addSubcollection = (name, playlist) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${props.collectionId}/subcollections`,
        {
          method: "POST",
          body: JSON.stringify({ name: dbFormat(name), playlist: playlist }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          if (playlist) {
            setSubcopy(!subCopy);
          } else {
            var newArray = items;
            newArray.push({
              id: data.id,
              order_no: data.order_no,
              name: name,
              max_order_no: 1,
              vidList: [],
            });
          }
          setItems([...newArray]);
        })
        .catch((err) => {
          console.error(err);
          return [];
        });
    });
  };

  const arrangeSubcollection = (droppedOnId, subcollection) => {
    getToken().then((idToken) => {
      const droppedIndex = items.findIndex(
        (element) => element.id === subcollection.id
      );
      const droppedVidList = items[droppedIndex].vidList;
      var newArray = items.filter((element) => subcollection.id !== element.id);

      var subIndex = newArray.findIndex(
        (element) => element.id === droppedOnId
      );
      if (droppedIndex <= subIndex) {
        subIndex = subIndex + 1;
        if (subIndex === newArray.length) {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${subcollection.id}`,
            {
              method: "PUT",
              body: JSON.stringify({ collectionId: props.collectionId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                return Promise.reject();
              }
              return response.json();
            })
            .then((data) => {
              newArray.push({
                id: subcollection.id,
                order_no: data.order_no,
                name: subcollection.name,
                max_order_no: 1,
                open: subcollection.open,
                vidList: droppedVidList,
              });
              setItems([...newArray]);
            })
            .catch((err) => {
              return [];
            });
          return;
        }
      }
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${subcollection.id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            orderNo: newArray[subIndex].order_no,
            collectionId: props.collectionId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })

        .catch((err) => {
          return [];
        });
      subcollection.max_order_no = 0;
      subcollection.order_no = newArray[subIndex].order_no;
      subcollection.vidList = droppedVidList;
      for (let index = subIndex; index < newArray.length; index++) {
        newArray[index].order_no = newArray[index].order_no + 1;
      }
      newArray.splice(subIndex, 0, subcollection);
      setItems([...newArray]);
    });
  };

  const toggleOpen = (id) => {
    var newArray = items;
    const index = newArray.findIndex((element) => element.id === id);
    newArray[index].open = !newArray[index].open;
    setItems([...newArray]);
    return;
  };

  const addVidToBack = (video) => {
    getToken((idToken) => {
      var link = false;
      var linkType = "video";
      if (
        video.youtube_id.startsWith("http") ||
        video.youtube_id.startsWith("www") ||
        video.youtube_id.startsWith("dropbox")
      ) {
        linkType = getFileType(video.youtube_id);
        link = true;
      }
      fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${video.id}`, {
        method: "PUT",
        body: JSON.stringify({ collectionId: props.collectionId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          var newArray = removeItem(video.id);
          newArray.push({
            linkType: link,
            link: link,
            id: video.id,
            order_no: data.order_no,
            name: video.name,
            youtube_id: video.youtube_id,
            start: video.start,
            ending: video.ending,
          });
          setItems([...newArray]);
        })
        .catch((err) => {
          return [];
        });
    });
  };

  const renameItem = (id, name) => {
    var selectedIsChanged = false;

    if (props.selected != 0) {
      if (id == props.selected.id) {
        selectedIsChanged = true;
      }
    }

    if (!items) {
      return;
    }
    var newArray = items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id === id) {
            newArray[i].vidList[j].name = name;
            setItems([...newArray]);
            if (selectedIsChanged) {
              clearAndSetSelected(items[i].vidList[j]);
              props.setChangeInSelected(!props.changeInSelected);
            }
            return;
          }
        }
      }
      if (items[i].id === id) {
        newArray[i].name = name;
        setItems([...newArray]);
        if (selectedIsChanged) {
          clearAndSetSelected(items[i]);
          props.setChangeInSelected(!props.changeInSelected);
        }
        return;
      }
    }
    return null;
  };

  const findVid = () => {
    if (!items) {
      return;
    }
    if (props.selected.id == -3) {
      var lastItem = findItem(accountInfo.video_id);
      if (lastItem) {
        clearAndSetSelected(lastItem);
        return;
      }
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (!items[i].open) {
            setCurrSubCollection(items[i].name);
            toggleOpen(items[i].id);
            clearAndSetSelected(items[i].vidList[j]);
          }
          return;
        }
      } else {
        setCurrSubCollection(null);
        clearAndSetSelected(items[i]);
        return;
      }
    }
    clearAndSetSelected({
      youtube_id: "afzmwAKUppU",
      name: "Welcome to youlearn!",
      id: -1,
      start: 0,
      ending: 10,
    });
    return;
  };

  const findVidSubcol = (id) => {
    if (!items) {
      return;
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id == id) {
            return items[i].id;
          }
        }
      } else if (id == items[i].id) {
        return null;
      }
    }
    return null;
  };

  const removeItem = (id) => {
    if (!items) {
      return;
    }
    var newArray = items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id == id) {
            newArray[i].vidList.splice(j, 1);
            return newArray;
          }
        }
      }
      if (items[i].id == id) {
        newArray.splice(i, 1);
        return newArray;
      }
    }
    return newArray;
  };

  const findItem = (id) => {
    if (!items) {
      return;
    }
    var newArray = items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id == id) {
            setCurrSubCollection(items[i].name);
            if (!items[i].open) {
              toggleOpen(items[i].id);
            }
            return newArray[i].vidList[j];
          }
        }
      }
      if (items[i].id == id) {
        return items[i];
      }
    }
    return false;
  };

  const addVid = (droppedOnId, video) => {
    getToken().then((idToken) => {
      var link = false;
      var linkType = "video";
      if (
        video.youtube_id.startsWith("http") ||
        video.youtube_id.startsWith("www") ||
        video.youtube_id.startsWith("dropbox")
      ) {
        linkType = getFileType(video.youtube_id);
        link = true;
      }
      var addBehind = false;
      var newArray = items.filter((element) => video.id != element.id);
      var vidIndex = newArray.findIndex((element) => element.id == droppedOnId);
      if (newArray.length != items.length) {
        if (items.findIndex((element) => element.id == video.id) <= vidIndex) {
          vidIndex = vidIndex + 1;
          addBehind = true;
          if (vidIndex == newArray.length) {
            fetch(
              `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${video.id}`,
              {
                method: "PUT",
                body: JSON.stringify({ collectionId: props.collectionId }),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return Promise.reject();
                }
                return response.json();
              })
              .then((data) => {
                newArray.push({
                  youtube_id: video.youtube_id,
                  linkType: linkType,
                  link: link,
                  start: video.start,
                  ending: video.ending,
                  id: video.id,
                  order_no: data.order_no,
                  name: video.name,
                });
                setItems([...newArray]);
                updateBrowseValue();
              })
              .catch((err) => {
                return [];
              });
            return;
          }
        }
      }
      fetch(`${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${video.id}`, {
        method: "PUT",
        body: JSON.stringify({
          orderNo: newArray[vidIndex].order_no,
          collectionId: props.collectionId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .catch((err) => {
          return [];
        });
      video.order_no = newArray[vidIndex].order_no;
      for (let index = vidIndex; index < newArray.length; index++) {
        newArray[index].order_no = newArray[index].order_no + 1;
      }
      newArray = removeItem(video.id);
      vidIndex = newArray.findIndex((element) => element.id == droppedOnId);
      if (addBehind) {
        vidIndex = vidIndex + 1;
      }
      newArray.splice(vidIndex, 0, video);
      setItems([...newArray]);
      updateBrowseValue();
      return;
    });
  };

  const selectLastItem = (itemArray, isChecking) => {
    var revItemArray = itemArray.reverse();
    for (let i = 0; i < revItemArray.length; i++) {
      if (revItemArray[i].vidList) {
        for (let j = revItemArray[i].vidList.length - 1; j >= 0; j--) {
          if (isChecking) {
            return true;
          }
          if (!revItemArray[i].open) {
            toggleOpen(revItemArray[i].id);
            setCurrSubCollection(revItemArray[i].name);
            clearAndSetSelected(revItemArray[i].vidList[j]);
          } else {
            setCurrSubCollection(revItemArray[i].name);
            clearAndSetSelected(revItemArray[i].vidList[j]);
          }
          return;
        }
      } else {
        if (isChecking) {
          return true;
        }
        setCurrSubCollection(null);
        clearAndSetSelected(revItemArray[i]);
        return;
      }
    }
    return false;
  };
  const selectPrev = (isChecking) => {
    if (!items) {
      return;
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id == props.selected.id) {
            if (j > 0) {
              if (isChecking) {
                return true;
              }
              setCurrSubCollection(items[i].name);
              clearAndSetSelected(items[i].vidList[j - 1]);
            } else {
              if (items[i].open && !isChecking) {
                toggleOpen(items[i].id);
              }
              return selectLastItem(items.slice(0, i), isChecking);
            }
          }
        }
      } else {
        if (items[i].id == props.selected.id) {
          if (i > 0) {
            if (isChecking) {
              return true;
            }
            setCurrSubCollection(null);
            clearAndSetSelected(items[i - 1]);
          }
        }
      }
    }
    return false;
  };

  const selectFirstItem = (itemArray, isChecking) => {
    for (let i = 0; i < itemArray.length; i++) {
      if (itemArray[i].vidList) {
        for (let j = 0; j < itemArray[i].vidList.length; j++) {
          if (isChecking) {
            return true;
          }
          if (!itemArray[i].open) {
            toggleOpen(itemArray[i].id);
            setCurrSubCollection(itemArray[i].name);
            clearAndSetSelected(itemArray[i].vidList[j]);
          } else {
            setCurrSubCollection(itemArray[i].name);
            clearAndSetSelected(itemArray[i].vidList[j]);
          }
          return;
        }
      } else {
        if (isChecking) {
          return true;
        }
        setCurrSubCollection(null);
        clearAndSetSelected(itemArray[i]);
        return;
      }
    }
    return false;
  };

  const selectNext = (isChecking) => {
    if (!items) {
      return;
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].vidList) {
        for (let j = 0; j < items[i].vidList.length; j++) {
          if (items[i].vidList[j].id == props.selected.id) {
            if (j + 1 < items[i].vidList.length) {
              if (!isChecking) {
                setCurrSubCollection(items[i].name);
                clearAndSetSelected(items[i].vidList[j + 1]);
              }
              return true;
            } else {
              if (items[i].open && !isChecking) {
                toggleOpen(items[i].id);
              }
              return selectFirstItem(items.slice(i + 1), isChecking);
            }
          }
        }
      } else {
        if (items[i].id == props.selected.id) {
          if (i + 1 < items.length) {
            if (!items[i + 1].vidList) {
              if (!isChecking) {
                setCurrSubCollection(null);
                clearAndSetSelected(items[i + 1]);
              }
              return true;
            } else {
              return selectFirstItem(items.slice(i + 1), isChecking);
            }
          } else {
            return false;
          }
        }
      }
    }
    return;
  };

  useEffect(() => {
    if (props.browseVids == "back") {
      selectPrev();
      props.setBrowseVids("none");
    }
    if (props.browseVids == "forward") {
      selectNext();
      props.setBrowseVids("none");
    }
  }, [props.browseVids]);

  useEffect(() => {
    if (props.collectionId == -1) {
      clearAndSetSelected({
        youtube_id: "afzmwAKUppU",
        name: "Welcome to youlearn!",
        id: -2,
        start: 0,
        ending: 10,
      });
      setItems([]);
      return;
    } else if (props.collectionId == -2) {
      clearAndSetSelected({
        youtube_id: "afzmwAKUppU",
        name: "Welcome to youlearn!",
        id: -3,
        start: 0,
        ending: 10,
      });
      setItems(null);
      return;
    }
    clearAndSetSelected({
      youtube_id: "afzmwAKUppU",
      name: "Welcome to youlearn!",
      id: -3,
      start: 0,
      ending: 10,
    });
    setItems(null);
    getToken(props.explore ? "explore" : false).then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${props.collectionId}/all/${props.category}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          data.forEach((element) => {
            var link = false;
            var linkType = "video";
            if (element.youtube_id) {
              if (
                element.youtube_id.startsWith("http") ||
                element.youtube_id.startsWith("www") ||
                element.youtube_id.startsWith("dropbox")
              ) {
                linkType = getFileType(element.youtube_id);
                link = true;
              }
            }
            element.linkType = linkType;
            element.link = link;
            element.open = false;
          });
          setItems(data.sort(dynamicSort("order_no")));
          setTimeout(() => {
            setCategoryChange(true);
          }, 700);
        })
        .catch((err) => {
          return [];
        });
    });
  }, [props.collectionId, subCopy]);

  const newVideoAdded = (newVideo) => {
    if (!newVideo) {
      return;
    }
    if (newVideo.collectionId == -1) {
      const subIndex = items.findIndex((elem) => {
        return elem.id == newVideo.subcollectionId;
      });
      if (subIndex == -1) {
        return;
      }
      var newItems = items;
      newItems[subIndex].open = true;
      newItems[subIndex].vidList = [...items[subIndex].vidList, newVideo];
      setItems([...newItems]);
      clearAndSetSelected(newVideo);
      const currSelected = document.getElementsByClassName("selected-item");
      for (var i = 0; i < currSelected.length; i++) {
        if (currSelected[i]) {
          currSelected[i].classList.remove("selected-item");
        }
      }
    } else if (newVideo.collectionId === props.collectionId) {
      if (items) {
        const newItems = items;
        if (newVideo.youtube_id == "subcollection") {
          setSubcopy(!subCopy);
        } else {
          for (let index = 0; index < newItems.length; index++) {
            newItems[index].order_no = newItems[index].order_no + 1;
          }

          setItems([newVideo, ...newItems]);
          clearAndSetSelected(newVideo);
          const currSelected = document.getElementsByClassName("selected-item");
          for (var i = 0; i < currSelected.length; i++) {
            if (currSelected[i]) {
              currSelected[i].classList.remove("selected-item");
            }
          }
        }
      }
    }
  };

  const handleMove = (item) => {
    const vidToMove = findItem(props.contextItemId);
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${props.contextItemId}`,
        {
          method: "PUT",
          body: JSON.stringify({ subcollectionId: item.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          var newArr = [...item.vidList];

          newArr.push({
            link: vidToMove.link,
            youtube_id: vidToMove.youtube_id,
            id: vidToMove.id,
            order_no: data.order_no,
            name: vidToMove.name,
            start: vidToMove.start,
            ending: vidToMove.start,
            description: vidToMove.description,
          });
          updateVidList(newArr, item.id, props.contextItemId);
          displayMessage(
            "success",
            '"' + vidToMove.name + '" ' + props.t("movedToSub")
          );
          props.setSubMenuState(0);
        })
        .catch((err) => {
          return [];
        });
    });
  };

  useEffect(() => {
    if (!items && props.share) {
      getToken().then((idToken) => {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/share/${user_id}/${item_type}/${item_id}/${share_record_id}/${millisec}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((result) => {
            setItems(result);
          });
      });
    }
  }, [props.share]);

  useEffect(() => {
    if (props.share) {
      findVid();
    }
  }, [items]);

  useEffect(() => {
    if (categoryChange) {
      findVid();
      setCategoryChange(false);
    }
  }, [categoryChange]);

  useEffect(() => {
    if (items) {
      var vidSubcol = findVidSubcol(props.contextItemId);

      setContextSubcols([
        ...items.filter((item) => {
          return item.hasOwnProperty("max_order_no") && item.id != vidSubcol;
        }),
      ]);
    }
  }, [props.contextItemId]);

  useEffect(() => {
    getToken().then((idToken) => {
      if (currentUser) {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/accounts/${currentUser.uid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              category: props.category,
              collectionId: props.collectionId,
              videoId: props.selected.id,
            }),
          }
        ).then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        });
      }
    });
    updateBrowseValue();
  }, [props.selected.id]);

  useEffect(() => {
    newVideoAdded(props.addedVid);
  }, [props.addedVid]);

  useEffect(() => {
    if (props.editObj) {
      if (props.editObj.name == -1) {
        setItems([...removeItem(props.editObj.id)]);
        return;
      }
      if (props.editObj.name === null) {
        deleteItem(props.editObj.id, props.editObj.type);
      } else {
        renameItem(props.editObj.id, props.editObj.name);
      }
    }
  }, [props.editObj.changed]);

  return (
    <Grid container>
      <Menu
        keepMounted
        open={props.contextState.mouseY !== null && props.subMenuState == 1}
        onClose={() => {
          props.setSubMenuState(0);
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          props.contextState.mouseY !== null &&
          props.contextState.mouseX !== null
            ? {
                top: props.contextState.mouseY + 143,
                left: props.contextState.mouseX + 140,
              }
            : undefined
        }
      >
        {items &&
          contextSubcols.map((item) => {
            return (
              <MenuItem onClick={() => handleMove(item)}>{item.name}</MenuItem>
            );
          })}
      </Menu>
      {/*<Grid onDragEnter={dragEnter} item xs={12} className="collection-header">
        <Typography variant="h2" component="h2">
          {props.name}
          <IconButton>
            <ShareIcon />
          </IconButton>
          <IconButton>
            <PublicIcon />
          </IconButton>
        </Typography>
  </Grid>*/}
      <ListItem
        className={classes.subPoser}
        disableTypography
        id="subDragPoser"
      >
        <ListItem className={classes.subText} id="subDragPoserText"></ListItem>
      </ListItem>
      <ListItem className={classes.poser} disableTypography id="dragPoser">
        <ListItemText id="dragText"></ListItemText>
      </ListItem>
      <Grid
        style={{ paddingLeft: "20px", height: "calc(100vh - 90px)" }}
        item
        sm={12}
        xs={12}
      >
        {!props.share && (
          <>
            <h3 className="menu-header">{props.t("categories")}</h3>
            <OrganizationMenu
              setCategory={props.setCategory}
              category={props.category}
              explore={props.explore}
            ></OrganizationMenu>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#323232",
                marginRight: "15px",
                marginBottom: "30px",
              }}
            ></div>
          </>
        )}
        <div className="collection-menu-container">
          {!props.share && (
            <h3 className="menu-header">{props.t("collectionMenu")}</h3>
          )}
          {!items && <LoadingCol></LoadingCol>}
          {items && !props.share && (
            <h4 className="collection-name">{props.collectionName}</h4>
          )}

          {items && props.collectionId !== -1 && (
            <CollectionSideMenu
              browseVids={props.browseVids}
              setBrowseVids={props.setBrowseVids}
              teacher={props.teacher}
              renameItem={renameItem}
              deleteItem={deleteItem}
              updateSelected={updateSelected}
              updateVidList={updateVidList}
              toggleOpen={toggleOpen}
              addVidToBack={addVidToBack}
              clearAndSetSelected={clearAndSetSelected}
              arrangeSubcollection={arrangeSubcollection}
              addVid={addVid}
              items={items}
              collectionId={props.collectionId}
            />
          )}
          {items && items.length == 0 && props.collectionId != -1 && (
            <AlertMessage
              type="info"
              message={props.t("emptyCollection")}
              cssClass="collection-drawer-alert"
            />
          )}
          {items && props.collectionId != -1 && (
            <div
              droppable="true"
              onDragEnter={dragEnter}
              style={{ position: "fixed", bottom: 24 }}
            >
              {props.teacher && !props.explore && (
                <SpeedDialAddButton
                  addSubcollection={addSubcollection}
                  collectionId={props.collectionId}
                  collectionName={props.collectionName}
                  newVidAdded={newVideoAdded}
                />
              )}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
});

export default CollectionContainer;
