import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import PlainVideoContainer from "../PlainVideoContainer";
import InputYoutubeUrl from "./InputYoutubeUrl";
import { useAuth } from "../auth/AuthContext";
import { dbFormat } from "../../helpers";
import InputYoutubeSearch from "./InputYoutubeSearch";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/youtube-yl-logo.svg";
import AlertMessage from "../AlertMessage";
import { isMobileOnly } from "react-device-detect";
import DialogLayout from "../layout/DialogLayout";
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg";
import { TextField as YlTextField, SelectField } from "../InputFields";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  titleLogo: {
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: 28,
    },
  },
  trimButton: {
    marginTop: 10,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
  backBtn: {
    marginTop: -15,
  },
  backBtnLabel: {
    color: "#D6D5FD !important",
    fontFamily: "Open Sans !important",
    fontWeight: "300 !important",
  },
  videoContainer: {
    maxWidth: 600,
    margin: "auto",
  },
  thumbnailContainer: {
    margin: "auto",
  },
}));

const AddVideoDialog = withTranslation()((props) => {
  const { currentUser, userValidated, getToken, displayMessage } = useAuth();
  const [error, setError] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState(null);
  const [videoName, setVideoName] = useState("");
  const [description, setDescription] = useState("");
  const [trimValues, setTrimValues] = useState([-1, -1]);
  const [trimmingVideo, setTrimmingVideo] = useState(false);
  const [focusValue, setFocusValue] = useState(0);
  const classes = useStyles();
  const [lastSearch, setLastSearch] = useState("");
  const [segments, setSegments] = useState(null);
  const [subcollections, setSubcollections] = useState([]);
  const [subcollection, setSubcollection] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (props.open === true) {
      if (userValidated) {
        if (props.setContextState) {
          props.setContextState({
            mouseX: null,
            mouseY: null,
          });
        }
        getCollections();
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (props.collectionId) {
      for (let index = 0; index < collections.length; index++) {
        if (collections[index].id == props.collectionId) {
          setSubcollections(collections[index].subList);
        }
      }
      setCollection(props.collectionId);
    } else {
      if (collections.length) {
        getCollection();
      }
    }
  }, [collections]);

  const handleClose = () => {
    //props.setAddVidOpen(false)
    setTrimValues([-1, -1]);
    setVideoId("");
    //setSubcollections([]);
    //setCollections([]);
    setSearchString("");
    setSearchResults([]);
    setVideoName("");
    setDescription("");
    setCollection(null);
    setSegments(null);
    setTrimmingVideo(false);
    if (props.setAddVidOpen) {
      props.setAddVidOpen(false);
    } else {
      props.handleClose();
    }
  };

  const handleBack = () => {
    setTrimValues([-1, -1]);
    setVideoId("");
    setVideoName("");
    setDescription("");
    setSegments(null);
    setTrimmingVideo(false);
  };

  const handleTrimVideo = () => {
    setSegments(null);
    setTrimmingVideo(true);
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (videoName == "") {
      setError(true);
      return;
    }

    if (collection != null && !subcollection) {
      var start = null;
      var ending = null;

      getToken().then((idToken) => {
        if (trimValues[1] != -1) {
          start = trimValues[0];
          ending = trimValues[1];
        } else {
          return;
        }
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${collection}/videos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              name: dbFormat(videoName),
              youtubeId: videoId,
              subcollectionId: 2,
              start: start,
              ending: ending,
              description: description,
              segments: segments,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            var collectionName = collections.filter((col) => {
              return col.id == collection;
            })[0].name;
            displayMessage(
              "success",
              '"' +
                videoName +
                '" ' +
                props.t("wasAddedCol") +
                ' " ' +
                collectionName +
                '"'
            );
            if (props.setAddedVid) {
              props.setAddedVid({
                name: videoName,
                youtube_id: videoId,
                collectionId: collection,
                start: start,
                ending: ending,
                id: data.id,
                order_no: 0,
                description: description,
              });
            }
            handleClose();
          })
          .catch((err) => {
            /* eslint-disable no-console */
            console.error(err);
          });
      });
    } else {
      getToken().then((idToken) => {
        if (trimValues[1] != -1) {
          start = trimValues[0];
          ending = trimValues[1];
        } else {
          return;
        }
        if (!subcollection) {
          return;
        }
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${subcollection}/videos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              name: dbFormat(videoName),
              youtubeId: videoId,
              subcollectionId: subcollection,
              start: start,
              ending: ending,
              description: description,
              segments: segments,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            displayMessage(
              "success",
              '"' +
                videoName +
                '" ' +
                props.t("wasAddedSub") +
                ' " ' +
                subcollections.filter((subcol) => {
                  return subcollection == subcol.id;
                })[0].name +
                '"'
            );
            if (props.setAddedVid) {
              props.setAddedVid({
                name: videoName,
                youtube_id: videoId,
                collectionId: -1,
                start: start,
                ending: ending,
                id: data.id,
                order_no: data.order_no,
                description: description,
                subcollectionId: subcollection,
              });
            }
            // TODO ADD DESCRIPTION
            handleClose();
          })
          .catch((err) => {
            displayMessage("error", "Error!");
            /* eslint-disable no-console */
          });
      });
    }
  };

  const handleCollectionChange = (event) => {
    for (let index = 0; index < collections.length; index++) {
      if (collections[index].id == event.target.value) {
        setSubcollections(collections[index].subList);

        setSubcollection(null);
      }
    }
    setCollection(event.target.value);
  };

  const handleSubcollectionChange = (event) => {
    setSubcollection(event.target.value);
  };

  const getCollection = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${props.subcollectionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setCollection(data.collection_id);
          for (let index = 0; index < collections.length; index++) {
            if (collections[index].id == data.collection_id) {
              setSubcollections(collections[index].subList);
              setSubcollection(props.subcollectionId);
            }
          }
        })
        .catch((err) => {
          displayMessage("error", "Error!");
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const getCollections = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${currentUser.uid}/collections`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          const formattedcollections = data.map((collection) => {
            return {
              name: collection.name,
              id: collection.id,
              subList: collection.subList,
            };
          });
          setCollections(formattedcollections);
        })
        .catch((err) => {
          displayMessage("error", "Error!");
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const nameUpdate = (name) => {
    setVideoName(name);
  };

  const handleVideoIdChange = (videoId) => {
    setFocusValue(1);
    setVideoId(videoId);
  };

  const renderTitle = () => {
    return (
      <>
        {props.t("addNewVideo")}
        <YouTubeLogo className={classes.titleLogo} />
      </>
    );
  };

  useEffect(() => {
    if (trimValues.length == 3) {
      setVideoName(trimValues[2]);
      setTrimValues([trimValues[0], trimValues[1]]);
    }
  }, [trimValues]);
  return (
    <>
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={videoId ? true : false}
        displaySecondaryBtn={videoId ? true : false}
        primaryBtnText={props.t("save")}
        secondaryBtnText={props.t("cancel")}
        title={renderTitle()}
        maxWidth="md"
        open={props.open}
      >
        {!videoId ? (
          <>
            {/*<h2 className={classes.title} gutterBottom>
              Ertu með slóðina?
        </h2> */}
            <p>{props.t("pasteVidUrl")}.</p>
            <AlertMessage
              type="info"
              message={
                props.t("urlFormat") +
                ": https://www.youtube.com/watch?v=7jTyvm27EsE"
              }
              cssClass=""
            />
            <div>
              <InputYoutubeUrl
                setSegments={setSegments}
                setVideoName={setVideoName}
                handleVideoIdChange={handleVideoIdChange}
                defaultVideoUrl=""
              />
            </div>
            <React.Fragment>
              <Typography gutterBottom style={{ paddingTop: "25px" }}>
                {props.t("orSearchVid")}
              </Typography>

              <div>
                <InputYoutubeSearch
                  lastSearch={lastSearch}
                  setLastSearch={setLastSearch}
                  setSegments={setSegments}
                  setVideoName={setVideoName}
                  handleVideoIdChange={handleVideoIdChange}
                  searchResults={searchResults}
                  setSearchResults={setSearchResults}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
              </div>
            </React.Fragment>
          </>
        ) : (
          <React.Fragment>
            <div>
              <Button
                startIcon={<BackArrow />}
                classes={{
                  root: classes.backBtn,
                  label: classes.backBtnLabel,
                }}
                onClick={(e) => handleBack(e)}
              >
                {props.t("back")}
              </Button>
            </div>
            {trimValues[1] == -2 && (
              <div className={classes.thumbnailContainer}>
                <img
                  alt="thumbnail"
                  style={{
                    width: "60%",
                    marginLeft: "20%",
                    marginRight: "20%",
                  }}
                  src={"https://i.ytimg.com/vi/" + videoId + "/sddefault.jpg"}
                ></img>
                <AlertMessage type="info" message={props.t("linkOnly")} />
              </div>
            )}
            {trimValues[1] != -2 && (
              <div className={classes.videoContainer}>
                <PlainVideoContainer
                  t={props.t}
                  setTrimValues={setTrimValues}
                  trimmingVideo={trimmingVideo}
                  addDialog={true}
                  videoId={videoId}
                ></PlainVideoContainer>
                {!trimmingVideo && (
                  <div className={classes.center}>
                    <Button
                      className={classes.trimButton + " outlined"}
                      color="primary"
                      onClick={(e) => handleTrimVideo(e)}
                    >
                      {props.t("trimVideo")}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={classes.padding}
            >
              <Grid item xs={12} className={classes.marginBottom}>
                <form onSubmit={handleSave} className={classes.input}>
                  <YlTextField
                    id="video-name"
                    label={props.t("name")}
                    fullWidth
                    autoFocus={!isMobileOnly}
                    focused={focusValue == 1}
                    onChange={nameUpdate}
                    value={videoName}
                    error={error}
                    errorMessage={props.t("chooseName")}
                  />
                </form>
              </Grid>
              {/* {!props.subcollectionId && ( */}
              <Grid item container spacing={3} className={classes.marginBottom}>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    labelId="collection-select-label"
                    id="collection-select"
                    label={props.t("collection")}
                    value={collection}
                    onChange={handleCollectionChange}
                  >
                    {collections.map((collection) => (
                      <MenuItem value={collection.id}>
                        {collection.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    labelId="sub-collection-select-label"
                    id="sub-collection-select"
                    label={props.t("subcollection")}
                    value={subcollection}
                    onChange={handleSubcollectionChange}
                  >
                    {subcollections.map((subcollection) => (
                      <MenuItem value={subcollection.id}>
                        {subcollection.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>

              {/* )} */}
              <Grid item xs={12} sm={12}>
                <form onSubmit={handleSave} className={classes.input}>
                  <YlTextField
                    fullWidth
                    focused={focusValue == 2}
                    id="video-description"
                    label={props.t("description")}
                    rowsMax={4}
                    rows={3}
                    multiline
                    value={description}
                    onChange={setDescription}
                  />
                </form>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </DialogLayout>
    </>
  );
});

export default AddVideoDialog;
