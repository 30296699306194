import React, { useState, useEffect } from "react";
import youtube from "../apis/youtube";
import { TextField as YlTextField } from "../InputFields";
import { ReactComponent as YouTubeOutlinedIcon } from "../../assets/icons/youtube-outlined-icon.svg";
import "../i18n";
import { withTranslation } from "react-i18next";

const InputPlayListUrl = withTranslation()((props) => {
  const [playListUrl, setPlayListUrl] = useState("");

  const fetchPlayListName = (id) => {
    youtube
      .get("/playlists", {
        params: {
          id: id,
        },
      })
      .then((result) => {
        if (result.data && result.data.items && result.data.items.length > 0) {
          props.setPlayListId(id);
          props.setSubcollectionName(result.data.items[0].snippet.title);
        }
      });
  };

  const newPlayList = (url) => {
    setPlayListUrl(url);
    if (url !== undefined && url !== "") {
      var playListSplit = url.split("list=");
      if (playListSplit.length > 1) {
        fetchPlayListName(playListSplit[1]);
      }
    }
  };

  return (
    <>
      <form onSubmit={(e) => props.handleSave(e)}>
        <YlTextField
          className="input-box"
          id="url"
          value={playListUrl}
          onChange={newPlayList}
          placeholder={props.t("ytPlaylist")}
          startAdornment={
            <YouTubeOutlinedIcon style={{ verticalAlign: "middle" }} />
          }
        />
      </form>
    </>
  );
});

export default InputPlayListUrl;
