import React, { useRef, useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import { timeToString } from "../helpers";
import ListItemText from "@material-ui/core/ListItemText";
import EditButton from "./EditButton";
import SegmentNote from "./SegmentNote";
import IconButton from "@material-ui/core/IconButton";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Icon } from "@iconify/react";
import messageOutlined from "@iconify/icons-ant-design/message-outlined";
import { ReactComponent as StarIcon } from "../assets/icons/star-icon.svg";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { isMobileOnly } from "react-device-detect";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import "./i18n";
import { withTranslation } from "react-i18next";

const SegmentItem = withStyles((theme) => ({
  root: {
    textAlign: "left",
    color: theme.palette.text,
    backgroundColor: "transparent",
    borderRadius: 7,
    width: "100%",
    padding: 5,
    marginBottom: "10px",
    border: "1px solid " + theme.palette.primary.extra,
    zIndex: "100",
    height: 45,
    [theme.breakpoints.up("lg")]: {
      padding: "2px 0",
      height: 35,
    },
  },
  selected: {
    background: theme.palette.primary.main + "!important",
    color: "#fff !important",
  },
}))(ListItem);

const SegmentName = withStyles((theme) => ({
  primary: {
    fontWeight: 600,
    [theme.breakpoints.up("lg")]: {
      fontWeight: 400,
      fontSize: 14,
    },
    marginLeft: "10px",
    maxWidth: "calc(100% - 84px)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}))(ListItemText);

const MasteredCheckbox = withStyles((theme) => ({
  root: {
    color: "transparent !important",
    "& .MuiSvgIcon-root": {
      backgroundColor: theme.palette.primary.transparentDark,
      borderRadius: 7,
    },
    "&$checked": {
      // fill: theme.palette.primary.linearPink + " !important",
    },
  },
  checked: {
    "& .MuiSvgIcon-root": {
      backgroundColor: "transparent",
    },
  },
}))((props) => <Checkbox color="default" {...props} />);

const TimeTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.videoBackground,
    color: theme.palette.primary.light,
    boxShadow: theme.shadows[1],
    padding: 8,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    // marginBottom: -4,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  arrow: {
    color: theme.palette.background.videoBackground,
  },
}))(Tooltip);

const Segment = withTranslation()((props) => {
  const [nameInput, setNameInput] = useState(props.name);
  const [edit, setEdit] = useState(props.edit);
  const [isMastered, setIsMastered] = useState(props.isMastered);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [noteText, setNoteText] = useState(props.note);
  var isSelected = props.selected === props.tempId;
  // var isPlaying = props.isPlaying === props.tempId;
  var noteIconColor = props.note === "" ? "rgba(255, 255, 255, 0.3)" : "#fff";
  //const buttonEl = useRef(null);

  const inputRef = useRef(null);

  const focusInput = () => {
    setEdit(true);
    inputRef.current.focus();
  };

  useEffect(() => {
    if (props.edit) {
      focusInput();
    }
  }, [props.edit]);

  const updateNameInput = () => {
    if (nameInput !== props.name) {
      setNameInput(props.name);
    }
  };

  useEffect(() => {
    setNoteText(props.note);
    setIsNoteOpen(false);
  }, [props.note]);

  useEffect(() => {
    if (props.isMastered !== isMastered) {
      setIsMastered(props.isMastered);
    }
  }, [props.isMastered]);

  const closeEdit = (e) => {
    if (!props.new) {
      setEdit(false);
      setNameInput(props.name);
    }
  };

  const handleMasteredChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.changeMastered(!isMastered, props.id ? props.id : props.tempId);
  };

  const openNote = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsNoteOpen(!isNoteOpen);
  };

  const handleSelectSegment = (e) => {
    props.handleChangeSegmentTime(false);
    props.update(props.start, props.ending, props.tempId);
  };

  const handleSaveSegment = (e) => {
    e.preventDefault();
    setEdit(false);
    if (props.new) {
      props.addNewSegment(props.start, props.ending, nameInput);
    } else {
      e.stopPropagation();
      props.rename(nameInput, props.id);
    }
  };

  useEffect(() => {
    if (props.note) {
      if (props.selected == props.tempId) {
        setIsNoteOpen(true);
      } else {
        setIsNoteOpen(false);
      }
    }
  }, [props.selected]);

  return (
    <div className={"segment-container" + (isMastered ? " mastered" : "")}>
      {/* {!isMobileOnly && (
        <span className="time-top" style={{ color: "#A4A3EE" }}>
          <AccessTimeRoundedIcon fontSize="small" />{" "}
          {timeToString(props.start - props.min) +
            " - " +
            timeToString(props.ending - props.min)}
        </span>
      )} */}
      <TimeTooltip
        arrow
        TransitionComponent={Zoom}
        placement="left"
        title={
          <>
            <AccessTimeRoundedIcon
              fontSize="small"
              style={{ marginRight: 6 }}
            />{" "}
            {timeToString(props.start - props.min)} -{" "}
            {timeToString(props.ending - props.min)}
          </>
        }
      >
        <SegmentItem
          className={
            (!props.student ? "editable " : "") +
            "segment" +
            (isMastered ? " mastered" : "")
          }
          id={"segment-" + props.tempId}
          selected={isSelected}
          style={{ zIndex: 100 - props.index }}
          button
          onClick={(e) => {
            if (e && !e.target) {
              handleSelectSegment();
            }
            if (e && e.target && e.target.classList) {
              if (
                (e.target.classList.contains("MuiListItemText-primary") ||
                  e.target.classList.contains("MuiListItemText-root") ||
                  e.target.classList.contains("MuiButton-text") ||
                  e.target.classList.contains("time") ||
                  e.target.classList.contains("segment")) &&
                !edit
              ) {
                handleSelectSegment();
              }
            }
          }}
        >
          <EditButton
            tempId={props.tempId}
            segmentAction={props.segmentAction}
            setSegmentAction={props.setSegmentAction}
            segmentToEdit={props.segmentToEdit}
            selected={isSelected}
            handleChangeSegmentTime={props.handleChangeSegmentTime}
            note={props.note}
            setAddedVid={props.setAddedVid}
            name={nameInput}
            start={props.start}
            ending={props.ending}
            youtube_id={props.youtube_id}
            teacher={props.teacher}
            updateNameInput={updateNameInput}
            focusInput={focusInput}
            id={props.id}
            del={props.del}
            rename={props.rename}
            handleSelectSegment={handleSelectSegment}
          ></EditButton>
          {!edit ? (
            <SegmentName primary={props.name} />
          ) : (
            <ClickAwayListener onClickAway={closeEdit}>
              <form
                className="segment-form"
                onSubmit={(e) => handleSaveSegment(e)}
              >
                <input
                  className="editSeg"
                  ref={inputRef}
                  placeholder={props.t("typeName")}
                  type="text"
                  onChange={(e) => setNameInput(e.target.value)}
                  value={nameInput}
                  required
                />
                <IconButton
                  className="edit-btn"
                  aria-label="accept"
                  size="small"
                  onClick={(e) => handleSaveSegment(e)}
                >
                  <DoneRoundedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  className="edit-btn"
                  aria-label="delete"
                  size="small"
                  onClick={(e) =>
                    props.new ? props.closeNewInput(e) : closeEdit(e)
                  }
                >
                  <CloseRoundedIcon fontSize="inherit" />
                </IconButton>
              </form>
            </ClickAwayListener>
          )}
          <span className={"right" + (edit ? " isEditing" : "")}>
            {!isMobileOnly && (
              <span
                className="time"
                style={{ color: isSelected ? "#fff" : "#A4A3EE" }}
              >
                <AccessTimeRoundedIcon fontSize="small" />{" "}
                {timeToString(props.start - props.min) +
                  " - " +
                  timeToString(props.ending - props.min)}
              </span>
            )}
            {!edit && (
              <React.Fragment>
                {(props.note !== "" || props.teacher) && (
                  <IconButton
                    className="note-btn"
                    aria-label="note"
                    onClick={(e) => openNote(e)}
                    disabled={props.new}
                  >
                    <Icon
                      icon={messageOutlined}
                      style={{ color: isNoteOpen ? "#A4A3EE" : noteIconColor }}
                    />
                  </IconButton>
                )}
                <FormControlLabel
                  className="mastered-checkbox"
                  disabled={props.new || !props.teacher}
                  control={
                    <MasteredCheckbox
                      checked={isMastered}
                      onClick={handleMasteredChange}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<StarIcon />}
                      name="checkedH"
                    />
                  }
                />
              </React.Fragment>
            )}
          </span>
        </SegmentItem>
      </TimeTooltip>
      <SegmentNote
        selected={isSelected}
        noteText={noteText}
        setNoteText={setNoteText}
        open={isNoteOpen}
        segmentId={props.id ? props.id : props.tempId}
        handleNoteChange={props.handleNoteChange}
      />
    </div>
  );
});

export default Segment;
