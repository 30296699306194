import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import youtube from "../apis/youtube";
import { parseSegments } from "../../helpers";
import { TextField as YlTextField } from "../InputFields";
import { ReactComponent as YouTubeOutlinedIcon } from "../../assets/icons/youtube-outlined-icon.svg";
import { isMobile } from "react-device-detect";
import { EcoOutlined } from "@material-ui/icons";

export default function InputYoutubeUrl(props) {
  const [videoUrl, setVideoUrl] = useState("");
  const fetchInfo = (id) => {
    youtube
      .get("/videos", {
        params: {
          id: id,
        },
      })
      .then((result) => {
        if (result.data && result.data.items && result.data.items.length > 0) {
          props.setVideoName(result.data.items[0].snippet.title);
          props.setSegments(
            parseSegments(result.data.items[0].snippet.description)
          );
        }
      });
  };

  const newVideo = (url) => {
    setVideoUrl(url);
    // const videoId = url.split("=")[1];

    if (url !== undefined && url !== "") {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length === 11) {
        const videoId = match[2];
        fetchInfo(videoId);

        props.handleVideoIdChange(videoId);
      } else if (url.includes("vimeo")) {
        if (url.includes("player.vimeo.com/video/")) {
          var temp = url.split("player.vimeo.com/video/")[1].split("&")[0];
          props.handleVideoIdChange("eo.com/" + temp);
        } else {
          props.handleVideoIdChange(url.split("/vim")[1]);
        }
      }
    }
  };

  useEffect(() => {
    setVideoUrl(props.defaultVideoUrl);
    newVideo(props.defaultVideoUrl);
  }, [props.defaultVideoUrl]);

  return (
    <>
      <YlTextField
        autoFocus={!isMobile}
        id="url"
        value={videoUrl}
        onChange={newVideo}
        placeholder="YouTube Video url"
        startAdornment={
          <YouTubeOutlinedIcon style={{ verticalAlign: "middle" }} />
        }
      />
    </>
  );
}

InputYoutubeUrl.propTypes = {
  handleVideoIdChange: PropTypes.func.isRequired,
};
