import React, { useState, useEffect } from "react";
import DialogLayout from "../layout/DialogLayout";
import MenuItem from "@material-ui/core/MenuItem";
import { dbFormat } from "../../helpers";
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useAuth } from "../auth/AuthContext";
import PlainVideoContainer from "../PlainVideoContainer";
import { makeStyles } from "@material-ui/core/styles";
import { TextField as YlTextField, SelectField } from "../InputFields";
import DropboxChooser from "react-dropbox-chooser";
import GooglePicker from "react-google-picker";
import { IconButton } from "@material-ui/core";
import { FolderOutlined } from "@material-ui/icons";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  titleLogo: {
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: 28,
    },
  },
  trimButton: {
    marginTop: 10,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
  backBtn: {
    marginTop: -15,
  },
  backBtnLabel: {
    color: "#D6D5FD !important",
    fontFamily: "Open Sans !important",
    fontWeight: "300 !important",
  },
  videoContainer: {
    maxWidth: 600,
    margin: "auto",
  },
  thumbnailContainer: {
    margin: "auto",
  },
}));

const AddDownloadLink = withTranslation()((props) => {
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(false);
  const [collection, setCollection] = useState(null);
  const [downloadItemName, setDownloadItemName] = useState("");
  const [downloadItemDescription, setDownloadItemDescription] = useState("");
  const [downloadItemUrl, setDownloadItemUrl] = useState("");
  const { currentUser, userValidated, getToken, displayMessage } = useAuth();
  const [subcollections, setSubcollections] = useState([]);
  const [subcollection, setSubcollection] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [trimValues, setTrimValues] = useState([0, 0]);
  const [trimmingVideo, setTrimmingVideo] = useState(false);
  const [splitValue, setSplitValue] = useState("drop");
  const [folder, setFolder] = useState(null);
  const [folderName, setFolderName] = useState(null);

  console.log(downloadItemUrl);
  const handleTrimVideo = () => {
    setTrimmingVideo(true);
  };

  const classes = useStyles();
  useEffect(() => {
    if (props.open === true) {
      if (userValidated) {
        if (props.setContextState) {
          props.setContextState({
            mouseX: null,
            mouseY: null,
          });
        }
        getCollections(currentUser.uid);
        getFolder();
      }
    }
  }, [props.open]);

  const handleSubcollectionChange = (event) => {
    setSubcollection(event.target.value);
  };

  useEffect(() => {
    if (props.collectionId) {
      for (let index = 0; index < collections.length; index++) {
        if (collections[index].id == props.collectionId) {
          setSubcollections(collections[index].subList);
        }
      }
      setCollection(props.collectionId);
    } else {
      if (collections.length) {
        getCollection();
      }
    }
  }, [collections]);

  const saveFolder = (folderId, folderName) => {
    const folderString = folderId + "" + folderName;
    setFolder(folderString);
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/accounts/${currentUser.uid}/folder`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            folderString: folderString,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {});
    });
  };

  const checkUrl = (value) => {
    setDownloadItemUrl(value);
    if (value.includes("dropbox")) {
      var link = value;
      var splitLink = link.split("/");
      if (splitLink.length >= 4) {
        if (splitLink[3] == "sh") {
          return;
        }
      }
      if (splitLink.length >= 6) {
        var fileName = splitLink[splitLink.length - 1].slice(0, -5);
        var splitName = fileName.split("%20");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += " " + splitName[index];
        }
        splitName = fileName.split("%C3%89");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "É" + splitName[index];
        }
        splitName = fileName.split("%C3%A9");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "é" + splitName[index];
        }
        splitName = fileName.split("%C3%BD");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ý" + splitName[index];
        }
        splitName = fileName.split("%C3%B0");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ð" + splitName[index];
        }
        splitName = fileName.split("%C3%BE");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "þ" + splitName[index];
        }
        splitName = fileName.split("%C3%B6");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ö" + splitName[index];
        } //%C3%B3
        splitName = fileName.split("%C3%B3");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ó" + splitName[index];
        } //%C3%8D
        splitName = fileName.split("%C3%8D");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "í" + splitName[index];
        }
        splitName = fileName.split("%C3%90");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ð" + splitName[index];
        }
        splitName = fileName.split("%C3%86");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Æ" + splitName[index];
        }
        splitName = fileName.split("%C3%9E");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Þ" + splitName[index];
        }
        splitName = fileName.split("%C3%93");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ó" + splitName[index];
        }
        splitName = fileName.split("%C3%96");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ö" + splitName[index];
        }
        splitName = fileName.split("%C3%9D");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ý" + splitName[index];
        }
        splitName = fileName.split("%C3%A6");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "æ" + splitName[index];
        }
        splitName = fileName.split("%C3%AD");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "í" + splitName[index];
        }
        splitName = fileName.split("%C3%A1");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "á" + splitName[index];
        }
        splitName = fileName.split("%C3%81");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Á" + splitName[index];
        }
        splitName = fileName.split("%28");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "(" + splitName[index];
        }
        splitName = fileName.split("%29");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += ")" + splitName[index];
        }
        splitName = fileName.split("%C3%BA");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ú" + splitName[index];
        }
        var fileTypes = [
          ".MOV",
          ".MP3",
          ".MP4",
          ".WAV",
          ".WEBM",
          ".OGG",
          ".FLAC",
          ".M4A",
          ".m4a",
          ".mp4",
          ".mov",
          ".mp3",
          ".ogg",
          ".wav",
          ".flac",
          ".webm",
        ];
        const name = fileName.split("?")[0];
        setDownloadItemName(name);
        if (
          fileTypes.some((type) => {
            return name.includes(type);
          })
        ) {
          setSplitValue(".com");
          setIsVideo(true);
        }
      }
    }
  };

  const getFolder = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/accounts/${currentUser.uid}/folder`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          if (data.folder == null) {
            return;
          }
          setFolderName(data.folder.slice(33));
          setFolder(data.folder.slice(0, 33));
        })
        .catch((err) => {
          displayMessage("error", "Upp kom villa");
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const handleBack = () => {
    setTrimValues([0, 0]);
    setDownloadItemUrl("");
    setDownloadItemName("");
    setDownloadItemDescription("");
    setTrimmingVideo(false);
    setIsVideo(false);
  };

  const handleClose = () => {
    setTrimValues([0, 0]);
    setIsVideo(false);
    setTrimmingVideo(false);
    setDownloadItemName("");
    setDownloadItemUrl("");
    setDownloadItemDescription("");
    props.setAddLinkOpen(false);
  };

  const getCollection = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${props.subcollectionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setCollection(data.collection_id);
          for (let index = 0; index < collections.length; index++) {
            if (collections[index].id == data.collection_id) {
              setSubcollections(collections[index].subList);
              setSubcollection(props.subcollectionId);
            }
          }
        })
        .catch((err) => {
          displayMessage("error", "Upp kom villa");
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const triggerError = () => {
    setIsVideo(false);
    displayMessage(
      "info",
      "Hljóð/myndbands skrá er á óstuddu sniði eða of stór. 100MB hámark er á innfelldum skrám frá Google Drive. Skráin verður vistuð sem hlekkur"
    );
  };

  const handleCollectionChange = (event) => {
    for (let index = 0; index < collections.length; index++) {
      if (collections[index].id == event.target.value) {
        setSubcollections(collections[index].subList);

        setSubcollection(null);
      } //https://www.dropbox.com/s/x1k5o9mvvd87nhn/sample4.aif?dl=0
    }
    setCollection(event.target.value);
  };

  const renderTitle = () => {
    return <React.Fragment> {props.t("addDropbox")} </React.Fragment>;
  };

  const getCollections = (id) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${id}/collections`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          const formattedcollections = data.map((collection) => {
            return {
              name: collection.name,
              id: collection.id,
              subList: collection.subList,
            };
          });
          setCollections(formattedcollections);
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const handleSave = (e) => {
    if (downloadItemName == "") {
      setError(true);
      return;
    }
    if (e) {
      e.preventDefault();
    }
    if (collection != null && !subcollection) {
      getToken().then((idToken) => {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${collection}/videos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              name: dbFormat(downloadItemName),
              youtubeId: isVideo
                ? "box.com" + downloadItemUrl.split(splitValue)[1]
                : downloadItemUrl,
              subcollectionId: 2,
              start: trimValues[0],
              ending: trimValues[1],
              description: dbFormat(downloadItemDescription),
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            var newVideo = {
              name: downloadItemName,
              youtube_id: isVideo
                ? "box.com" + downloadItemUrl.split(splitValue)[1]
                : downloadItemUrl,
              subcollectionId: -1,
              start: trimValues[0],
              ending: trimValues[1],
              description: downloadItemDescription,
              link: !isVideo,
              id: data.id,
              order_no: data.order_no,
              collectionId: collection,
            };
            props.setAddedVid(newVideo);
          })
          .catch((error) => {});
      });
    } else {
      getToken().then((idToken) => {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${subcollection}/videos`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              name: dbFormat(downloadItemName),
              youtubeId: isVideo
                ? "box.com" + downloadItemUrl.split(splitValue)[1]
                : downloadItemUrl,
              subcollectionId: subcollection,
              start: trimValues[0],
              ending: trimValues[1],
              description: downloadItemDescription,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            displayMessage(
              "success",
              '"' +
                downloadItemName +
                '" ' +
                props.t("wasAddedSub") +
                ' "' +
                subcollections.filter((subcol) => {
                  return subcollection == subcol.id;
                })[0].name +
                '"'
            );
            if (props.setAddedVid) {
              props.setAddedVid({
                name: downloadItemName,
                youtube_id: isVideo
                  ? "box.com" + downloadItemUrl.split(splitValue)[1]
                  : downloadItemUrl,
                collectionId: -1,
                start: trimValues[0],
                ending: trimValues[1],
                id: data.id,
                order_no: data.order_no,
                link: !isVideo,
                description: downloadItemDescription,
                subcollectionId: subcollection,
              });
            }
            // TODO ADD DESCRIPTION
            handleClose();
          })
          .catch((err) => {
            displayMessage("error", "Upp kom villa");
            /* eslint-disable no-console */
          });
      });
    }
    handleClose();
  };

  return (
    <DialogLayout
      handleClose={handleClose}
      handleSave={handleSave}
      displayPrimaryBtn={
        (isVideo && trimValues[1] != 0) || (!isVideo && downloadItemUrl)
      }
      displaySecondaryBtn={true}
      primaryBtnText={props.t("save")}
      secondaryBtnText={props.t("cancel")}
      title={renderTitle()}
      maxWidth="md"
      open={props.open}
    >
      {downloadItemUrl == "" && (
        <>
          {" "}
          {false && (
            <span class="drive-wrapper">
              {true && (
                <>
                  <GooglePicker
                    clientId={process.env.REACT_APP_PICKER_CLIENT_ID}
                    scope={["https://www.googleapis.com/auth/drive.file"]}
                    onAuthFailed={(data) =>
                      console.log("on auth failed:", data)
                    }
                    navHidden={true}
                    authImmediate={false}
                    createPicker={(google, oauthToken) => {
                      if (!folder) {
                        const googleViewId = google.picker.ViewId.FOLDERS;
                        const folderView = new google.picker.DocsView(
                          googleViewId
                        )
                          .setIncludeFolders(true)
                          .setSelectFolderEnabled(true);
                        const picker = new window.google.picker.PickerBuilder()
                          .addView(folderView)
                          .setSize(1051)
                          .setTitle(
                            'Veldu upphleðsumöppu stillta á "allir með tengil geta skoðað".'
                          )
                          .setOAuthToken(oauthToken)
                          .setDeveloperKey(
                            process.env.REACT_APP_PICKER_DEVELOPER_KEY
                          )
                          .setCallback((data) => {
                            if (data.action == "picked") {
                              saveFolder(data.docs[0].id, data.docs[0].name);
                              setFolder(data.docs[0].id);
                              setFolderName(data.docs[0].name);
                              displayMessage(
                                "info",
                                '"' +
                                  data.docs[0].name +
                                  '" var valin sem upphleðslumappa, smelltu aftur á "Hlaða upp eða bæta við frá Drive" til að halda áfram'
                              );
                            }
                          });
                        displayMessage(
                          "info",
                          'Fyrst þarf að velja möppu fyrir upphleðslu Ath. að mappan þarf að vera stillt á "allir með tengil geta skoðað" ',
                          () => {
                            picker.build().setVisible(true);
                          }
                        );
                      } else {
                        const googleViewId = google.picker.ViewId.DOCS;
                        const docsView = new google.picker.DocsView(
                          googleViewId
                        )
                          .setIncludeFolders(true)
                          .setSelectFolderEnabled(true);
                        const picker = new window.google.picker.PickerBuilder()
                          .addView(docsView)
                          .setSize(1051)
                          .setTitle(
                            'Smelltu á "upload" til að hlaða upp í "' +
                              folderName +
                              '"'
                          )
                          .addView(
                            new google.picker.DocsUploadView().setParent(folder)
                          )
                          .setOAuthToken(oauthToken)
                          .setDeveloperKey(
                            process.env.REACT_APP_PICKER_DEVELOPER_KEY
                          )
                          .setCallback((data) => {
                            var fileTypes = [
                              ".MOV",
                              ".MP3",
                              ".MP4",
                              ".WAV",
                              ".WEBM",
                              ".OGG",
                              ".FLAC",
                              ".M4A",
                              ".m4a",
                              ".mp4",
                              ".mov",
                              ".mp3",
                              ".ogg",
                              ".wav",
                              ".flac",
                              ".webm",
                            ];
                            if (data.action == "picked") {
                              if (data.docs[0].embedUrl) {
                                setDownloadItemUrl(data.docs[0].embedUrl);
                                setDownloadItemName(data.docs[0].name);
                                if (
                                  fileTypes.some((type) => {
                                    return data.docs[0].name.includes(type);
                                  })
                                ) {
                                  if (data.docs[0].duration) {
                                    setTrimValues([0, data.docs[0].duration]);
                                  }
                                  setSplitValue("/file/d/");
                                  setIsVideo(true);
                                }
                              } else if (data.docs[0].url) {
                                setDownloadItemUrl(data.docs[0].url);
                                setDownloadItemName(data.docs[0].name);
                                if (
                                  fileTypes.some((type) => {
                                    return data.docs[0].name.includes(type);
                                  })
                                ) {
                                  if (data.docs[0].duration) {
                                    setTrimValues([0, data.docs[0].duration]);
                                  }
                                  setSplitValue("/file/d/");
                                  setIsVideo(true);
                                }
                              }
                            }
                          });

                        picker.build().setVisible(true);
                      }
                    }}
                  >
                    <span
                      class=" dropbox-btn dropbox-default"
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      <span class="btn-drive-status"></span> Hlaða upp eða bæta
                      við frá Drive
                    </span>
                  </GooglePicker>
                </>
              )}
            </span>
          )}
          {folderName && false && (
            <span class="google-folder-wrapper">
              <GooglePicker
                clientId={process.env.REACT_APP_PICKER_CLIENT_ID}
                scope={["https://www.googleapis.com/auth/drive.file"]}
                onAuthFailed={(data) => console.log("on auth failed:", data)}
                navHidden={true}
                authImmediate={false}
                createPicker={(google, oauthToken) => {
                  const googleViewId = google.picker.ViewId.FOLDERS;
                  const folderView = new google.picker.DocsView(googleViewId)
                    .setIncludeFolders(true)
                    .setSelectFolderEnabled(true);
                  const picker = new window.google.picker.PickerBuilder()
                    .addView(folderView)
                    .setSize(1051)
                    .setTitle(
                      'Veldu nýja upphleðslumöppu í stað "' + folderName + '"'
                    )
                    .setOAuthToken(oauthToken)
                    .setDeveloperKey(process.env.REACT_APP_PICKER_DEVELOPER_KEY)
                    .setCallback((data) => {
                      if (data.action == "picked") {
                        saveFolder(data.docs[0].id, data.docs[0].name);
                        setFolder(data.docs[0].id);
                      }
                    });

                  picker.build().setVisible(true);
                }}
              >
                {" "}
                <IconButton>
                  <FolderOutlined></FolderOutlined>{" "}
                </IconButton>
              </GooglePicker>
            </span>
          )}
          <DropboxChooser
            appKey={process.env.REACT_APP_DROPBOX_APP_KEY}
            success={(files) => {
              setDownloadItemUrl(files[0].link);
              setDownloadItemName(files[0].name);
              var fileTypes = [
                ".MOV",
                ".MP3",
                ".MP4",
                ".WAV",
                ".WEBM",
                ".OGG",
                ".FLAC",
                ".M4A",
                ".m4a",
                ".mp4",
                ".mov",
                ".mp3",
                ".ogg",
                ".wav",
                ".flac",
                ".webm",
              ];

              if (
                fileTypes.some((type) => {
                  return files[0].link.includes(type);
                })
              ) {
                setSplitValue(".com");
                setIsVideo(true);
              }
            }}
            cancel={() => {}}
            multiselect={false}
          >
            <a
              href="#"
              class=" dropbox-btn dropbox-default"
              style={{ marginLeft: "calc(50% - 103px)" }}
            >
              <span class="btn-status"></span> {props.t("uploadAddDropbox")}
            </a>
          </DropboxChooser>
        </>
      )}
      {downloadItemName != "" && (
        <div>
          <Button
            startIcon={<BackArrow />}
            classes={{
              root: classes.backBtn,
              label: classes.backBtnLabel,
            }}
            onClick={(e) => handleBack(e)}
          >
            {props.t("back")}
          </Button>
        </div>
      )}
      {isVideo && (
        <div className={classes.videoContainer}>
          <PlainVideoContainer
            t={props.t}
            userEmail={currentUser.email}
            triggerError={triggerError}
            setTrimValues={setTrimValues}
            trimmingVideo={trimmingVideo}
            addDialog={true}
            videoId={"box.com" + downloadItemUrl.split(splitValue)[1]}
          ></PlainVideoContainer>
        </div>
      )}
      {!isVideo && (
        <form onSubmit={(e) => handleSave(e)}>
          <YlTextField
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="url"
            value={downloadItemUrl}
            onChange={checkUrl}
            fullWidth
          />
        </form>
      )}
      <br></br>
      {downloadItemUrl != "" && (
        <>
          <form onSubmit={(e) => handleSave(e)}>
            <YlTextField
              margin="dense"
              id="name"
              label="Name"
              type="name"
              helperText={error ? props.t("chooseName") : ""}
              value={downloadItemName}
              onChange={setDownloadItemName}
              fullWidth
            />
          </form>
          <br></br>
          <form onSubmit={(e) => handleSave(e)}>
            <YlTextField
              margin="dense"
              id="description"
              label={props.t("description")}
              type="text"
              value={downloadItemDescription}
              onChange={setDownloadItemDescription}
              fullWidth
            />
          </form>
          <br></br>
        </>
      )}
      <Grid item container spacing={3} className={classes.marginBottom}>
        <Grid item xs={12} sm={6}>
          <SelectField
            labelId="collection-select-label"
            id="collection-select"
            label={props.t("collection")}
            value={collection}
            onChange={handleCollectionChange}
          >
            {collections.map((collection) => (
              <MenuItem value={collection.id}>{collection.name}</MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            labelId="sub-collection-select-label"
            id="sub-collection-select"
            label={props.t("subcollection")}
            value={subcollection}
            onChange={handleSubcollectionChange}
          >
            {subcollections.map((subcollection) => (
              <MenuItem value={subcollection.id}>{subcollection.name}</MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>
    </DialogLayout>
  );
});

export default AddDownloadLink;
