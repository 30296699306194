import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import NativeSelect from "@material-ui/core/NativeSelect";
import AddIcon from "@material-ui/icons/Add";
import removeIcon from "../../images/AddNewCategory.png";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { Icon, InlineIcon } from "@iconify/react";
import checkCircleFill from "@iconify/icons-ph/check-circle-fill";
import { withTranslation } from "react-i18next";
import "../i18n";
// or

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

const DepartmentTable = withTranslation()((props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.accessedDepartments != 0
        ? props.staffMemberName + " " + props.t("hasAccessTo")
        : props.staffMemberName + " " + props.t("hasNoDepartmentAccess")}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {/*<TableHead>
                <TableRow>
                    <TableCell>Deildir sem {props.staffMemberName} er aðili að</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>*/}
          <TableBody>
            {props.accessedDepartments.map((department) => (
              <TableRow key={department.id}>
                <TableCell component="th" scope="row">
                  {department.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() =>
                      props.handleAccessDeactivation(department.membership_id)
                    }
                    color="white"
                    size="medium"
                  >
                    <img src={removeIcon}></img>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {props.adding && (
              <TableRow
                style={{
                  border: "none",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                key={-1}
              >
                <TableCell
                  scope="row"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "12px",
                  }}
                >
                  <InputLabel htmlFor="age-native-helper"></InputLabel>
                  <NativeSelect
                    native
                    value={props.dept}
                    onChange={props.handleDeptChange}
                    inputProps={{
                      name: "age",
                      id: "age-native-helper",
                    }}
                  >
                    {props.departments
                      .filter((department) => {
                        for (
                          let index = 0;
                          index < props.accessedDepartments.length;
                          index++
                        ) {
                          if (
                            department.name ==
                            props.accessedDepartments[index].name
                          ) {
                            return false;
                          }
                        }
                        return true;
                      })
                      .map((department) => {
                        return (
                          <option value={department.id}>
                            {department.name}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  align="right"
                >
                  <IconButton
                    onClick={props.handleAccessActivation}
                    color="white"
                    size="medium"
                    //className="add-collection-btn"
                  >
                    <Icon
                      icon={checkCircleFill}
                      style={{
                        color: "#605df9",
                        fontSize: "30px",
                        paddingBottom: "0px",
                      }}
                    />
                  </IconButton>
                  {props.t("add")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!(props.accessedDepartments.length == props.departments.length) &&
        !props.adding && (
          <React.Fragment>
            <Tooltip title={props.t("addDepartmentAccess")} arrow>
              <IconButton
                onClick={() => props.handleStartAdding()}
                color="white"
                size="medium"
                //className="add-collection-btn"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            {props.t("addDepartmentAccess")}
          </React.Fragment>
        )}
    </React.Fragment>
  );
});

export default DepartmentTable;
