import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import SearchResult from "./SearchResult";
import PropTypes from "prop-types";
import axios from "axios-jsonp-pro";
import { parseSegments, ptFormatToSeconds } from "../../helpers";
import AutocompleteSearch from "./AutocompleteSearch";
import youtube from "../apis/youtube";

export default function InputYoutubeSearch(props) {
  const [loadingSearchResult, setLoadingSearchResult] = useState(false);

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }

    selectSearch(props.searchString);
  };

  const selectSearch = (string) => {
    if (string == props.lastSearch) {
      return;
    }

    setLoadingSearchResult(true);
    axios
      .post(process.env.REACT_APP_RESOURCE_SERVER + "/api/search/", {
        search: string,
      })
      .then((response) => {
        if (props.landing) {
          youtube
            .get("/videos", {
              params: {
                id: response.data[0].id.videoId,
                part: "snippet,contentDetails,statistics",
              },
            })
            .then((result) => {
              if (
                result.data &&
                result.data.items &&
                result.data.items.length > 0
              ) {
                var segments = parseSegments(
                  result.data.items[0].snippet.description
                );
                if (segments) {
                  for (let index = 0; index < segments.length; index++) {
                    segments[index].tempId = index;
                    segments[index].note = "";
                    segments[index].mastered = false;
                    if (index + 1 >= segments.length) {
                      segments[index].ending = ptFormatToSeconds(
                        result.data.items[0].contentDetails.duration
                      );
                      break;
                    } else {
                      segments[index].ending = segments[index + 1].start;
                    }
                  }
                } else {
                  segments = [];
                }

                props.setLicks(segments);
                props.handleVideoChange(response.data[0].id.videoId, {
                  title: result.data.items[0].snippet.title,
                  //description: result.data.items[0].snippet.description,
                });
              }
              //setSearchResults(result.data.items)
            });
        } else {
          props.setSearchResults(response.data);
          setLoadingSearchResult(false);
          props.setLastSearch(string);
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="flex-end"
        className="youtube-input"
      >
        <Grid item xs={12}>
          <form onSubmit={handleSearch}>
            <AutocompleteSearch
              selectSearch={selectSearch}
              searchString={props.searchString}
              setSearchString={props.setSearchString}
            />
          </form>
        </Grid>
        {!props.landing && (
          <Grid item xs={12}>
            <SearchResult
              setSegments={props.setSegments}
              setVideoName={props.setVideoName}
              handleVideoIdChange={props.handleVideoIdChange}
              searchResults={props.searchResults}
              setSearchResults={props.setSearchResults}
              loading={loadingSearchResult}
            ></SearchResult>
          </Grid>
        )}
      </Grid>
    </>
  );
}
