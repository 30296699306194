import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DialogLayout from "../layout/DialogLayout";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg";
import { useAuth } from "../auth/AuthContext";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  titleLogo: {
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: 28,
    },
  },
  trimButton: {
    marginTop: 10,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
  primaryBtn: {
    background: theme.palette.primary.main,
  },
  backBtn: {
    marginTop: -15,
  },
  backBtnLabel: {
    color: "#D6D5FD !important",
    fontFamily: "Open Sans !important",
    fontWeight: "300 !important",
  },
}));

const MoveItemDialog = withTranslation()((props) => {
  const { currentUser, userValidated, getToken, accountInfo, displayMessage } =
    useAuth();
  const [open, setOpen] = React.useState(false);
  const [collection, setCollection] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(false);
  const [collections, setCollections] = React.useState([]);
  const [subcollections, setSubcollections] = React.useState(null);
  const [subcollection, setSubcollection] = React.useState(null);
  const [subcollectionName, setSubcollectionName] = React.useState(null);

  const classes = useStyles();

  const handleCollectionChange = (event) => {
    setTimeout(() => {
      if (document.getElementById("confirm-col")) {
        document.getElementById("confirm-col").focus();
      }
    }, 100);
    setCollectionName(event.target.value.name);
    setCollection(event.target.value.id);

    getSubcols(event.target.value.id);
  };

  const getSubcols = (colId) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${colId}/subcollections`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setSubcollections(data);
        })
        .catch(() => {
          return;
        });
    });
  };

  const handleSubcollectionChange = (event) => {
    setSubcollectionName(event.target.value.name);
    setSubcollection(event.target.value.id);
  };

  useEffect(() => {
    if (userValidated) {
      getData(currentUser.uid);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.collectionId) {
      setCollection(props.collectionId);
    }
  }, [collections]);

  const getData = (id) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${id}/collections`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          const formattedcollections = data.map((collection) => {
            return {
              category: collection.category,
              name: collection.name,
              id: collection.id,
              org_name: collection.org_name,
            };
          });
          setCollections(formattedcollections);
        })
        .catch((err) => {
          /* eslint-disable no-console */
        });
      if (props.item.type === "subcollection") {
        return;
      }

      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${props.item.id}/personal`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setVideo(data);
        })
        .catch((err) => {});
    });
  };

  const handleClickOpen = () => {
    if (userValidated) {
      getData(currentUser.uid);
    }
    props.setContextState(initialState);
    setOpen(true);
    setTimeout(() => {
      if (document.getElementById("confirm-col")) {
        document.getElementById("confirm-col").focus();
      }
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const handleSave = () => {
    if (collection == null) {
      setError(true);
      return;
    }
    if (props.item.type == "subcollection" && collection != null) {
      getToken().then((idToken) => {
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${props.item.id}`,
          {
            method: "PUT",
            body: JSON.stringify({ collectionId: collection }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            displayMessage(
              "success",
              '"' +
                props.item.name +
                '" ' +
                props.t("wasMovedTo") +
                ' "' +
                collectionName +
                '"'
            );
            props.handleRemove();
            handleClose();
            return response.json();
          })
          .catch((err) => {
            displayMessage("error", "Villa kom upp");
            /* eslint-disable no-console */
          });
      });
    } else if (video != null && collection != null) {
      getToken().then((idToken) => {
        if (subcollection) {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${video.id}`,
            {
              method: "PUT",
              body: JSON.stringify({ subcollectionId: subcollection }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                return Promise.reject();
              }
              displayMessage(
                "success",
                '"' +
                  props.item.name +
                  '" ' +
                  props.t("wasMovedTo") +
                  ' "' +
                  subcollectionName +
                  '"'
              );
              props.handleRemove();
              handleClose();
              return response.json();
            })
            .catch((err) => {
              displayMessage("error", "Villa kom upp");
              /* eslint-disable no-console */
            });
        } else {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${video.id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify({
                orderNo: 0,
                collectionId: collection,
              }),
            }
          )
            .then((response) => {
              if (!response.ok) {
                return Promise.reject();
              }
              displayMessage(
                "success",
                '"' +
                  props.item.name +
                  '" ' +
                  props.t("wasMovedTo") +
                  ' "' +
                  collectionName +
                  '"'
              );
              props.handleRemove();
              handleClose();
              return response.json();
            })
            .catch(() => {
              displayMessage("error", "Villa kom upp");
              /* eslint-disable no-console */
            });
        }
      });
    }
  };

  useEffect(() => {
    if (props.item.id === "") {
      props.setContextState(initialState);
    }
  }, [props.item]);

  const renderTitle = () => {
    return <React.Fragment>{props.t("move")}</React.Fragment>;
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>
        {props.t("moveToCollection")}
      </MenuItem>
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={false}
        displaySecondaryBtn={true}
        secondaryBtnText={props.t("cancel")}
        title={renderTitle()}
        maxWidth="md"
        open={open}
      >
        {!collectionName && (
          <React.Fragment>
            <p>
              {props.t("chooseCollectionFor")}
              {' "'}
              {props.item.name}
              {'"'}
            </p>
            <FormControl
              className={(classes.formControl, classes.margin, classes.input)}
            >
              <InputLabel id="collection-select-label">
                {props.t("studentsCollections")}
              </InputLabel>
              <Select
                helperText={error ? props.t("selectCollection") : ""}
                labelId="collection-select-label"
                id="collection-select"
                value={collection}
                onChange={handleCollectionChange}
              >
                {collections &&
                  collections
                    .filter((elem) => {
                      return (
                        elem.category == "students" &&
                        elem.org_name == accountInfo.name
                      );
                    })
                    .map((collection) => (
                      <MenuItem value={collection}>{collection.name}</MenuItem>
                    ))}
              </Select>
            </FormControl>
            <Grid item>
              <FormControl
                className={(classes.formControl, classes.margin, classes.input)}
              >
                <InputLabel id="collection-select-label">
                  {props.t("personal")}
                </InputLabel>
                <Select
                  helperText={error ? props.t("selectCol") : ""}
                  labelId="collection-select-label"
                  id="collection-select"
                  value={collection}
                  onChange={handleCollectionChange}
                >
                  {collections &&
                    collections
                      .filter((elem) => {
                        return elem.category == "personal";
                      })
                      .map((collection) => (
                        <MenuItem value={collection}>
                          {collection.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
        {!collectionName &&
          accountInfo &&
          accountInfo.departments &&
          accountInfo.departments.map((department) => {
            return (
              <Grid item>
                <FormControl
                  className={
                    (classes.formControl, classes.margin, classes.input)
                  }
                >
                  <InputLabel id="collection-select-label">
                    {department.name}
                  </InputLabel>
                  <Select
                    helperText={error ? props.t("selectCol") : ""}
                    labelId="collection-select-label"
                    id="collection-select"
                    value={collection}
                    onChange={handleCollectionChange}
                  >
                    {collections &&
                      collections
                        .filter((elem) => {
                          return elem.category == department.id;
                        })
                        .map((collection) => (
                          <MenuItem value={collection}>
                            {collection.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
        {collection && (
          <React.Fragment>
            <div>
              <Button
                startIcon={<BackArrow />}
                classes={{
                  root: classes.backBtn,
                  label: classes.backBtnLabel,
                }}
                onClick={() => {
                  setCollectionName(null);
                  setCollection(null);
                  setSubcollection(null);
                  setSubcollectionName(null);
                }}
              >
                {props.t("diffCol")}
              </Button>
              <br></br>
              <Button
                id="confirm-col"
                style={{ marginTop: "10px" }}
                onClick={handleSave}
                color="primary"
                className={classes.primaryBtn}
              >
                {props.t("moveTo")}
                {collectionName}
                {subcollectionName ? " - " + subcollectionName : ""}
              </Button>
              {!(props.item.type == "subcollection") && (
                <FormControl
                  className={
                    (classes.formControl, classes.margin, classes.input)
                  }
                >
                  <InputLabel id="collection-select-label">
                    {props.t("optSubcol")}
                  </InputLabel>
                  <Select
                    helperText={error ? props.t("optSubcol") : ""}
                    labelId="collection-select-label"
                    id="collection-select"
                    value={subcollection}
                    onChange={handleSubcollectionChange}
                  >
                    {subcollections &&
                      subcollections.map((collection) => (
                        <MenuItem value={collection}>
                          {collection.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </React.Fragment>
        )}
      </DialogLayout>
    </React.Fragment>
  );
});

export default MoveItemDialog;
