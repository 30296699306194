import React from "react";
import StudentTable from "../StudentTable";
import MenuItem from "@material-ui/core/MenuItem";
import { dbFormat } from "../../helpers";
import { useAuth } from "../auth/AuthContext";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import DialogLayout from "../layout/DialogLayout";
import "../i18n";
import { withTranslation } from "react-i18next";

const AddStudentDialog = withTranslation()((props) => {
  const [fetching, setFetching] = React.useState(true);
  const [students, setStudents] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const { currentUser, getToken, accountInfo, displayMessage } = useAuth();
  const [collective, setCollective] = React.useState(false);
  const [changes, setChanges] = React.useState(false);

  const handleClickOpen = () => {
    setFetching(true);
    setEmail("");
    props.setContextState(initialState);
    setOpen(true);
    setStudents(null);
    setChanges(false);
    getStudent();
  };

  const handleAddStudent = () => {
    setEmail("");
    setStudents([...students, email]);
    setChanges(true);
  };

  const handleStudentRemove = (removeEmail) => {
    setEmail("");
    setStudents([
      ...students.filter((student) => {
        return student != removeEmail;
      }),
    ]);
    setChanges(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const getStudent = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/student_collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            uid: "get",
            name: dbFormat(props.rightClickedItemName),
            email: email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            setFetching(false);
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          if (data.type == 2) {
            var studentArray = [];
            for (let index = 0; index < data.students.length; index++) {
              studentArray.push(data.students[index].user_id);
            }
            setStudents(studentArray);
            setCollective(true);
          } else if (data.type == 1) {
            var studentArray = [];
            for (let index = 0; index < data.students.length; index++) {
              studentArray.push(data.students[index].user_id);
            }
            setStudents(studentArray);
            setCollective(false);
          }
          setFetching(false);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleSave = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/student_collections/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            uid: currentUser.uid,
            name: dbFormat(props.rightClickedItemName),
            email: email,
            students: students,
            org_name: accountInfo.name,
          }),
        }
      ).then((result) => {
        displayMessage("success", " aðgangur að safni hefur verið uppfærður");
        setChanges(false);
      });
    });
  };

  return (
    <React.Fragment>
      {props.category == "students" && (
        <MenuItem onClick={handleClickOpen}>{props.t("editStud")}</MenuItem>
      )}
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={changes}
        displaySecondaryBtn={true}
        primaryBtnText={props.t("save")}
        secondaryBtnText={props.t("cancel")}
        title={
          props.t("studentAccessTo") + ' "' + props.rightClickedItemName + '"'
        }
        maxWidth="md"
        open={open}
      >
        {!fetching && (
          <React.Fragment>
            {!collective && props.t("oneStudPerCol")}
            <StudentTable
              max={!collective}
              students={students}
              setEmail={setEmail}
              email={email}
              setStudents={setStudents}
              handleAddStudent={handleAddStudent}
              handleStudentRemove={handleStudentRemove}
            ></StudentTable>
          </React.Fragment>
        )}
        {fetching && (
          <React.Fragment>
            <Typography style={{ marginBottom: "10px" }} variant="body1">
              <Skeleton width="50%" />
            </Typography>
            <Typography variant="caption">
              <Skeleton width="100%" />
            </Typography>
            <Typography style={{ marginBottom: "-5px" }} variant="h3">
              <Skeleton width="20%" />
            </Typography>
          </React.Fragment>
        )}
      </DialogLayout>
    </React.Fragment>
  );
});

export default AddStudentDialog;
