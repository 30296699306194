import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import StopIcon from "@material-ui/icons/Stop";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Forward5Icon from "@material-ui/icons/Forward5";
import Replay5Icon from "@material-ui/icons/Replay5";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import SpeedControls from "../SpeedControls";
import SaveIcon from "@material-ui/icons/Save";
import LooperSwitch from "../LoopSwitch";

const StdButton = withStyles((theme) => ({
  root: {
    display: "inline",
    fontSize: "1px !important",
    padding: "2px 5px !important",
    margin: 5,
    width: 98,
    [theme.breakpoints.down("sm")]: {
      with: "auto",
    },
  },
}))(Button);

const AddButton = withStyles({
  root: {
    display: "inline",
    borderRadius: "50% !important",
    height: 35,
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    width: "35px !important",
    minWidth: "35px !important",
    marginLeft: 15,
    background: "#43418D",
  },
})(Button);

const ZoomButton = withStyles({
  root: {
    padding: 2,
  },
})(IconButton);

const PauseSymbol = withStyles({
  root: {
    width: "25px",
    height: "24.25px",
    color: "black",
    margin: "-7px",
  },
})(PauseIcon);

const StopSymbol = withStyles({
  root: {
    width: "26.25px",
    height: "26.25px",
    color: "black",
    margin: "-7px",
  },
})(StopIcon);

const PlaySymbol = withStyles({
  root: {
    width: "30px",
    height: "30px",
    color: "black",
    margin: "-9.5px",
  },
})(PlayArrowIcon);

const PlayButton = withStyles({
  root: {
    borderRadius: "50%",
    backgroundColor: "#A4A3EE",
    "&:hover": {
      backgroundColor: "#b6b5ee",
      boxShadow: "0px 0px 11px rgba(67, 65, 141, 0.8)",
    },
  },
})(IconButton);

const StopButton = withStyles({
  root: {
    borderRadius: "50%",
    backgroundColor: "#A4A3EE",
    marginLeft: "6px",
    marginRight: "0",
    "&:hover": {
      backgroundColor: "#b6b5ee",
      boxShadow: "0px 0px 11px rgba(67, 65, 141, 0.8)",
    },
  },
})(IconButton);

export default function MobileControls(props) {
  return (
    <React.Fragment>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid item>
          <div className="play-control">
            <PlayButton onClick={() => props.play()} id="play" color="primary">
              {props.isPlaying ? (
                <PauseSymbol></PauseSymbol>
              ) : (
                <PlaySymbol></PlaySymbol>
              )}
            </PlayButton>
            <StopButton onClick={() => props.stop()}>
              <StopSymbol></StopSymbol>
            </StopButton>
          </div>
        </Grid>
        <Grid item>
          <StdButton
            disabled={!props.isPlaying}
            className="control-text-btn"
            variant="contained"
            onClick={() => props.setStart()}
          >
            setja upphaf
          </StdButton>

          <StdButton
            style={{ marginRight: props.addDialog ? "-200px" : "0" }}
            disabled={!props.isPlaying}
            className="control-text-btn"
            variant="contained"
            onClick={() => props.setEnd()}
          >
            setja endir
          </StdButton>
        </Grid>
        {!props.addDialog && (
          <Grid item style={{ maxWidth: 77, flex: "2", textAlign: "right" }}>
            {!props.student &&
              (props.isChangingSegment ? (
                <AddButton
                  onClick={() => props.changeSegmentTime()}
                  disabled={!props.isSegmentChanged}
                >
                  <SaveIcon></SaveIcon>
                </AddButton>
              ) : (
                <AddButton
                  id="mobAddSeg"
                  onClick={() => props.handleAddSegmentClick()}
                >
                  <AddIcon id="mobAddSegIcon"></AddIcon>
                </AddButton>
              ))}
          </Grid>
        )}
      </Grid>
      {
        <Grid container alignItems="center" style={{ marginTop: 8 }}>
          <Grid item xs={2}>
            <IconButton onClick={props.fiveBack}>
              <Replay5Icon></Replay5Icon>
            </IconButton>
          </Grid>

          <Grid item xs={2} style={{ textAlign: "right" }}>
            <LooperSwitch
              loopChecked={props.loopChecked}
              toggleLoop={props.toggleLoop}
            />
          </Grid>
          <Grid item xs={props.addDialog ? 8 : 4}>
            <SpeedControls
              increase={props.increase}
              decrease={props.decrease}
              changeSpeed={props.changeSpeed}
              ytControls={true}
              speeds={props.speeds}
              speed={props.speed}
            ></SpeedControls>
          </Grid>
          {!props.addDialog && (
            <>
              <Grid item xs={2}>
                <ZoomButton
                  onClick={props.zoomChange}
                  color="primary"
                  className="zoom-btn"
                  aria-label="Zoom btn"
                  component="span"
                  disabled={props.isSliderReset}
                >
                  {props.isZoomed ? (
                    <span
                      className="zoom-out-icon"
                      style={{ color: "#605df9" }}
                    />
                  ) : (
                    <span className="zoom-in-icon" />
                  )}
                </ZoomButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={props.fiveForward}>
                  <Forward5Icon></Forward5Icon>
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      }
    </React.Fragment>
  );
}
