import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useAuth } from "../auth/AuthContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import "../i18n";
import { withTranslation } from "react-i18next";

const LinkButton = withStyles({
  root: {
    margin: "4px",
  },
})(Button);

const DownloadLink = withTranslation()((props) => {
  const { getToken } = useAuth();
  const [description, setDescription] = React.useState("");
  const [displaySrc, setDisplaySrc] = React.useState(null);
  const fetchDesc = (id) => {
    const videoId = id;
    getToken().then((idToken) => {
      axios
        .get(process.env.REACT_APP_RESOURCE_SERVER + "/api/videos/" + videoId, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .then((result) => {
          if (
            !result.data.description ||
            result.data.description == "null" ||
            result.data.description == "undefined" ||
            result.data.description == null ||
            result.data.description == undefined
          ) {
            setDescription("");
          } else {
            setDescription(result.data.description);
          }
        });
    });
  };

  if (props.item.description == null || props.item.description == undefined) {
    fetchDesc(props.item.id);
  }
  const useStyles = makeStyles((theme) => ({
    header: {
      color:
        theme.palette.type === "light"
          ? theme.palette.common.black
          : theme.palette.common.white,
    },
  }));
  const classes = useStyles();
  const download = () => {
    if (props.item) {
      var link = "";
      if (props.item.youtube_id.includes("drive.google.com")) {
        var splitLink = props.item.youtube_id.split("/");
        if (splitLink[splitLink.length - 2]) {
          var fileId = splitLink[splitLink.length - 2];
        } else {
          return;
        }
        link =
          "https://drive.google.com/u/0/uc?id=" + fileId + "&export=download";
      } else if (props.item.youtube_id.includes("dropbox")) {
        const linkArr = props.item.youtube_id.split("?dl=0");

        if (linkArr.length == 2) {
          link = linkArr[0] + "?dl=1" + linkArr[1];
        } else {
          link = props.item.youtube_id.slice(0, -1) + "1";
        }
      } else if (props.item.youtube_id.includes("1drv.ms")) {
        link = props.item.youtube_id;
      }
      if (link === "") {
        return;
      }
      const newWindow = window.open(link, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  const open = () => {
    if (props.item) {
      var link = "";
      if (props.item.youtube_id.includes("drive.google.com")) {
        var splitLink = props.item.youtube_id.split("/");
        if (splitLink[splitLink.length - 2]) {
          var fileId = splitLink[splitLink.length - 2];
        } else {
          return;
        }
        link =
          "https://drive.google.com/file/d/" + fileId + "/view?usp=sharing";
      } else if (props.item.youtube_id.includes("dropbox")) {
        link = props.item.youtube_id.slice(0, -1) + "0";
      } else if (props.item.youtube_id.includes("1drv.ms")) {
        link = props.item.youtube_id;
      }
      if (link === "") {
        return;
      }
      const newWindow = window.open(link, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  React.useEffect(() => {
    fetchDesc(props.item.id);
  }, [props.changeInSelected]);

  React.useEffect(() => {
    const selectedItems = document.getElementsByClassName("selected-item");
    if (selectedItems) {
      for (let index = 0; index < selectedItems.length; index++) {
        selectedItems[index].classList.remove("selected-item");
      }
    }
    const elem = document.getElementById(props.item.id);
    if (elem != null) {
      elem.classList.add("selected-item");
    }
    if (
      props.item.youtube_id.includes("dropbox") &&
      props.item.youtube_id.includes(".pdf")
    ) {
      const linkArr = props.item.youtube_id.split("?dl=0");

      if (linkArr.length == 2) {
        setDisplaySrc(linkArr[0] + "?raw=1");
      }
    } else {
      setDisplaySrc(null);
    }
  }, [props.item.id]);
  return (
    <Grid className="link-container" container>
      <p className="video-sub-collection-name">
        {props.currSubCollection ? (
          <>
            <span className="collection">{props.collectionName} / </span>
            {props.currSubCollection}
          </>
        ) : (
          props.collectionName
        )}
      </p>
      <Grid item md={10} sm={9} xm={8}>
        <h2 className="link-heading"> {props.item.name} </h2>{" "}
      </Grid>
      <Grid item md={2} sm={3} xs={4}>
        <Grid container justify="flex-end" alignItems="flex-start">
          <IconButton
            disabled={props.browseVids == "none" || props.browseVids == "next"}
            onClick={() => props.setBrowseVids("back")}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            disabled={props.browseVids == "none" || props.browseVids == "prev"}
            onClick={() => props.setBrowseVids("forward")}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
      {props.item.description &&
        props.item.description != "null" &&
        props.item.description != "undefined" &&
        !description && (
          <Grid item md={12}>
            <p className="link-desc"> {props.item.description} </p>{" "}
          </Grid>
        )}
      {description && (
        <Grid item md={12}>
          <p className="link-desc"> {description} </p>{" "}
        </Grid>
      )}

      <Grid item md={4}>
        <LinkButton
          className="link-button"
          onClick={download}
          endIcon={<GetAppIcon></GetAppIcon>}
        >
          {props.t("download")}
        </LinkButton>
        <LinkButton
          className="link-button"
          onClick={open}
          endIcon={<OpenInNewIcon></OpenInNewIcon>}
        >
          {props.t("open")}
        </LinkButton>
        <Grid item md={4}></Grid>
      </Grid>
      {displaySrc && (
        <Grid item md={12} sm={12} lg={12}>
          <iframe
            className={"file-display-frame"}
            title="unique"
            src={displaySrc}
          ></iframe>
        </Grid>
      )}
    </Grid>
  );
});

export default DownloadLink;
