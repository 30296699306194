import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useAuth } from "./auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    inputProps: {
      padding: "10px 12px 10px",
    },
  },
}));

export default function OrgSelect() {
  const classes = useStyles();
  const { accountInfo, handleOrgChange } = useAuth();

  const handleChange = (event) => {
    handleOrgChange(event.target.value);
  };

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <Select
        id="demo-simple-select-filled"
        value={accountInfo.name}
        onChange={handleChange}
        displayEmpty
        inputProps={{
          "aria-label": "Without label",
          className: "workspace-select",
        }}
      >
        {accountInfo &&
          accountInfo.orgs &&
          accountInfo.orgs.map((org) => {
            return <MenuItem value={org.name}> {org.name} </MenuItem>;
          })}
      </Select>
    </FormControl>
  );
}
