import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import DialogLayout from "../layout/DialogLayout";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField as YlTextField } from "../InputFields";
import "../i18n";
import { withTranslation } from "react-i18next";

const RenameItemDialog = withTranslation()((props) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleClickOpen = () => {
    props.setContextState(initialState);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (props.rightClickedItemName == "") {
      setError(true);
      return;
    }
    props.setEditObj({
      changed: !props.editObj.changed,
      index: Number(props.item.index),
      name: props.rightClickedItemName,
      type: props.item.type,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (props.item.id === "") {
      props.setContextState(initialState);
    }
  }, [props.item]);

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>{props.t("rename")}</MenuItem>
      <DialogLayout
        handleClose={handleClose}
        handleSave={handleSave}
        displayPrimaryBtn={!(props.rightClickedItemName == "")}
        displaySecondaryBtn={true}
        primaryBtnText={props.t("save")}
        secondaryBtnText={props.t("cancel")}
        title={props.t("renameCol")}
        maxWidth="md"
        open={open}
      >
        <p>{props.t("newNameForCol")}</p>
        <form onSubmit={handleSave}>
          <YlTextField
            autoFocus
            margin="dense"
            id="name"
            label={props.t("name")}
            type="Text"
            helperText={error ? props.t("chooseName") : ""}
            value={props.rightClickedItemName}
            onChange={props.setRightClickedItemName}
            fullWidth
          />
        </form>
      </DialogLayout>
    </React.Fragment>
  );
});

export default RenameItemDialog;
