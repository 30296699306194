import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeMute from "@material-ui/icons/VolumeMute";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: 100,
    marginTop: -40,
    marginLeft: 15,
  },
  slider: {
    color: "white !important",
  },
  up: {
    zIndex: 1,
  },
});

export default function VolumeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(100);
  const [isShown, setIsShown] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.player.setVolume(newValue);
  };

  const mute = () => {
    setValue(0);
    props.player.setVolume(0);
  };

  const unMute = () => {
    setValue(100);
    props.player.setVolume(100);
  };

  return (
    <div className={classes.root}>
      <Grid onMouseLeave={() => setIsShown(false)} container spacing={1}>
        <Grid item className={classes.up}>
          {value > 50 && (
            <VolumeUp
              onClick={() => mute()}
              onMouseEnter={() => {
                setIsShown(true);
                setValue(props.player.getVolume());
              }}
            />
          )}
          {value < 51 && value > 0 && (
            <VolumeDown
              onClick={() => mute()}
              onMouseEnter={() => {
                setIsShown(true);
                setValue(props.player.getVolume());
              }}
            />
          )}
          {value === 0 && (
            <VolumeMute
              onClick={() => unMute()}
              onMouseEnter={() => {
                setIsShown(true);
                setValue(props.player.getVolume());
              }}
            />
          )}
        </Grid>
        <Grid item xs>
          {isShown && (
            <Slider
              className={classes.slider}
              value={value}
              onChange={handleChange}
              aria-labelledby="continuous-slider"
            />
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </div>
  );
}
