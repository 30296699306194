import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useAuth } from "../auth/AuthContext";
import { dbFormat } from "../../helpers";
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg";
import DialogLayout from "../layout/DialogLayout";
import { TextField as YlTextField } from "../InputFields";
import "../i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  primaryBtn: {
    background: theme.palette.primary.main,
  },
  margin: {
    margin: theme.spacing(1),
    // textAlign: "center",
  },
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  title: {
    margin: 0,
    paddingBottom: 0,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
}));

const ClipFromSegmentDialog = withTranslation()((props) => {
  const { currentUser, getToken, accountInfo, displayMessage } = useAuth();
  const [collection, setCollection] = useState();
  const [collectionName, setCollectionName] = useState();
  const [name, setName] = useState(props.name);
  const [error, setError] = useState(false);
  const [collections, setCollections] = React.useState([]);
  const [description, setDescription] = React.useState(props.note);
  const [subcollections, setSubcollections] = React.useState(null);
  const [subcollection, setSubcollection] = React.useState(null);
  const [subcollectionName, setSubcollectionName] = React.useState(null);

  const classes = useStyles();

  const handleCollectionChange = (event) => {
    setCollectionName(event.target.value.name);
    setCollection(event.target.value.id);
    setSubcollectionName(null);
    setSubcollections(null);
    setSubcollection(null);
    setTimeout(() => {
      if (document.getElementById("confirm-copy-clip")) {
        document.getElementById("confirm-copy-clip").focus();
      }
    }, 200);
    getSubcols(event.target.value.id);
  };

  const getSubcols = (colId) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${colId}/subcollections`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          setSubcollections(data);
        })
        .catch(() => {
          return;
        });
    });
  };

  const handleSubcollectionChange = (event) => {
    setSubcollectionName(event.target.value.name);
    setSubcollection(event.target.value.id);
  };

  const getData = (id) => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${id}/collections`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          const formattedcollections = data.map((collection) => {
            return {
              org_name: collection.org_name,
              category: collection.category,
              name: collection.name,
              id: collection.id,
            };
          });
          setCollections(formattedcollections);
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(err);
        });
    });
  };

  const handleClose = () => {
    props.setOpen(false);
    props.setMenuOpen(false);
  };
  const handleSave = () => {
    if (collection == null) {
      setError(true);
      return;
    }

    if (collection != null) {
      getToken().then((idToken) => {
        if (subcollection) {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER}/api/subcollections/${subcollection}/videos`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify({
                name: dbFormat(name),
                youtubeId: props.youtube_id,
                subcollectionId: subcollection,
                start: props.start,
                ending: props.ending,
                description: description,
              }),
            }
          )
            .then((response) => {
              if (!response.ok) {
                return Promise.reject();
              }
              return response.json();
            })
            .then((data) => {
              displayMessage(
                "success",
                '"' +
                  name +
                  '" ' +
                  props.t("wasAddedSub") +
                  ' " ' +
                  subcollectionName +
                  '"'
              );
              if (props.setAddedVid) {
                props.setAddedVid({
                  name: dbFormat(name),
                  youtube_id: props.youtube_id,
                  collectionId: -1,
                  start: props.start,
                  ending: props.ending,
                  id: data.id,
                  order_no: data.order_no,
                  description: description,
                  subcollectionId: subcollection,
                });
              }
              // TODO ADD DESCRIPTION
              handleClose();
            })
            .catch((err) => {
              displayMessage("error", "Upp kom villa");
              /* eslint-disable no-console */
            });
        } else {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${collection}/videos`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify({
                name: dbFormat(name),
                youtubeId: props.youtube_id,
                subcollectionId: 2,
                start: props.start,
                ending: props.ending,
                description: description,
              }),
            }
          )
            .then((response) => {
              if (!response.ok) {
                return Promise.reject();
              }

              handleClose();
              return response.json();
            })
            .then((data) => {
              props.setAddedVid({
                name: name,
                youtube_id: props.youtube_id,
                collectionId: collection,
                start: props.start,
                ending: props.ending,
                id: data.id,
                order_no: 0,
                description: description,
              });
              displayMessage(
                "success",
                '"' +
                  name +
                  '" ' +
                  props.t("wasAddedCol") +
                  ' " ' +
                  collectionName +
                  '"'
              );
            })
            .catch((err) => {
              displayMessage("error", "Villa kom upp");
              /* eslint-disable no-console */
              console.error(err);
            });
        }
      });
    }
  };
  useEffect(() => {
    if (props.open == true) {
      setName(props.name);
      setDescription(props.note);
      getData(currentUser.uid);
    }
  }, [props.open]);

  return (
    <DialogLayout
      handleClose={handleClose}
      handleSave={handleSave}
      displayPrimaryBtn={false}
      displaySecondaryBtn={true}
      secondaryBtnText={props.t("Cancel")}
      title={props.t("copySegAsClip")}
      maxWidth="md"
      open={props.open}
    >
      {!collectionName && (
        <p style={{ marginTop: "0px", marginBottom: "0px" }}>
          {props.t("colForClip")}
        </p>
      )}
      {!collectionName && (
        <React.Fragment>
          <Grid item xs={12}>
            <FormControl
              className={(classes.formControl, classes.margin, classes.input)}
            >
              <InputLabel id="collection-select-label">
                {props.t("students")}
              </InputLabel>
              <Select
                helperText={error ? props.t("selectCol") : ""}
                labelId="collection-select-label"
                id="collection-select"
                value={collection}
                onChange={handleCollectionChange}
              >
                {collections &&
                  collections
                    .filter((elem) => {
                      return (
                        elem.category == "students" &&
                        elem.org_name == accountInfo.name
                      );
                    })
                    .map((collection) => (
                      <MenuItem value={collection}>{collection.name}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={(classes.formControl, classes.margin, classes.input)}
            >
              <InputLabel id="collection-select-label">
                {props.t("personal")}
              </InputLabel>
              <Select
                helperText={error ? props.t("selectCol") : ""}
                labelId="collection-select-label"
                id="collection-select"
                value={collection}
                onChange={handleCollectionChange}
              >
                {collections &&
                  collections
                    .filter((elem) => {
                      return elem.category == "personal";
                    })
                    .map((collection) => (
                      <MenuItem value={collection}>{collection.name}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </React.Fragment>
      )}
      {!collectionName &&
        accountInfo &&
        accountInfo.departments &&
        accountInfo.departments.map((department) => {
          return (
            <Grid item xs={12}>
              <FormControl
                className={(classes.formControl, classes.margin, classes.input)}
              >
                <InputLabel id="collection-select-label">
                  {department.name}
                </InputLabel>
                <Select
                  helperText={error ? props.t("selectCol") : ""}
                  labelId="collection-select-label"
                  id="collection-select"
                  value={collection}
                  onChange={handleCollectionChange}
                >
                  {collections &&
                    collections
                      .filter((elem) => {
                        return elem.category == department.id;
                      })
                      .map((collection) => (
                        <MenuItem value={collection}>
                          {collection.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          );
        })}

      {collection && (
        <React.Fragment>
          <div>
            <Button
              startIcon={<BackArrow />}
              classes={{
                root: classes.backBtn,
                label: classes.backBtnLabel,
              }}
              onClick={() => {
                setCollectionName(null);
                setCollection(null);
              }}
            >
              {props.t("diffCol")}
            </Button>
            <br></br>
            <Button
              id="confirm-copy-clip"
              style={{ marginTop: "10px" }}
              onClick={handleSave}
              color="primary"
              className={classes.primaryBtn}
            >
              {props.t("copyTo")} {collectionName}{" "}
              {subcollectionName ? " - " + subcollectionName : ""}
            </Button>
            {
              <FormControl
                className={(classes.formControl, classes.margin, classes.input)}
              >
                <InputLabel id="collection-select-label">
                  {props.t("optSubcol")}
                </InputLabel>
                <Select
                  helperText={error ? props.t("selectCol") : ""}
                  labelId="collection-select-label"
                  id="collection-select"
                  value={subcollection}
                  onChange={handleSubcollectionChange}
                >
                  {subcollections &&
                    subcollections.map((subcollection) => (
                      <MenuItem value={subcollection}>
                        {subcollection.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            }
          </div>
        </React.Fragment>
      )}
      <Grid item style={{ paddingTop: "20px" }}>
        <YlTextField
          margin="dense"
          id="name"
          label={props.t("name")}
          helperText={error ? props.t("selectCol") : ""}
          type="text"
          value={name}
          onChange={setName}
          fullWidth
        />
      </Grid>
      {description != null && (
        <Grid item style={{ paddingTop: "20px" }}>
          <YlTextField
            margin="dense"
            id="name"
            label={props.t("description")}
            type="text"
            value={description}
            onChange={setDescription}
            fullWidth
          />
        </Grid>
      )}
    </DialogLayout>
  );
});

export default ClipFromSegmentDialog;
