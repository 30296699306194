import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as LooperIcon } from "../assets/icons/loop-icon.svg";
import { Icon } from "@iconify/react";
import "./i18n";
import { withTranslation } from "react-i18next";

const LooperSwitch = withStyles({
  thumb: {
    padding: "1500px",
  },
  root: {
    width: 44,
    height: 44,
    padding: 11,
  },
  switchBase: {
    background: "radial-gradient(50% 50% at 50% 50%, #232323 0%, #181818 100%)",
    marginTop: 8,
    marginLeft: 8,
    padding: 4,
    "&$checked": {
      background:
        "radial-gradient(50% 50% at 50% 50%, #FFFDF7 0%, #FFF2C6 100%)",
      boxShadow: "0px 0px 11px rgba(255, 253, 247, 0.64)",
    },
  },
  track: {
    background:
      "linear-gradient(270deg, #343434 -7.89%, rgba(52, 52, 52, 0) 148.5%)",
    borderRadius: 30,
  },
  checked: {
    transform: "translateX(0px) !important",
  },
})(Switch);

const LoopIcon = withStyles({
  root: {
    width: "200px",
  },
})(Icon);

const LoopSwitch = withTranslation()((props) => {
  return (
    <>
      <FormControlLabel
        className="loop-switch"
        control={
          <Tooltip
            title={
              props.loopChecked
                ? props.t("loopSeg") + " : ON"
                : props.t("loopSeg") + " : OFF"
            }
            placement="bottom"
            enterDelay={500}
          >
            <LooperSwitch
              color="default"
              checked={props.loopChecked}
              onChange={(e) => props.toggleLoop(e)}
              icon={
                <LooperIcon />
                // <LoopIcon
                //   style={{
                //     color: "#fff",
                //   }}
                //   icon={loopMarker}
                // ></LoopIcon>
              }
              checkedIcon={
                <LooperIcon />
                // <LoopIcon
                //   style={{
                //     color: "#605df9",
                //   }}
                //   icon={loopMarker}
                // ></LoopIcon>
              }
            ></LooperSwitch>
          </Tooltip>
        }
      />
    </>
  );
});

export default LoopSwitch;
