import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { parseSegments } from "../../helpers";
import youtube from "../apis/youtube";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function SearchResult(props) {
  let { searchResults, loading } = props;

  const vidSelect = (id, name) => {
    props.setVideoName(name);
    props.handleVideoIdChange(id);
    youtube
      .get("/videos", {
        params: {
          id: id,
        },
      })
      .then((result) => {
        if (result.data && result.data.items && result.data.items.length > 0) {
          props.setVideoName(result.data.items[0].snippet.title);
          var segs = parseSegments(result.data.items[0].snippet.description);
          props.setSegments(segs);
          document.activeElement.blur();
        }
        //setSearchResults(result.data.items)
      });
  };

  const classes = useStyles();

  return (
    <List id="search-list" className={classes.root}>
      {loading ? (
        <>
          <LinearProgress />
        </>
      ) : (
        searchResults.map((item, index) => {
          return (
            <>
              <ListItem
                autoFocus={index == 0}
                button
                onClick={() => vidSelect(item.id.videoId, item.snippet.title)}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <img
                    alt={item.snippet.title}
                    height="100"
                    src={item.snippet.thumbnails.default.url}
                  />
                </ListItemAvatar>
                <ListItemText
                  style={{ padding: "10px" }}
                  primary={item.snippet.title}
                  secondary={
                    <React.Fragment>{item.snippet.description}</React.Fragment>
                  }
                />
              </ListItem>
            </>
          );
        })
      )}
    </List>
  );
}
