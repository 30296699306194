import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Collapse from "@material-ui/core/Collapse";
import "./i18n";
import { withTranslation } from "react-i18next";

const SegmentNote = withTranslation()((props) => {
  const handleSaveNote = (e) => {
    e.preventDefault();
    props.handleNoteChange(props.noteText, props.segmentId);
  };

  return (
    <Collapse in={props.open}>
      <div className={"segment-note " + (props.open ? "" : "hidden")}>
        <form className="note-form" onSubmit={(e) => handleSaveNote(e)}>
          <TextareaAutosize
            autoFocus
            className="note-input"
            rowsMax={4}
            aria-label="Write a note for segment"
            placeholder={props.t("writeNote") + " ..."}
            onChange={(e) => props.setNoteText(e.target.value)}
            value={props.noteText}
            onBlur={(e) => handleSaveNote(e)}
          />
        </form>
      </div>
    </Collapse>
  );
});

export default SegmentNote;
