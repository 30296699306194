import React from "react";
import Slider from "@material-ui/core/Slider";
import { timeToString, dynamicSort, dbFormat } from "../helpers";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import StopIcon from "@material-ui/icons/Stop";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import IntervalDisplay from "./IntervalDisplay";
import List from "@material-ui/core/List";
import Segment from "./Segment";
import VolumeSlider from "./VolumeSlider";
import SpeedControls from "./SpeedControls";
import SortingOptions from "./SortingOptions";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CloseIcon from "@material-ui/icons/Close";
import Portal from "@material-ui/core/Portal";
import Forward5Icon from "@material-ui/icons/Forward5";
import Replay5Icon from "@material-ui/icons/Replay5";
import Grow from "@material-ui/core/Collapse";
import Collapse from "@material-ui/core/Collapse";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as SaveIcon } from "../assets/icons/save-icon.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import Attachment from "./Attachment";
import MobileControls from "../components/videos/MobileControls";
import { isMobileOnly } from "react-device-detect";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LoadingSegments from "./videos/LoadingSegments";
import LooperSwitch from "./LoopSwitch";
import VideoSettings from "./videos/VideoSettings";
import AlertMessage from "./AlertMessage";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Tooltip } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VimeoInterface from "./VimeoInterface.js";
import ShareIcon from "@material-ui/icons/Share";
import SaveVidIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import SaveColButton from "./collections/SaveColButton";
import { withTranslation } from "react-i18next";
import AddCollVideoDialog from "./collections/AddCollVideoDialog";
import "./i18n";
import {
  LocalConvenienceStoreOutlined,
  ThreeDRotation,
} from "@material-ui/icons";

const StdButton = withStyles({
  root: {
    display: "inline",
    fontSize: "3px !important",
    padding: "3px 9px !important",
    margin: "5px",
  },
})(Button);

const PauseSymbol = withStyles({
  root: {
    width: "25px",
    height: "24.25px",
    color: "black",
    margin: "-7px",
  },
})(PauseIcon);

const StopSymbol = withStyles({
  root: {
    width: "26.25px",
    height: "26.25px",
    color: "black",
    margin: "-7px",
  },
})(StopIcon);

const PlaySymbol = withStyles({
  root: {
    width: "30px",
    height: "30px",
    color: "black",
    margin: "-9.5px",
  },
})(PlayArrowIcon);

const PlayButton = withStyles({
  root: {
    borderRadius: "50%",
    backgroundColor: "#A4A3EE",
    "&:hover": {
      backgroundColor: "#b6b5ee",
      boxShadow: "0px 0px 11px rgba(67, 65, 141, 0.8)",
    },
  },
})(IconButton);

const StopButton = withStyles({
  root: {
    borderRadius: "50%",
    backgroundColor: "#A4A3EE",
    marginLeft: "6px",
    marginRight: "0",
    "&:hover": {
      backgroundColor: "#b6b5ee",
      boxShadow: "0px 0px 11px rgba(67, 65, 141, 0.8)",
    },
  },
})(IconButton);

const ZoomButton = withStyles({
  root: {
    // marginLeft: "-8px",
    // marginRight: "0.7%",
    padding: 4,
  },
})(IconButton);

const SegmentSlider = withStyles({
  root: {
    width: "calc(100% - 24px)",
    marginTop: "0px",
    paddingTop: "0px",
    marginLeft: "12px",

    color: "#A4A3EE",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 6,
    backgroundColor: "#EAE8F9",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginTop: -8,
    marginLeft: -2,
    zIndex: 3,
    "&:focus, &:hover, &$active": {
      boxShadow: "0px 0px 14px #43418D",
    },
    transition: "border .2s ease, box-shadow .2s ease",
  },
  valueLabel: {
    left: "calc(-50% - 12px)",
    top: -24,
    "& *": {
      background: "transparent",
      color: "#A4A3EE",
    },
    "& > span > span": {
      padding: 8,
      background: "rgba(0, 0, 0, 0.65)", //TODO THEME
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: "#43418D",
    opacity: "0.7",
    zIndex: 3,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    zIndex: 1,
  },
})(Slider);

const VideoProgress = withStyles({
  root: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "12px",
    borderRadius: 4,
    transition: "none !important",
    animation: "none !important",
    height: 8,
    backgroundColor: "transparent",
    position: "absolute",
    width: "calc(100% - 24px)",
    "*, *::before, *::after": {
      transition: "none !important",
      animation: "none !important",
    },
    zIndex: 2,
  },
  bar: {
    transition: "none !important",
    animation: "none !important",
    borderRadius: "4px 0 0 4px",
    backgroundColor: "#9B3F3F",
  },
})(LinearProgress);

const PaginationBtn = withStyles((theme) => ({
  root: {
    color: "#A4A3EE",
    [theme.breakpoints.down("sm")]: {
      // width: 28,
      padding: 8,
      "& svg": {
        height: 25,
      },
    },
  },
}))(IconButton);

class VideoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: false,
      min: 0,
      max: 10,
      absMin: 0,
      absMax: 10,
      selected: -1,
      speeds: [1],
      speed: 1,
      videoId: "",
      player: null,
      playerReady: false,
      sliderValue: [0, 10],
      progressValue: 0,
      progressBarValue: 0,
      newLickTitle: "",
      isAddingNewSegment: false,
      selectedSegSortingOpt: 1,
      loadingSegments: false,
      segmentCount: 0, //For segment ID when not logged in
      loopChecked: true,
      isZooming: false,
      changingSegmentTime: false,
      lockMode: true,
    };

    this.segSortingOpt = ["Nafn (a-ö)", "Tímaröð"];
    this.changeSelectedSortingOpt = this.changeSelectedSortingOpt.bind(this);
    this.sortSegments = this.sortSegments.bind(this);
    this.triggerFullscreen = this.triggerFullscreen.bind(this);
    this.videoRef = React.createRef();
    this.addSegmentRef = React.createRef();
    this.reset = this.reset.bind(this);
    this.updatePlayer = this.updatePlayer.bind(this);
    this.changeSpeed = this.changeSpeed.bind(this);
    this.isZoomed = false;
    this.isSliderReset = true;
    this.isSegmentChanged = false;
    this.ignoreBuffering = false;
    this.ingoreUpdate = false;
    this.pendingPlay = false;
    this.ignoreStateChange = false;
    this.prevState = 0;
    this.pauseInterval = false;
    this.lastEvent = null;
    this.video = "_aQonnNSATk"; //video id
    this.loopState = 1;
    this.intervalLock = null;
    this.segmentLock = false;
    this.vidEndBuffer = 0.5;
    // because the play event
    // is launced twice the first
    // time there is need for
    // keeping track of this
    this.vidHovered = false;
    this.timeVal = 0;
    this.loops = [];
    this.segmentChanges = [];
    this.toggleLoop = this.toggleLoop.bind(this);
    this.intervals = [];
    this.mouseVideoLeave = this.mouseVideoLeave(this);
    this.outOfBoundsCheck = this.outOfBoundsCheck.bind(this);
    this.outOfVideoBoundsCheck = this.outOfVideoBoundsCheck.bind(this);
    this.changeSegName = this.changeSegName.bind(this);
    this.progressBarCalc = this.progressBarCalc.bind(this);
    this.zoomChange = this.zoomChange.bind(this);
    this.deleteSegment = this.deleteSegment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.increaseSpeed = this.increaseSpeed.bind(this);
    this.findCorrectSpeedChange = this.findCorrectSpeedChange.bind(this);
    this.decreaseSpeed = this.decreaseSpeed.bind(this);
    this.changeSpeed = this.changeSpeed.bind(this);
    this.fetchDesc = this.fetchDesc.bind(this);
    this.changeMastered = this.changeMastered.bind(this);
    this.update = this.update.bind(this);
    this.updateSegmentStatus = this.updateSegmentStatus.bind(this);
    this.handleExitSegment = this.handleExitSegment.bind(this);
    this.handleAddSegmentClick = this.handleAddSegmentClick.bind(this);
    this.handleSegmentClickAway = this.handleSegmentClickAway.bind(this);
    this.addNewSegment = this.addNewSegment.bind(this);
    this.moveAhead = this.moveAhead.bind(this);
    this.changeSegmentNote = this.changeSegmentNote.bind(this);
    this.changeSegmentTime = this.changeSegmentTime.bind(this);
    this.setStart = this.setStart.bind(this);
    this.setEnd = this.setEnd.bind(this);
    this.intervalInputChange = this.intervalInputChange.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.segPlay = this.segPlay.bind(this);
    this.segStop = this.segStop.bind(this);
    this.handleChangeSegmentTime = this.handleChangeSegmentTime.bind(this);
    this.toggleControls = this.toggleControls.bind(this);
    this.fiveBack = this.fiveBack.bind(this);
    this.fiveForward = this.fiveForward.bind(this);
    this.oneBack = this.oneBack.bind(this);
    this.oneForward = this.oneForward.bind(this);
    this.lockModeToggle = this.lockModeToggle.bind(this);
    this.getShareLink = this.getShareLink.bind(this);
    this.lastMouseDown = null;
    this.movingThumb = false;
  }

  componentDidMount() {
    // On mount, check to see if the API script is already loaded
    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.updatePlayer;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      if (!this.props.addDialog) {
        this.setState(
          {
            attachments: [],
            absMax: this.props.video.ending - this.vidEndBuffer,
            absMin: this.props.video.start,
            max: this.props.video.ending - this.vidEndBuffer,
            min: this.props.video.start,
            thumbWidth: (this.props.video.ending - this.props.video.start) / 70,
            speed: Number(1),
            isPlaying: false,
            playerReady: false,
            player: null,
            isZoomed: false,
            progressValue: 0,
            progressBarValue: 0,
            sliderValue: [
              this.props.video.start,
              this.props.video.ending - this.vidEndBuffer,
            ],
          },
          () => {
            this.updatePlayer();
          }
        );
      } else {
        // https://www.dropbox.com/s/bq16s665ky30m4i/Country%20duet%20n%C3%B3tur.pdf?dl=0
        this.updatePlayer();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.licks !== this.props.licks && this.props.landing) {
      this.setState({
        licks: this.props.licks,
      });
    }
    if (prevProps.videoId !== this.props.videoId) {
      // Destroy the old player and create a new one!
      if (this.state.player !== undefined && this.state.player !== null) {
        this.state.player.destroy();
        this.setState(
          {
            player: null,
          },
          () => {
            this.updatePlayer();
          }
        );
      } else {
        this.updatePlayer();
      }
    } else if (prevProps.video) {
      if (prevProps.attachmentChange != this.props.attachmentChange) {
        this.props.getToken().then((idToken) => {
          axios
            .get(
              process.env.REACT_APP_RESOURCE_SERVER +
                "/api/videos/" +
                this.props.video.id +
                "/attachments/" +
                (this.props.student ? "/student" : "/personal"),
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then((response) => {
              this.setState({
                attachments: [...response.data],
              });
            })
            .catch((error) => {
              "";
            });
        });
      }
      if (prevProps.changeInSelected != this.props.changeInSelected) {
        this.fetchDesc(this.props.video.id);
      }
      if (prevProps.video.id !== this.props.video.id) {
        this.stopLoops();
        this.stopSegmentChanges();
        if (
          (this.state.player !== undefined && this.state.player !== null) ||
          true
        ) {
          if (this.state.player !== undefined && this.state.player) {
            this.state.player.destroy();
          }
          this.setState(
            {
              licks: [],
              selectedSegment: null,
              changeSegmentTime: false,
              absMax: this.props.video.ending - this.vidEndBuffer,
              absMin: this.props.video.start,
              max: this.props.video.ending - this.vidEndBuffer,
              min: this.props.video.start,
              thumbWidth:
                (this.props.video.ending - this.props.video.start) / 70,
              speed: Number(1),
              isPlaying: false,
              playerReady: false,
              player: null,
              isZoomed: false,
              progressValue: 0,
              progressBarValue: 0,
              sliderValue: [
                this.props.video.start,
                this.props.video.ending - this.vidEndBuffer,
              ],
            },
            () => {
              this.updatePlayer();
            }
          );
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.state.player) this.state.player.destroy();
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  scrollToVideo = () =>
    window.scrollTo(0, this.videoRef.current.offsetTop - 30);

  reset() {
    this.player.pauseVideo();
    this.player.seekTo(0);
    this.ignoreStateChange = true;
    this.isSliderReset = true;
    //this.player.seekTo(0)
    if (this.isZoomed) {
      this.zoomChange();
    }
    this.setState({
      progressBarValue: 0,
      sliderValue: [0, this.player.getDuration()],
      isPlaying: false,
    });
  }

  handleChangeSegmentTime(value) {
    this.setState({
      changingSegmentTime: value,
    });
  }

  fetchDesc(id) {
    if (this.props.video.id != -3) {
      const videoId = id;
      this.props.getToken().then((idToken) => {
        axios
          .get(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/videos/" +
              videoId +
              (this.props.student ? "/student" : "/personal"),
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((result) => {
            if (
              !result.data.description ||
              result.data.description == "null" ||
              result.data.description == "undefined" ||
              result.data.description == null ||
              result.data.description == undefined
            ) {
              this.setState({
                description: "",
              });
            } else {
              this.setState({
                description: result.data.description,
              });
            }
          });
      });
    }
  }

  updatePlayer(controlToggle) {
    var videoId = "T1uAp69IJnk";
    if (this.props.videoId) {
      videoId = this.props.videoId;
    } else if (this.props.video) {
      videoId = this.props.video.youtube_id;
    }
    if (!this.props.addDialog && this.props.video.ending == -2) {
      this.fetchDesc(this.props.video.id);
      var selectedItems = document.getElementsByClassName("selected-item");
      if (selectedItems) {
        for (let index = 0; index < selectedItems.length; index++) {
          selectedItems[index].classList.remove("selected-item");
        }
      }

      var elem = document.getElementById(this.props.video.id);
      if (elem != null) {
        elem.classList.add("selected-item");
      }
      this.setState({
        licks: [],
        thumbnail: "https://i.ytimg.com/vi/" + videoId + "/sddefault.jpg",
      });
      return;
    }
    this.setState({
      thumbnail: null,
    });
    // Determining whether the youtube controls should be on or off
    var YtControls = 0;
    if (!this.props.addDialog) {
      if (controlToggle) {
        if (this.state.controls) {
          YtControls = 0;
        } else {
          YtControls = 1;
        }
      } else {
        if (this.state.controls) {
          YtControls = 1;
        } else {
          YtControls = 0;
        }
      }
    } else {
      YtControls = 1;
    }

    if (videoId.includes("eo.com")) {
      try {
        this.setState(
          {
            videoId: videoId,
          },
          () => {
            let player = new VimeoInterface({
              videoId: videoId,
              onPlaybackRateChange: this.onPlaybackRateChange.bind(this),
              onPlayerStateChange: this.onPlayerStateChange.bind(this),
              onPlayerReady: this.onPlayerReady.bind(this),
              playerId: "player-" + videoId + " " + this.props.addDialog,
            });
            this.setState({
              selectedSegment: null,
              description: null,
              player: player,
              selected: -1,
              licks: null,
              attachments: [],
            });
          }
        );
      } catch (err) {
        console.log(err);
      }
    } else if (videoId.includes("box.com") || videoId.includes("view?usp")) {
      //alert("videoId: " + videoId);
      //this.props.displayMessage("info", "videoId: " + videoId);
      this.setState(
        {
          videoId: videoId,
        },
        () => {
          var player = document.getElementById(
            this.props.addDialog
              ? "video2" + this.state.videoId
              : "video1" + this.state.videoId
          );

          player.getAvailablePlaybackRates = () => {
            return [
              "0.25",
              "0.3",
              "0.35",
              "0.4",
              "0.45",
              "0.5",
              "0.6",
              "0.65",
              "0.7",
              "0.75",
              "0.8",
              "0.85",
              "0.9",
              "0.95",
              "1",
              "1.05",

              "1.2",
              "1.25",
              "1.5",
              "1.75",
              "2",
            ];
          };
          player.getPlayerState = () => {
            return player.paused ? 2 : 1;
          };
          player.getCurrentTime = () => {
            return player.currentTime;
          };
          player.seekTo = (value) => {
            player.currentTime = value;
          };
          player.destroy = () => {};
          player.getDuration = () => {
            return player.duration;
          };
          player.onloadeddata = (e) => {
            this.onPlayerReady(e);
          };
          player.onseeked = () => {
            if (!player.paused) {
              //this.props.displayMessage("info", "seeked");
              this.onPlayerStateChange({ data: 1 });
            }
          };
          player.onplay = () => {
            // this.props.displayMessage("info", "play");
            this.onPlayerStateChange({ data: 1 });
          };
          player.getPlaybackRate = () => {
            return player.playbackRate;
          };
          player.setPlaybackRate = (rate) => {
            player.playbackRate = rate;
            this.onPlaybackRateChange({ data: rate });
          };
          player.playVideo = () => {
            player.play();
          };
          player.pauseVideo = () => {
            player.pause();
          };
          player.onerror = (e) => {
            if (this.props.triggerError) {
              this.props.triggerError();
            }
          };
          player.onpause = () => {
            this.onPlayerStateChange({ data: 2 });
          };
          player.onplaying = () => {
            //this.props.displayMessage("info", "playing");
            //this.onPlayerStateChange({ data: 1 });
          };
          if (videoId.includes("box.com")) {
            player.src =
              "https://dl.dropboxusercontent.com" +
              (this.props.videoId
                ? this.props.videoId.split("dl=0")[0].slice(7)
                : this.state.videoId.split("dl=0")[0].slice(7)) +
              "?raw=1";
            console.log(player);
          } else {
            player.src =
              "https://drive.google.com/uc?export=download&id=" +
              (this.props.videoId
                ? this.props.videoId.split("/")[0]
                : this.state.videoId.split("/")[0]);
          }
          player.load();
          this.setState({
            selectedSegment: null,
            description: null,
            player: player,
            selected: -1,
            licks: null,
            attachments: [],
          });
        }
      );
    } else if (this.props.addDialog || (videoId && this.props.video.id != -3)) {
      if (window.YT) {
        this.setState(
          {
            videoId: videoId,
          },
          () => {
            var player = new window.YT.Player(
              "player-" + videoId + " " + this.props.addDialog,
              {
                playerVars: {
                  controls: YtControls,
                  modestbranding: 1,
                  playsinline: 1,
                },
                videoId: videoId,
                height: "1800",
                width: "1040",
                events: {
                  onPlaybackRateChange: this.onPlaybackRateChange.bind(this),
                  onStateChange: this.onPlayerStateChange.bind(this),
                  onError: this.onPlayerError.bind(this),
                  onReady: this.onPlayerReady.bind(this),
                },
              }
            );
            if (controlToggle) {
              this.setState({
                controls: !!YtControls,
                player: player,
              });
            } else this.isZoomed = false;
            this.setState({
              selectedSegment: null,
              description: null,
              player: player,
              selected: -1,
              maxTempId: 10,
              attachments: [],
            });
          }
        );
      }
    }
  }

  outOfBoundsCheck = (start, end) => {
    return this.state.min > start || this.state.max < end;
  };
  outOfVideoBoundsCheck = (value) => {
    return (
      value < this.state.sliderValue[0] - 1 || this.state.sliderValue[1] < value
    );
  };

  findCorrectSpeedChange = (increasing) => {
    var allSpeeds = [
      Number(0.25),
      Number(0.3),
      Number(0.35),
      Number(0.4),
      Number(0.45),
      Number(0.5),
      Number(0.55),
      Number(0.6),
      Number(0.65),
      Number(0.7),
      Number(0.75),
      Number(0.8),
      Number(0.85),
      Number(0.9),
      Number(0.95),
      Number(1),
      Number(1.05),
      Number(1.1),
      Number(1.15),
      Number(1.2),
      Number(1.25),
      Number(1.3),
      Number(1.35),
      Number(1.4),
      Number(1.45),
      Number(1.5),
      Number(1.55),
      Number(1.6),
      Number(1.65),
      Number(1.7),
      Number(1.75),
      Number(1.8),
      Number(1.85),
      Number(1.9),
      Number(1.95),
      Number(2),
    ];

    var speed = Number(this.player.getPlaybackRate());

    var index = allSpeeds.indexOf(speed);
    if (increasing) {
      return allSpeeds[index + 1];
    } else {
      return allSpeeds[index - 1];
    }
  };

  increaseSpeed = () => {
    var newValue = this.findCorrectSpeedChange(
      true,
      Number(this.player.getPlaybackRate())
    );
    this.player.setPlaybackRate(Number(newValue));
  };

  decreaseSpeed = () => {
    var newValue = this.findCorrectSpeedChange(
      false,
      Number(this.player.getPlaybackRate())
    );
    this.player.setPlaybackRate(Number(newValue));
  };

  zoomChange = () => {
    if (this.props.videoId) {
      var zoomOutMin = 0;
      var zoomOutMax = this.player.getDuration();
    } else {
      var zoomOutMin = this.state.absMin;
      var zoomOutMax = this.state.absMax;
    }
    this.setState({ isZooming: true });
    this.timeoutId = setTimeout(
      function () {
        this.setState({ isZooming: false });
      }.bind(this),
      300
    );
    if (!this.isZoomed) {
      this.isZoomed = !this.isZoomed;
      this.setState({
        thumbWidth:
          (this.state.sliderValue[1] - this.state.sliderValue[0]) / 70,
        max: this.state.sliderValue[1],
        min: this.state.sliderValue[0],
        progressBarValue: this.progressBarCalc(
          this.state.progressValue,
          this.state.sliderValue[0],
          this.state.sliderValue[1]
        ),
      });
    } else {
      this.isZoomed = !this.isZoomed;
      this.setState({
        thumbWidth: (zoomOutMax - zoomOutMin) / 70,
        max: zoomOutMax,
        min: zoomOutMin,
        progressBarValue: this.progressBarCalc(
          this.state.progressValue,
          zoomOutMin,
          zoomOutMax
        ),
      });
    }
  };
  oneBack = () => {
    const newValue = this.state.progressValue - 1;
    if (this.state.absMin > newValue) {
      return;
    }
    if (this.state.min > newValue) {
      if (this.isZoomed) {
        this.zoomChange();
      }
    }
    this.stopIntervals();
    this.stopLoops();
    this.player.seekTo(newValue);
    if (newValue < this.state.sliderValue[0]) {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
        sliderValue: [newValue, this.state.sliderValue[1]],
      });
    } else {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
      });
    }
    this.intervalLock = false;
    this.setSegmentChanges();
  };
  oneForward = () => {
    const newValue = this.state.progressValue + 1;
    if (this.state.absMax < newValue) {
      return;
    }
    if (this.state.max < newValue) {
      if (this.isZoomed) {
        this.zoomChange();
      }
    }
    if (this.state.max < newValue) {
    }
    this.stopIntervals();
    this.stopLoops();
    this.player.seekTo(newValue);
    if (newValue > this.state.sliderValue[1]) {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
        sliderValue: [this.state.sliderValue[0], this.state.max],
      });
    } else {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
      });
    }
    this.intervalLock = false;
    this.setSegmentChanges();
  };
  fiveBack = () => {
    const newValue = this.state.progressValue - 5;
    if (this.state.absMin > newValue) {
      return;
    }
    if (this.state.min > newValue) {
      if (this.isZoomed) {
        this.zoomChange();
      }
    }
    this.stopIntervals();
    this.stopLoops();
    this.player.seekTo(newValue);
    if (newValue < this.state.sliderValue[0]) {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
        sliderValue: [newValue, this.state.sliderValue[1]],
      });
    } else {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
      });
    }
    this.intervalLock = false;
    this.setSegmentChanges();
  };
  fiveForward = () => {
    const newValue = this.state.progressValue + 5;
    if (this.state.absMax < newValue) {
      return;
    }
    if (this.state.max < newValue) {
      if (this.isZoomed) {
        this.zoomChange();
      }
    }
    if (this.state.max < newValue) {
    }
    this.stopIntervals();
    this.stopLoops();
    this.player.seekTo(newValue);
    if (newValue > this.state.sliderValue[1]) {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
        sliderValue: [this.state.sliderValue[0], this.state.max],
      });
    } else {
      this.setState({
        progressBarValue: this.progressBarCalc(newValue),
        progressValue: newValue,
      });
    }
    this.intervalLock = false;
    this.setSegmentChanges();
  };
  deleteSegment = (id) => {
    const newList = this.state.licks;
    var removeIndex = newList.map((item) => item.id).indexOf(id);
    removeIndex >= 0 && newList.splice(removeIndex, 1);

    this.setState({
      selected: this.state.selected === id ? -1 : this.state.selected,
      playerReady: true,
      licks: newList,
    });
    if (!this.props.landing) {
      this.props.getToken().then((idToken) => {
        if (this.props.video) {
          axios
            .delete(
              process.env.REACT_APP_RESOURCE_SERVER + "/api/segments/" + id,
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then((response) => {});
        }
      });
    }
  };

  deleteAttachment = (id) => {
    const newList = this.state.attachments;
    var removeIndex = newList.map((item) => item.id).indexOf(id);
    removeIndex >= 0 && newList.splice(removeIndex, 1);

    this.setState({
      attachments: newList,
    });
    this.props.getToken().then((idToken) => {
      if (this.props.video) {
        axios.delete(
          process.env.REACT_APP_RESOURCE_SERVER + "/api/attachments/" + id,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      }
    });
  };

  changeSpeed = (event) => {
    this.player.setPlaybackRate(Number(event.target.value));
  };

  sliderResetCheck = (startValue, endValue) => {
    if (startValue === 0 && endValue >= this.player.getDuration()) {
      this.isSliderReset = true;
    } else {
      this.isSliderReset = false;
    }
  };

  handleSliderChange = (event, newValue) => {
    if (!this.movingThumb) {
      var progressBarChange = null;
      if (this.state.sliderValue[0] + this.state.thumbWidth < newValue[0]) {
        progressBarChange = newValue[0];
      } else if (
        this.state.sliderValue[1] - this.state.thumbWidth >
        newValue[1]
      ) {
        progressBarChange = newValue[1];
      }
      if (progressBarChange) {
        this.setState({
          progressValue: progressBarChange,
          progressBarValue: this.progressBarCalc(progressBarChange),
        });
        this.updateSegmentStatus(progressBarChange);
        if (this.player.getPlayerState() === 1) {
          this.searchingWhilePlaying = true;
        }
        this.player.seekTo(progressBarChange);
        return;
      }
    }
    this.movingThumb = true;
    this.pauseInterval = false;
    if (this.intervalLock) {
      if (
        !(
          this.intervalLock[0] < newValue[0] &&
          this.intervalLock[1] > newValue[1]
        )
      ) {
        this.intervalLock = null;
      }
    }
    if (this.props.addDialog && !this.props.landing) {
      this.props.setTrimValues(newValue);
    }
    this.sliderResetCheck(newValue[0], newValue[1]);
    if (this.state.selected !== -1) {
      this.isSegmentChanged = true;
    }

    this.pendingPlay = false;
    if (this.ingoreUpdate) {
      this.ingoreUpdate = false;
      return;
    }
    // if the first thumb is clicked and held
    if (this.state.sliderValue === newValue) {
      if (this.player.getPlayerState() === 1 && this.firstThumb()) {
        this.pendingPlay = true;
      }
      return;
    }

    // if the first value changed
    if (this.state.sliderValue[0] !== newValue[0]) {
      if (this.player.getPlayerState() === 1) {
        this.searchingWhilePlaying = true;
      }
      if (!this.intervalLock && !this.state.changingSegmentTime) {
        this.updateSegmentStatus(newValue[0]);
      }
      this.setState({
        playerReady: true,
        sliderValue: newValue,
        progressValue: newValue[0],
        progressBarValue: this.progressBarCalc(newValue[0]),
      });

      this.pauseInterval = false;
      this.player.seekTo(newValue[0]);
      return;
    }

    if (
      this.player.getPlayerState() !== 1 &&
      newValue[1] !== this.state.sliderValue[1]
    ) {
      this.player.seekTo(newValue[1]);
      this.ignoreStateChange = true;
      if (!this.intervalLock && !this.state.changingSegmentTime) {
        this.updateSegmentStatus(newValue[1]);
      }
      this.setState({
        playerReady: true,
        sliderValue: newValue,
        progressBarValue: this.progressBarCalc(),
      });
      return;
    } else {
      this.stopLoops();
      this.stopSegmentChanges();
      this.setSegmentChanges();
      var timeVal =
        (newValue[1] - this.player.getCurrentTime()) /
        this.player.getPlaybackRate();
      const player = this.player;
      const currLoop = this.loopState;
      this.loops.push(
        setTimeout(function () {
          if (currLoop) {
            player.seekTo(newValue[0]);
          }
        }, timeVal * 1000)
      );
      this.setState({
        playerReady: true,
        sliderValue: newValue,
        progressBarValue: this.progressBarCalc(),
      });
      return;
    }
  };

  handleChange(event) {
    this.setState({
      playerReady: true,
      newLickTitle: event.target.value,
    });
  }

  onPlaybackRateChange = (event) => {
    this.setState({
      speed: Number(event.data),
    });

    if (this.player.getPlayerState() === 1) {
      this.stopSegmentChanges();
      this.stopLoops();
      this.setSegmentChanges();
      var timeVal =
        (this.state.sliderValue[1] - this.player.getCurrentTime()) / event.data;
      const player = this.player;
      const startValue = this.state.sliderValue[0];
      const checked = this.state.loopChecked;
      this.loops.push(
        setTimeout(
          function () {
            if (!checked) {
              player.pauseVideo();
            }
            player.seekTo(startValue);
            if (!checked) {
              this.setState({
                playerReady: true,
                progressBarValue: this.progressBarCalc(startValue),
                progressValue: startValue,
              });
            }
          }.bind(this),
          timeVal * 1000
        )
      );
    }
  };

  onPlayerStateChange = (event) => {
    if (this.outOfVideoBoundsCheck(this.player.getCurrentTime())) {
      this.player.seekTo(this.state.sliderValue[0]);
    }
    switch (event.data) {
      // Playing
      case 1:
        if (this.ignoreStateChange) {
          // þráðurinn fer hingað og bufferar svo með ingore state change false
          this.ignoreStateChange = false;
          this.ignoreBuffering = true;

          this.player.seekTo(this.state.progressValue);
          return;
        }
        this.setState({
          isPlaying: true,
          playerReady: true,
        });
        if (this.pendingPlay) {
          return;
        }

        var timeVal;
        this.stopIntervals();
        this.stopLoops();
        this.stopSegmentChanges();
        this.setSegmentChanges();
        if (this.state.loopChecked) {
          timeVal =
            (this.state.sliderValue[1] - this.player.getCurrentTime()) /
            this.player.getPlaybackRate();
          if (this.player.getPlaybackRate() != this.state.speed) {
            this.setState({
              speed: this.player.getPlaybackRate(),
            });
          }
          if (timeVal > 0) {
            this.loops.push(
              setTimeout(
                function () {
                  this.player.seekTo(this.state.sliderValue[0]);
                  this.setState({
                    playerReady: true,
                    progressBarValue: this.progressBarCalc(
                      this.state.sliderValue[0]
                    ),
                    progressValue: this.state.sliderValue[0],
                  });
                }.bind(this),
                timeVal * 1000
              )
            );
          } else {
            this.ignoreBuffering = true;
          }
          //skoða þetta
          this.intervals.push(
            setInterval(
              function () {
                if (this.player) {
                  if (!this.searchingWhilePlaying) {
                    this.setState({
                      playerReady: true,
                      progressValue: this.player.getCurrentTime(),
                      progressBarValue: this.progressBarCalc(
                        this.player.getCurrentTime()
                      ),
                    });
                  }
                }
              }.bind(this),
              250
            )
          );
        } else {
          timeVal =
            (this.state.sliderValue[1] - this.player.getCurrentTime()) /
            this.player.getPlaybackRate();

          this.loops.push(
            setTimeout(
              function () {
                this.player.pauseVideo();
                this.player.seekTo(this.state.sliderValue[0]);
                this.setState({
                  playerReady: true,
                  progressBarValue: this.progressBarCalc(
                    this.state.sliderValue[0]
                  ),
                  progressValue: this.state.sliderValue[0],
                });
              }.bind(this),
              timeVal * 1000
            )
          );
          this.intervals.push(
            setInterval(
              function () {
                if (this.player) {
                  if (!this.searchingWhilePlaying) {
                    this.setState({
                      playerReady: true,
                      progressValue: this.player.getCurrentTime(),
                      progressBarValue: this.progressBarCalc(
                        this.player.getCurrentTime()
                      ),
                    });
                  }
                }
              }.bind(this),
              250
            )
          );
        }

        this.prevState = 1;
        break;
      // Pause
      case 2:
        if (this.ignoreStateChange) {
          return;
        }
        this.stopSegmentChanges();
        this.stopLoops();
        this.stopIntervals();
        this.setState({
          isPlaying: false,
          playerReady: true,
        });
        this.prevState = 2;
        break;
      // Buffering
      case 3:
        if (this.ignoreStateChange || this.ignoreBuffering) {
          this.ignoreBuffering = false;
          return;
        }
        if (this.pendingPlay) {
          this.setState({
            playerReady: true,
            progressValue: this.player.getCurrentTime(),
          });
        } else {
          this.setState({
            playerReady: true,
            //progressValue: this.state.sliderValue[0],
          });
        }
        this.prevstate = 3;
        break;
      default:
        return;
    }
  };

  handleSegmentClickAway(e) {
    if (
      !(
        e.target.id == "mobAddSeg" ||
        e.target.id == "mobAddSegIcon" ||
        this.segmentLock
      )
    ) {
      this.setState({
        isAddingNewSegment: false,
      });
    }
  }

  handleAddSegmentClick(event) {
    this.segmentLock = true;
    setTimeout(
      function () {
        this.segmentLock = false;
      }.bind(this),
      200
    );
    this.setState({ isAddingNewSegment: true });
  }

  intervalInputChange(startValue, endValue) {
    if (startValue !== this.state.sliderValue[0]) {
      this.setState({
        progressValue: startValue,
        progressBarValue: this.progressBarCalc(startValue),
      });
    }
    this.setState({
      sliderValue: [startValue, endValue],
    });
  }

  setStart() {
    var startValue = this.state.progressValue;
    var endValue = this.state.sliderValue[1];

    if (this.outOfBoundsCheck(startValue, endValue)) {
      this.zoomChange();
    }

    this.sliderResetCheck(startValue, endValue);

    if (this.state.selected !== -1) {
      this.isSegmentChanged = true;
    }

    this.ingoreUpdate = true;
    this.setState({
      sliderValue: [startValue, endValue],
      progressBarValue: this.progressBarCalc(this.player.getCurrentTime()),
    });
    if (this.props.addDialog && !this.props.landing) {
      this.props.setTrimValues([startValue, endValue]);
    }
  }

  setEnd() {
    if (this.intervalLock) {
      if (this.intervalLock[1] > this.state.progressValue) {
        this.intervalLock = null;
      }
    }
    var startValue = this.state.sliderValue[0];

    if (this.player.getPlayerState() === 2) {
      this.setState({
        sliderValue: [startValue, this.state.progressValue],
      });
      this.isSegmentChanged = true;
      return;
    }

    var endValue = this.player.getCurrentTime();

    if (this.outOfBoundsCheck(startValue, endValue)) {
      this.zoomChange();
    }

    this.sliderResetCheck(startValue, endValue);

    if (this.state.selected !== -1) {
      this.isSegmentChanged = true;
    }

    this.ingoreUpdate = true;
    this.setState({
      sliderValue: [startValue, endValue],
      progressBarValue: this.progressBarCalc(this.player.getCurrentTime()),
    });

    this.player.seekTo(this.state.sliderValue[0]);
    if (this.props.addDialog && !this.props.addDialog) {
      this.props.setTrimValues([startValue, endValue]);
    }
  }

  playVideo() {
    this.movingThumb = false;
    if (this.pendingPlay) {
      this.pendingPlay = false;
      this.player.seekTo(this.state.sliderValue[0]);
    } else if (this.searchingWhilePlaying) {
      setTimeout(
        function () {
          this.searchingWhilePlaying = false;
        }.bind(this),
        100
      );
    }
  }

  segStop() {
    this.player.pauseVideo();
    //this.ignoreStateChange = true
    this.player.seekTo(this.state.sliderValue[0]);
    setTimeout(
      function () {
        this.setState({
          playerReady: true,
          progressValue: this.state.sliderValue[0],
          progressBarValue: this.progressBarCalc(this.state.sliderValue[0]),
        });
      }.bind(this),
      250
    );
  }

  getShareLink() {
    this.props.getToken().then((idToken) => {
      if (!this.props.explore && !this.props.share) {
        axios
          .post(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/share/vid/" +
              this.props.video.id,
            {},
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((result) => {
            this.props.displayMessage("info", result.data.url, () =>
              navigator.clipboard.writeText(result.data.url)
            );
          });
      }
    });
  }

  lockModeToggle() {
    if (this.state.lockMode == false) {
      this.setState({
        lockMode: true,
      });
    } else {
      this.setState({
        lockMode: false,
      });
    }
  }

  toggleControls() {
    this.stopIntervals();
    this.stopSegmentChanges();
    this.stopLoops();
    this.state.player.destroy();
    this.setState(
      {
        speed: Number(1),
        isPlaying: false,
        player: null,
      },
      () => {
        this.updatePlayer(true);
      }
    );
  }

  firstThumb() {
    const thumbs = document.getElementsByClassName("MuiSlider-thumb");
    if (!this.lastMouseDown) {
      return false;
    }
    return (
      this.lastMouseDown.clientX < thumbs[0].getBoundingClientRect().right &&
      this.lastMouseDown.clientX > thumbs[0].getBoundingClientRect().left
    );
  }

  secondThumb() {
    const thumbs = document.getElementsByClassName("MuiSlider-thumb");
    if (!this.lastMouseDown) {
      return false;
    }
    return (
      this.lastMouseDown.clientX < thumbs[1].getBoundingClientRect().right &&
      this.lastMouseDown.clientX > thumbs[1].getBoundingClientRect().left
    );
  }

  segPlay(command) {
    if (this.player.getPlayerState() === 1) {
      this.player.pauseVideo();
    } else if (this.player.getPlayerState() === 2) {
      if (
        this.player.getCurrentTime() < this.state.sliderValue[0] ||
        this.player.getCurrentTime() > this.state.sliderValue[1]
      ) {
        this.player.seekTo(this.state.sliderValue[0]);
      }
      this.player.playVideo();
    } else if (this.player.getPlayerState() === 5) {
      this.player.seekTo(this.state.sliderValue[0]);
    }
  }

  progressBarCalc(currProgressValue, min, max) {
    //líklega updeitast min og max ekki í tæka tíð
    if (!currProgressValue) {
      currProgressValue = this.state.progressValue;
    }
    if (!max) {
      max = this.state.max;
    }
    if (!min && min != 0) {
      min = this.state.min;
    }
    if (currProgressValue < min) {
      return 0;
    } else if (currProgressValue > max) {
      return 100;
    } else {
      return ((currProgressValue - min) * 100) / (max - min);
    }
  }

  updateSegmentStatus(time) {
    if (!this.state.licks) {
      return;
    }
    for (let index = 0; index < this.state.licks.length; index++) {
      if (
        this.state.licks[index].start < time &&
        this.state.licks[index].ending > time
      ) {
        this.setState({
          selected: this.state.licks[index].tempId,
        });
        return;
      }
    }
    this.setState({
      selected: -1,
    });
  }

  setSegmentChanges() {
    if (!this.state.licks) {
      return;
    }
    for (let index = 0; index < this.state.licks.length; index++) {
      var timeVal1 =
        (this.state.licks[index].start - this.player.getCurrentTime()) /
        this.player.getPlaybackRate();
      var timeVal2 =
        (this.state.licks[index].ending - this.player.getCurrentTime()) /
          this.player.getPlaybackRate() +
        0.5;

      if (timeVal2 > 0) {
        this.segmentChanges.push(
          setTimeout(
            function () {
              if (this.state.selected == this.state.licks[index].tempId) {
                if (!this.intervalLock && !this.state.changingSegmentTime) {
                  this.setState({
                    selected: -1,
                  });
                }
              }
            }.bind(this),
            timeVal2 * 1000
          )
        );

        this.segmentChanges.push(
          setTimeout(
            function () {
              if (!this.intervalLock && !this.state.changingSegmentTime) {
                if (this.state.selected != this.state.licks[index].tempId)
                  this.setState({
                    selected: this.state.licks[index].tempId,
                  });
              }
            }.bind(this),
            timeVal1 * 1000
          )
        );
      }
    }
  }

  stopLoops() {
    for (var i = 0; i < this.loops.length; i++) {
      clearTimeout(this.loops[i]);
    }
  }
  stopSegmentChanges() {
    for (var i = 0; i < this.segmentChanges.length; i++) {
      clearTimeout(this.segmentChanges[i]);
    }
  }
  stopIntervals() {
    for (var i = 0; i < this.intervals.length; i++) {
      clearInterval(this.intervals[i]);
    }
  }

  //resetIntervalsAndTimeouts()

  // Update the start and end point on the slider
  update(start, ending, selected) {
    if (ending > this.state.absMax) {
      ending = this.state.absMax;
    }
    var segEnding = ending;
    if (this.state.lockMode) {
      this.intervalLock = [start - 0.3, ending + 0.3];
    } else {
      segEnding = this.state.max;
      this.intervalLock = null;
    }

    this.stopIntervals();
    if (this.state.isPlaying) {
      this.player.pauseVideo();
      setTimeout(
        function () {
          if (this.outOfBoundsCheck(start, segEnding)) {
            this.zoomChange();
          }
          this.player.seekTo(start);
          this.setState(
            {
              selected: selected,
              sliderValue: [start, segEnding],
              progressValue: start,
              progressBarValue: this.progressBarCalc(start),
            },
            () => {
              this.player.playVideo();
              this.setSegmentChanges();
            }
          );

          if (selected !== -1) {
            this.isSegmentChanged = false;
            //this.scrollToVideo();
            this.sliderResetCheck(start, segEnding);
          }
        }.bind(this),
        150
      );
      return;
    }
    if (this.outOfBoundsCheck(start, segEnding)) {
      this.zoomChange();
    }
    this.player.seekTo(start);

    this.setState({
      selected: selected,
      sliderValue: [start, segEnding],
      progressValue: start,
      progressBarValue: this.progressBarCalc(start),
    });

    if (selected !== -1) {
      this.isSegmentChanged = false;
      //this.scrollToVideo();
      this.sliderResetCheck(start, segEnding);
    }
  }

  handleExitSegment() {
    if (this.isZoomed) {
      this.zoomChange();
    }
    this.intervalLock = null;
    this.setState({
      changingSegmentTime: false,
      sliderValue: [this.state.absMin, this.state.absMax],
    });

    //this.update(this.state.min, this.state.max, -1);
    this.sliderResetCheck(this.state.min, this.state.max);
    return;
  }

  moveAhead(start) {
    this.player.seekTo(start - 0.7);
    this.intervalLock = null;
    if (this.isZoomed) {
      this.zoomChange();
    }
    this.stopLoops();
    this.stopSegmentChanges();
    this.stopIntervals();
    this.setState(
      {
        sliderValue: [start - 0.7, this.state.absMax],
        progressValue: start,
        progressBarValue: this.progressBarCalc(start),
        selected: -1,
      },
      () => {
        this.setSegmentChanges();
      }
    );
  }

  addNewSegment(start, ending, name) {
    var licks = this.state.licks;
    var segmentId = this.state.segmentCount;
    if (this.props.video) {
      var body = {
        tempId: this.state.maxTempId + 1,
        start: start,
        ending: ending,
        name: dbFormat(name),
        note: "",
        isMastered: false,
        new: false,
        edit: false,
      };
      const refId = this.state.maxTempId + 1;
      const newLicks = [
        ...licks,
        {
          tempId: refId,
          name: name,
          start: start,
          ending: ending,
          note: "",
          mastered: 0,
          new: false,
          edit: false,
        },
      ];
      this.setState(
        {
          licks: newLicks,
          isAddingNewSegment: false,
          maxTempId: refId,
          //segmentCount: this.state.segmentCount + 1,
        },
        () => {
          this.update(start, ending, refId);
          this.sortSegments();
          this.props.displayMessage(
            "info",
            this.props.t("newSegmentAdded"),
            () => this.moveAhead(ending)
          );
        }
      );
      this.props.getToken().then((idToken) => {
        axios
          .post(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/videos/" +
              this.props.video.id +
              "/segments",
            body,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            const newLickIndex = this.state.licks.findIndex(
              (element) => element.tempId == refId
            );
            var newLicks = this.state.licks;
            newLicks[newLickIndex].id = response.data.id;
            this.setState({
              licks: [...newLicks],
            });
          })
          .catch((error) => {});
      });
    } else {
      const tempLicks = [
        ...licks,
        {
          id: segmentId,
          start: start,
          ending: ending,
          name: name,
          note: "",
          mastered: 0,
          new: false,
          edit: false,
        },
      ];
      this.setState(
        {
          licks: tempLicks,
          isAddingNewSegment: false,
          segmentCount: this.state.segmentCount + 1,
        },
        () => {
          this.update(start, ending, segmentId);
          this.sortSegments();
        }
      );
    }
  }

  mouseVideoLeave() {
    this.vidHovered = true;
  }

  changeSegmentTime() {
    this.props.getToken().then((idToken) => {
      var newLicks = this.state.licks;
      var index = newLicks.findIndex((x) => x.tempId === this.state.selected);
      const element = newLicks[index];
      newLicks[index].start = this.state.sliderValue[0];
      newLicks[index].ending = this.state.sliderValue[1];
      this.isSegmentChanged = false;
      this.setState({
        playerReady: true,
        licks: newLicks,
      });

      if (this.props.video) {
        var body = {
          name: dbFormat(element.name),
          note: dbFormat(element.note),
          start: this.state.sliderValue[0],
          ending: this.state.sliderValue[1],
          mastered: element.mastered,
        };
        axios
          .put(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/segments/" +
              element.id,
            body,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then(() => {
            this.props.displayMessage(
              "info",
              'Breytingar á tíma vistaðar fyrir "' + element.name + '"',
              () => {
                this.moveAhead(this.state.sliderValue[1]);
              }
            );
            this.intervalLock = [
              this.state.sliderValue[0] - 0.3,
              this.state.sliderValue[1] + 0.3,
            ];
            this.handleChangeSegmentTime(false);
            this.sortSegments();
          })
          .catch((error) => {});
      }
    });
  }

  changeMastered = (mastered, id) => {
    if (!this.props.landing) {
      this.props.getToken().then((idToken) => {
        if (!id) {
          return;
        }
        var newLicks = this.state.licks;
        var index = newLicks.findIndex((seg) => seg.id === id);
        newLicks[index].mastered = mastered;
        this.setState({ licks: newLicks });
        var dbVal = 0;
        if (mastered) {
          dbVal = 1;
        }
        axios
          .put(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/segments/" +
              id +
              "/mastered",
            {
              value: dbVal,
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .catch((error) => {});
      });
    } else {
      var newLicks = this.state.licks;
      var index = newLicks.findIndex((seg) => seg.tempId === id);
      newLicks[index].mastered = mastered;
      this.setState({ licks: newLicks });
    }
  };

  changeSegName = (newName, id) => {
    if (!this.props.landing) {
      this.props.getToken().then((idToken) => {
        if (!id) {
          return;
        }
        var newLicks = this.state.licks;
        var index = newLicks.findIndex((seg) => seg.id === id);
        const element = newLicks[index];
        newLicks[index].edit = false;
        newLicks[index].new = false;
        newLicks[index].name = newName;
        this.setState({
          playerReady: true,
          licks: newLicks,
        });
        if (this.props.video) {
          var body = {
            name: dbFormat(newName),
            note: dbFormat(element.note),
            start: element.start,
            ending: element.ending,
            mastered: element.mastered,
          };
          axios
            .put(
              process.env.REACT_APP_RESOURCE_SERVER + "/api/segments/" + id,
              body,
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then(() => this.sortSegments())
            .catch((error) => {});
        }
      });
    } else {
    }
  };

  changeSegmentNote(newNote, id) {
    if (this.state.licks && !this.props.landing) {
      this.props.getToken().then((idToken) => {
        if (!id) {
          return;
        }
        if (this.props.video) {
          var body = {
            note: dbFormat(newNote),
          };
          axios
            .put(
              process.env.REACT_APP_RESOURCE_SERVER + "/api/segments/" + id,
              body,
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then(() => {
              if (this.state.licks) {
                var newLicks = this.state.licks;
                var index = newLicks.findIndex((seg) => seg.id === id);
                if (index != -1) {
                  newLicks[index].note = newNote;
                  this.setState({
                    playerReady: true,
                    licks: newLicks,
                  });
                }
              }
              this.props.displayMessage("success", "Breytingar vistaðar");
            })
            .catch((error) => {});
        }
      });
    } else {
      if (this.state.licks) {
        var newLicks = this.state.licks;
        var index = newLicks.findIndex((seg) => seg.tempId === id);
        if (index != -1) {
          newLicks[index].note = newNote;
          this.setState({
            playerReady: true,
            licks: newLicks,
          });
        }
      }
    }
  }

  htmlDecode(input) {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  onPlayerReady(event) {
    if (this.props.video) {
      //setSta
      this.fetchDesc(this.props.video.id);
      var selectedItems = document.getElementsByClassName("selected-item");
      if (selectedItems) {
        for (let index = 0; index < selectedItems.length; index++) {
          selectedItems[index].classList.remove("selected-item");
        }
      }

      var elem = document.getElementById(this.props.video.id);
      if (elem != null) {
        elem.classList.add("selected-item");
      }
    }

    window["onmousedown"] = (e) => {
      this.lastMouseDown = e;
    };

    window["onkeydown"] = (e) => {
      if (!e) {
        return;
      }

      if (!e.key || e.key[0] === "F") {
        return;
      }

      if (
        document.activeElement.type == "text" ||
        document.activeElement.type == "number" ||
        document.activeElement.tagName == "TEXTAREA" ||
        document.activeElement.tagName == "INPUT" ||
        document.activeElement.type == "search" ||
        document.activeElement.type == "input"
      ) {
        return;
      }
      // space bar
      if (e.which == 32) {
        if (this.player.getPlayerState() == 1) {
          this.segStop();
        } else {
          this.segPlay();
        }
        e.preventDefault();
      } else if (e.key === "o") {
        this.getShareLink();
      } else if (e.key === "c") {
        document.getElementsByClassName("MuiSlider-thumb")[0].focus();
      } else if (e.key === "r") {
        this.triggerFullscreen();
        e.preventDefault();
      } else if (e.key == "n") {
        this.handleAddSegmentClick();
        e.preventDefault();
      } else if (e.key == "p") {
        this.segPlay();
        e.preventDefault();
      } else if (e.key == "s") {
        this.setStart();
        e.preventDefault();
      } else if (e.key == "e") {
        this.setEnd();
        e.preventDefault();
      } else if (e.key == "ArrowRight") {
        this.fiveForward();
        e.preventDefault();
      } else if (e.key == "ArrowLeft") {
        this.fiveBack();
        e.preventDefault();
      } else if (e.key == "j") {
        this.oneForward();
        e.preventDefault();
      } else if (e.key == "h") {
        this.oneBack();
        e.preventDefault();
      } else if (e.key == "ArrowUp") {
        this.increaseSpeed();
        e.preventDefault();
      } else if (e.key == "ArrowDown") {
        this.decreaseSpeed();
        e.preventDefault();
      } else if (e.key == "z") {
        this.zoomChange();
        e.preventDefault();
      } else if (e.key == "k") {
        if (this.player.getPlayerState() == 1) {
          this.segPlay();
        }
        this.setState({
          sliderValue: [this.state.sliderValue[0], this.state.max],
        });
        e.preventDefault();
      }
    };

    window["ontouchstart"] = (e) => {
      this.lastMouseDown = e;
    };

    window["ontouchend"] = (e) => {
      this.playVideo();
    };

    window["onmouseup"] = (e) => {
      this.playVideo();
    };

    this.player = event.target;
    // Get segments from database

    if (this.props.video && !this.props.addDialog && !this.props.share) {
      this.setState({
        loadingSegments: true,
      });
      this.props
        .getToken(this.props.explore ? "explore" : false)
        .then((idToken) => {
          axios
            .get(
              process.env.REACT_APP_RESOURCE_SERVER +
                "/api/videos/" +
                this.props.video.id +
                "/segments" +
                (this.props.student ? "/student" : "/personal"),
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then((response) => {
              var maxTempId = 0;
              for (let index = 0; index < response.data.length; index++) {
                if (response.data[index].note === null) {
                  response.data[index].note = "";
                }
                response.data[index].tempId = index;
                maxTempId = index;
              }
              this.setState({
                maxTempId: maxTempId,
                player: event.target,
                playerReady: true,
                licks: response.data,
                loadingSegments: false,
              });
            })
            .then(() => this.sortSegments())
            .catch((error) => {
              "";
            });
          axios
            .get(
              process.env.REACT_APP_RESOURCE_SERVER +
                "/api/videos/" +
                this.props.video.id +
                "/attachments" +
                (this.props.student ? "/student" : "/personal"),
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then((response) => {
              this.setState({
                attachments: [...response.data],
              });
            })
            .catch((error) => {
              "";
            });
        });
    }
    if (this.props.share) {
      var maxTempId = 0;
      for (let index = 0; index < this.props.video.segments.length; index++) {
        if (this.props.video.segments[index].note === null) {
          this.props.video.segments[index].note = "";
        }
        this.props.video.segments[index].tempId = index;
        maxTempId = index;
      }
      this.setState(
        {
          maxTempId: maxTempId,
          player: event.target,
          playerReady: true,
          licks: this.props.video.segments,
          loadingSegments: false,
        },
        () => this.sortSegments()
      );
    }
    if (this.state.videoId.includes("eo.com") && this.props.setTrimValues) {
      this.props.setTrimValues([
        0,
        this.player.getDuration() == 0 ? -2 : this.player.getDuration(),
        new DOMParser().parseFromString(
          this.player.player.element.getAttribute("title"),
          "text/html"
        ).documentElement.textContent,
      ]);
    } else if (this.props.setTrimValues) {
      this.props.setTrimValues([
        0,
        this.player.getDuration() == 0 ? -2 : this.player.getDuration(),
      ]);
    }
    if (this.props.videoId) {
      this.setState({
        absMax: this.player.getDuration(),
        absMin: 0,
        thumbWidth: this.player.getDuration() / 70,
        max: this.player.getDuration(),
        min: 0,
        speed: Number(1),
        speeds: this.player.getAvailablePlaybackRates(),
        isPlaying: false,
        playerReady: true,
        sliderValue: [0, this.player.getDuration()],
        isZoomed: false,
      });
    } else {
      this.setState({
        speeds: this.player.getAvailablePlaybackRates(),
        playerReady: true,
      });
    }
  }

  //utility
  onPlayerError(event) {
    switch (event.data) {
      case 2:
        break;
      case 100:
        break;
      case 101 || 150:
        break;
      default:
    }
  }

  changeSelectedSortingOpt(index) {
    this.setState(
      {
        selectedSegSortingOpt: index,
      },
      () => this.sortSegments()
    );
  }

  triggerFullscreen() {
    if (!this.state.fullscreen) {
      this.props.setDrawerOpen(false);
    } else {
      this.props.setDrawerOpen(true);
    }
    this.setState({
      fullscreen: this.state.fullscreen ? false : true,
    });
  }

  sortSegments() {
    var segments = this.state.licks;
    switch (this.state.selectedSegSortingOpt) {
      //Title
      case 0:
        this.setState({
          licks: segments.sort(dynamicSort("name")),
        });
        break;
      //Timestamp
      case 1:
        this.setState({
          licks: segments.sort(dynamicSort("start")),
        });
        break;
      default:
        break;
    }
  }

  toggleLoop(e) {
    this.setState({
      loopChecked: e.target.checked,
    });

    if (this.player.getPlayerState() === 1) {
      this.stopSegmentChanges();
      this.stopLoops();
      this.setSegmentChanges();
      var timeVal =
        (this.state.sliderValue[1] - this.player.getCurrentTime()) /
        this.player.getPlaybackRate();
      const player = this.player;
      const startValue = this.state.sliderValue[0];
      const checked = e.target.checked;
      this.loops.push(
        setTimeout(
          function () {
            if (!checked) {
              player.pauseVideo();
            }
            player.seekTo(startValue);
            if (!checked) {
              this.setState({
                playerReady: true,
                progressBarValue: this.progressBarCalc(
                  this.state.sliderValue[0]
                ),
                progressValue: this.state.sliderValue[0],
              });
            }
          }.bind(this),
          timeVal * 1000
        )
      );
    }
  }

  render() {
    var selectedSegment =
      this.state.selected === -1
        ? null
        : this.state.licks.find((x) => x.tempId === this.state.selected);
    return (
      <Grid
        container
        justify="space-between"
        className={this.state.fullscreen ? "fullsc" : ""}
      >
        {/*<SpotPlayer></SpotPlayer>-->*/}
        {!this.props.addDialog && this.props.video && (
          <React.Fragment>
            {this.props.video.id != -3 && (
              <p className="video-sub-collection-name">
                {this.props.currSubCollection ? (
                  <>
                    <span className="collection">
                      {this.props.collectionName} /{" "}
                    </span>
                    {this.props.currSubCollection}
                  </>
                ) : (
                  this.props.collectionName
                )}
                {this.props.teacher && !this.props.explore && (
                  <Tooltip title={"Share Link"} arrow>
                    <PaginationBtn
                      style={{
                        marginTop: "-15px",
                        float: "right",
                        width: "auto",
                      }}
                      onClick={() => this.getShareLink()}
                    >
                      <ShareIcon></ShareIcon>
                    </PaginationBtn>
                  </Tooltip>
                )}
                {this.props.explore && (
                  <SaveColButton
                    setOpenSignIn={this.props.setOpenSignIn}
                    video={this.props.video}
                    segments={this.state.licks}
                  ></SaveColButton>
                )}
                {!isMobileOnly && (
                  <Tooltip title={this.props.t("focusMode")} arrow>
                    <PaginationBtn
                      style={{
                        marginTop: "-15px",
                        float: "right",
                        width: "auto",
                      }}
                      className="fullscreen-button"
                      onClick={() => this.triggerFullscreen()}
                    >
                      {!this.state.fullscreen ? (
                        <FullscreenIcon />
                      ) : (
                        <FullscreenExitIcon />
                      )}
                    </PaginationBtn>
                  </Tooltip>
                )}
              </p>
            )}

            {this.props.video.id == -3 && <Skeleton width="40%" height={20} />}
            <Grid item md={10} sm={9} xs={8}>
              {this.props.video.id != -3 && (
                <h2 className="video-name">{this.props.video.name}</h2>
              )}
              {this.props.video.id == -3 && (
                <Skeleton width="100%" height={40} />
              )}
            </Grid>
            <Grid item md={2} sm={3} xs={4}>
              <Grid
                className="backnforth"
                container
                justify="flex-end"
                alignItems="flex-start"
              >
                <PaginationBtn
                  disabled={
                    this.props.browseVids == "none" ||
                    this.props.browseVids == "next" ||
                    !this.state.licks
                  }
                  onClick={() => this.props.setBrowseVids("back")}
                >
                  <ArrowBackIosIcon />
                </PaginationBtn>
                <PaginationBtn
                  disabled={
                    this.props.browseVids == "none" ||
                    this.props.browseVids == "prev" ||
                    !this.state.licks
                  }
                  onClick={() => this.props.setBrowseVids("forward")}
                >
                  <ArrowForwardIosIcon />
                </PaginationBtn>
              </Grid>
            </Grid>

            {this.state.description && this.props.video.id != -3 && (
              <Grid item md={12} sm={12}>
                <p className="video-description">{this.state.description}</p>
              </Grid>
            )}
            {this.props.video.description &&
              this.props.video.id != -3 &&
              this.props.video.description != "null" &&
              this.props.video.description != "undefined" &&
              !this.state.description && (
                <Grid item md={12} sm={12}>
                  <p className="video-description">
                    {this.props.video.description}
                  </p>
                </Grid>
              )}
          </React.Fragment>
        )}
        <Grid
          item
          lg={this.props.addDialog && !this.props.landing ? 12 : 9}
          md={this.props.addDialog && !this.props.landing ? 12 : 9}
          sm={isMobileOnly ? 9 : 12}
          xs={12}
          className={this.props.addDialog ? "add-new-video" : ""}
        >
          <div
            onMouseLeave={() => (this.vidHovered = false)}
            onMouseEnter={() => (this.vidHovered = true)}
            id="vid-frame"
            className={
              "video-frame" +
              (!this.props.addDialog &&
              this.props.video.id != -3 &&
              selectedSegment
                ? " selected"
                : "") +
              (this.props.addDialog ? " new-video" : "") +
              (this.state.fullscreen ? " fullscreen-frame" : "") +
              (isMobileOnly ? " mobile-frame" : "")
            }
            ref={this.videoRef}
          >
            <div className="segment-name">
              <Typography variant="h2" component="h2">
                {!this.props.addDialog &&
                selectedSegment &&
                this.props.video.id != -3
                  ? selectedSegment.name
                  : ""}
              </Typography>
            </div>
            <div className="exit-segment-btn">
              {!this.props.addDialog &&
              this.intervalLock &&
              selectedSegment &&
              this.props.video.id ? (
                <Button onClick={this.handleExitSegment}>
                  {this.props.t("closeSegment")} <CloseIcon fontSize="small" />
                </Button>
              ) : null}
            </div>
            <div className="video-container">
              {!this.state.playerReady && !this.state.thumbnail && (
                <div
                  className="video-skeleton"
                  style={{ position: "absolute" }}
                >
                  <Skeleton variant="rect" width="60em" height={535} />
                  {/* <Skeleton width="60em" /> */}
                  {/*<Skeleton variant="circle" width={50} height={50}></Skeleton>*/}
                  {/*<Skeleton variant="circle" width={50} height={50}></Skeleton>*/}
                </div>
              )}
              {this.state.thumbnail && (
                <Button
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/watch?v=" +
                        this.props.video.youtube_id,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <img style={{ width: "100%" }} src={this.state.thumbnail} />
                </Button>
              )}

              <div
                id={"player-" + this.state.videoId + " " + this.props.addDialog}
              >
                {this.state.videoId &&
                  (this.state.videoId.includes("box.com") ||
                    this.state.videoId.includes("view?usp")) && (
                    <video
                      playsinline
                      id={
                        this.props.addDialog
                          ? "video2" + this.state.videoId
                          : "video1" + this.state.videoId
                      }
                    >
                      <source type="video/mp4" /> Your browser does not support
                      HTML video.
                    </video>
                  )}
              </div>
            </div>
            {!this.state.controls &&
              this.player &&
              this.vidHovered &&
              !this.props.addDialog && (
                <VolumeSlider player={this.player}></VolumeSlider>
              )}
          </div>

          {/* ADD NEW VIDEO TRIM CONTROLS */}
          {this.state.playerReady && this.props.addDialog && (
            <Collapse in={this.props.trimmingVideo}>
              <div className="add-new-video-controls">
                <div
                  className={
                    "player-slider" +
                    (this.isZoomed ? " zoomed" : "") +
                    (this.state.isZooming ? " zooming" : "")
                  }
                >
                  <VideoProgress
                    style={{
                      marginBottom: "-8px",
                      position: "relative",
                      width: "calc(100% - 24px)",
                    }}
                    className="progress-bar"
                    variant="determinate"
                    value={this.state.progressBarValue}
                  />
                  <SegmentSlider
                    max={this.state.max}
                    min={this.state.min}
                    valueLabelFormat={(x) => {
                      return timeToString(x);
                    }}
                    step={0.01}
                    value={this.state.sliderValue}
                    onChange={this.handleSliderChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />
                </div>
                <div className="control-container-top">
                  <div className="play-control">
                    <PlayButton
                      onClick={(event) => this.segPlay(event)}
                      id="play"
                      color="primary"
                    >
                      {this.state.isPlaying ? (
                        <PauseSymbol></PauseSymbol>
                      ) : (
                        <PlaySymbol></PlaySymbol>
                      )}
                    </PlayButton>
                    {/*<StopButton onClick={() => this.segStop()}>
                      <StopSymbol></StopSymbol>
                    </StopButton>
                      */}
                  </div>
                  <div className="trim-control">
                    <StdButton
                      disabled={
                        this.state.sliderValue[0] == this.state.progressValue ||
                        this.state.sliderValue[1] == this.state.progressValue
                      }
                      size="small"
                      variant="contained"
                      onClick={() => this.setStart()}
                    >
                      {this.props.t("setStart")}
                    </StdButton>
                    <StdButton
                      disabled={
                        this.state.sliderValue[0] == this.state.progressValue ||
                        this.state.sliderValue[1] == this.state.progressValue
                      }
                      size="small"
                      variant="contained"
                      onClick={() => this.setEnd()}
                    >
                      {this.props.t("setStart")}
                    </StdButton>
                  </div>
                  <div> {/* for layout */} </div>
                </div>
                <div className="control-container-bottom">
                  <div className="interval-control">
                    <IntervalDisplay
                      style
                      showFrom={true}
                      start={this.state.sliderValue[0]}
                      end={this.state.sliderValue[1]}
                      max={this.state.max}
                      min={this.state.min}
                      handleChange={this.intervalInputChange}
                    />
                    {/*<ZoomButton
                      onClick={this.zoomChange}
                      color="primary"
                      aria-label="Zoom in slider"
                      component="span"
                      disabled={this.isSliderReset}
                    >
                      {this.isZoomed ? (
                        <ZoomOutIcon style={{ color: "#605df9" }} />
                      ) : (
                        <ZoomInIcon
                          style={
                            this.isSliderReset
                              ? { color: "rgba(255, 255, 255, 0.3)" }
                              : { color: "#605df9" }
                          }
                        />
                      )}
                        </ZoomButton>*/}
                  </div>
                </div>
              </div>
            </Collapse>
          )}
          {/* END OF -- ADD NEW VIDEO TRIM CONTROLS */}

          {/* Teacher Player */}
          {this.state.playerReady && !this.props.addDialog && (
            <div
              id="fixed-controls"
              className={
                "fixed-video-control" +
                (this.props.drawerOpen ? " drawer-open" : "") +
                (this.state.fullscreen ? " fullscreen-controls" : "") +
                (isMobileOnly ? " mobile-con" : "") +
                (this.state.isAddingNewSegment ? " is-adding" : "")
              }
            >
              <div
                style={{ position: "relative" }}
                className={
                  "player-slider" +
                  (this.isZoomed ? " zoomed" : "") +
                  (this.state.isZooming ? " zooming" : "")
                }
              >
                <VideoProgress
                  className="progress-bar"
                  variant="determinate"
                  value={this.state.progressBarValue}
                />
                <SegmentSlider
                  max={this.state.max}
                  min={this.state.min}
                  valueLabelFormat={(x) => {
                    return timeToString(x - this.state.absMin);
                  }}
                  step={0.01}
                  value={this.state.sliderValue}
                  onChange={this.handleSliderChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
              </div>

              <Grid
                container
                justify="space-between"
                alignItems="center"
                className="player-toolbar"
              >
                <Grid item className="left-control-container">
                  <PlayButton onClick={this.segPlay} id="play" color="primary">
                    {this.state.isPlaying ? (
                      <PauseSymbol></PauseSymbol>
                    ) : (
                      <PlaySymbol></PlaySymbol>
                    )}
                  </PlayButton>
                  <StopButton onClick={() => this.segStop()}>
                    <StopSymbol></StopSymbol>
                  </StopButton>
                  <VideoSettings
                    toggleYTControls={this.toggleControls}
                    YTControlsOn={this.state.controls}
                  />
                </Grid>

                {/* <Grid
                    item
                    alignContent="flex-end"
                    className="right-control-container"
                  > */}
                <Grid item>
                  <Grid container alignItems="center">
                    <IconButton onClick={this.fiveBack}>
                      <Replay5Icon></Replay5Icon>
                    </IconButton>
                    <SpeedControls
                      ytControls={this.state.controls}
                      increase={this.increaseSpeed}
                      decrease={this.decreaseSpeed}
                      changeSpeed={this.changeSpeed}
                      speeds={this.state.speeds}
                      speed={this.state.speed}
                    />

                    <StdButton
                      disabled={
                        this.state.sliderValue[0] == this.state.progressValue ||
                        this.state.sliderValue[1] == this.state.progressValue
                      }
                      className="control-text-btn"
                      variant="contained"
                      onClick={() => this.setStart()}
                    >
                      {!isMobileOnly && this.props.t("set") + " "}
                      {this.props.t("start")}
                    </StdButton>
                    <StdButton
                      disabled={
                        this.state.sliderValue[0] == this.state.progressValue ||
                        this.state.sliderValue[1] == this.state.progressValue
                      }
                      className="control-text-btn"
                      variant="contained"
                      onClick={() => this.setEnd()}
                    >
                      {!isMobileOnly && this.props.t("set") + " "}
                      {this.props.t("end")}
                    </StdButton>

                    <label htmlFor="icon-button-file">
                      {this.player && (
                        <ZoomButton
                          onClick={this.zoomChange}
                          color="primary"
                          className="zoom-btn"
                          aria-label="zoom"
                          component="span"
                          disabled={this.isSliderReset}
                        >
                          {this.isZoomed ? (
                            <span
                              className="zoom-out-icon"
                              style={{ color: "#605df9" }}
                            />
                          ) : (
                            <span className="zoom-in-icon" />
                          )}
                        </ZoomButton>
                      )}
                    </label>
                    <LooperSwitch
                      loopChecked={this.state.loopChecked}
                      toggleLoop={this.toggleLoop}
                    />
                    <IconButton onClick={this.fiveForward}>
                      <Forward5Icon></Forward5Icon>
                    </IconButton>
                  </Grid>
                </Grid>
                {!this.state.changingSegmentTime && (
                  <div
                    ref={this.addSegmentRef}
                    className="control-add-btn-container"
                  ></div>
                )}
                {selectedSegment && this.state.changingSegmentTime && (
                  <StdButton
                    className="save-seg-btn control-text-btn"
                    size="small"
                    // style={{
                    //   padding: "5px 13px !important",
                    // }}
                    disabled={!this.isSegmentChanged}
                    onClick={() => this.changeSegmentTime()}
                  >
                    <SaveIcon /> Vista
                  </StdButton>
                )}

                {/* </Grid> */}
              </Grid>

              {/* <Grid item md={6}>
                  <StdButton
                    onClick={this.toggleControls}
                    className="YT-text-btn"
                  >
                    YT spilari:
                    {this.state.controls ? <b> Kveikt</b> : <b> Slökkt</b>}
                  </StdButton>
                  <Tooltip title="Býður uppá ýtarlegri hraðamöguleika" arrow>
                    <InfoOutlinedIcon
                      Filled
                      color="primary"
                      style={{ marginBottom: "-3px", fontSize: "15px" }}
                    ></InfoOutlinedIcon>
                  </Tooltip>
                </Grid> */}

              <Grid item md={12}>
                {this.props.video && (
                  <Typography
                    variant="body1"
                    component="p"
                    className="progress-value"
                    style={{ fontSize: "12px", marginLeft: "0px" }}
                  >
                    {timeToString(
                      this.state.progressValue - this.state.absMin
                    ) +
                      " / " +
                      timeToString(this.state.absMax - this.state.absMin)}{" "}
                  </Typography>
                )}
              </Grid>
            </div>
          )}
        </Grid>
        {/* End of teacher controls */}

        {/* Mobile controls */}
        {this.state.playerReady && isMobileOnly && !this.props.addDialog && (
          <div className="fixed-video-control mobile-player">
            <div
              style={{ position: "relative" }}
              className={
                "player-slider" +
                (this.isZoomed ? " zoomed" : "") +
                (this.state.isZooming ? " zooming" : "")
              }
            >
              <VideoProgress
                className="progress-bar"
                variant="determinate"
                value={this.state.progressBarValue}
              />
              <SegmentSlider
                max={this.state.max}
                min={this.state.min}
                valueLabelFormat={(x) => {
                  return timeToString(x - this.state.absMin);
                }}
                step={0.01}
                value={this.state.sliderValue}
                onChange={this.handleSliderChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </div>
            {!this.state.addDialog && (
              <MobileControls
                fiveBack={this.fiveBack}
                fiveForward={this.fiveForward}
                student={this.props.student}
                addDialog={this.props.addDialog}
                isSegmentChanged={this.isSegmentChanged}
                changeSegmentTime={this.changeSegmentTime}
                handleAddSegmentClick={this.handleAddSegmentClick}
                zoomChange={this.zoomChange}
                isZoomed={this.isZoomed}
                isSliderReset={this.isSliderReset}
                setEnd={this.setEnd}
                setStart={this.setStart}
                isPlaying={this.state.isPlaying}
                play={this.segPlay}
                stop={this.segStop}
                increase={this.increaseSpeed}
                decrease={this.decreaseSpeed}
                changeSpeed={this.changeSpeed}
                speeds={this.state.speeds}
                speed={this.state.speed}
                isChangingSegment={this.state.changingSegmentTime}
                loopChecked={this.state.loopChecked}
                toggleLoop={this.toggleLoop}
              ></MobileControls>
            )}
          </div>
        )}
        {/* End of mobile controls */}

        {/* SEGMENT LIST */}
        {((this.state.playerReady && !this.props.addDialog) ||
          this.props.landing) && (
          <React.Fragment>
            <Grid
              item
              lg={3}
              md={this.props.landing ? 3 : 12}
              sm={isMobileOnly ? 3 : 12}
              xs={12}
              className={
                "segment-list-container" +
                (this.state.fullscreen ? " fullscreen-seg" : "") +
                (isMobileOnly ? " mobile-seg" : "")
              }
              id="segment-list"
            >
              <Grid
                container
                justify="space-between"
                className={"segment-list-header"}
              >
                <h3>{this.props.t("segments")}</h3>
                <span>
                  <div className="segment-list-opt">
                    <SortingOptions
                      selected={this.state.selectedSegSortingOpt}
                      optionList={this.segSortingOpt}
                      changeSelected={this.changeSelectedSortingOpt}
                    />
                  </div>
                  <div className="segment-list-opt">
                    <Tooltip
                      title={
                        this.state.lockMode
                          ? this.props.t("segmentLock")
                          : this.props.t("segmentLockOff")
                      }
                    >
                      <IconButton onClick={() => this.lockModeToggle()}>
                        {this.state.lockMode ? (
                          <LockIcon></LockIcon>
                        ) : (
                          <LockOpenIcon></LockOpenIcon>
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </span>
              </Grid>

              <List className={"segment-list"}>
                {!this.props.student && (
                  <ClickAwayListener onClickAway={this.handleSegmentClickAway}>
                    <div className="add-new-seg-container">
                      {!this.state.changingSegmentTime &&
                        !this.props.landing && (
                          <Portal container={this.addSegmentRef.current}>
                            <StdButton
                              style={{
                                marginLeft: isMobileOnly ? "-10px" : "",
                              }}
                              className="add-new-seg-btn control-text-btn"
                              size="small"
                              color="primary"
                              disabled={this.isSliderReset}
                              onClick={() => this.handleAddSegmentClick()}
                            >
                              <AddIcon />{" "}
                              {!isMobileOnly && this.props.t("newSegment")}
                            </StdButton>
                          </Portal>
                        )}
                      <Grow in={this.state.isAddingNewSegment}>
                        {this.state.isAddingNewSegment ? (
                          <Segment
                            youtube_id={
                              this.props.video
                                ? this.props.video.youtube_id
                                : "2345"
                            }
                            min={this.state.absMin}
                            selected={this.state.selected}
                            key={this.state.licks.length}
                            start={this.state.sliderValue[0]}
                            ending={this.state.sliderValue[1]}
                            update={this.update}
                            name={""}
                            index={this.state.licks.length}
                            del={this.deleteSegment}
                            rename={this.changeSegName}
                            changeMastered={this.changeMastered}
                            addNewSegment={this.addNewSegment}
                            closeNewInput={this.handleSegmentClickAway}
                            edit={true}
                            new={true}
                          ></Segment>
                        ) : null}
                      </Grow>
                    </div>
                  </ClickAwayListener>
                )}
                {this.state.licks &&
                  !this.state.loadingSegments &&
                  this.state.licks.map((lick, index) => (
                    <Segment
                      student={this.props.student}
                      segmentToEdit={this.props.segmentToEdit}
                      segmentAction={this.props.segmentAction}
                      setSegmentAction={this.props.setSegmentAction}
                      handleChangeSegmentTime={this.handleChangeSegmentTime}
                      setAddedVid={this.props.setAddedVid}
                      youtube_id={
                        this.props.video ? this.props.video.youtube_id : "2345"
                      }
                      teacher={this.props.teacher}
                      min={this.state.absMin}
                      tempId={lick.tempId}
                      id={lick.id}
                      key={index}
                      index={index}
                      selected={this.state.selected}
                      start={lick.start}
                      ending={lick.ending}
                      update={this.update}
                      name={lick.name}
                      note={lick.note}
                      isMastered={lick.mastered}
                      del={this.deleteSegment}
                      changeMastered={this.changeMastered}
                      rename={this.changeSegName}
                      handleNoteChange={this.changeSegmentNote}
                      edit={lick.edit}
                      new={false}
                    ></Segment>
                  ))}
                {!this.state.isAddingNewSegment &&
                  !this.state.loadingSegments &&
                  this.state.licks &&
                  this.state.licks.length <= 0 && (
                    <AlertMessage
                      type="info"
                      message={this.props.t("noSegments")}
                    />
                  )}
                {this.state.loadingSegments && (
                  <LoadingSegments></LoadingSegments>
                )}
              </List>
              {this.state.attachments && this.state.attachments.length != 0 && (
                <React.Fragment>
                  <div className="segment-list-header">
                    <h3>Viðhengi</h3>
                  </div>
                  <List>
                    {this.state.attachments.map((attachment, index) => (
                      <Attachment
                        student={this.props.student}
                        index={index}
                        id={attachment.id}
                        deleteAttachment={this.deleteAttachment}
                        name={attachment.name}
                        link={attachment.link}
                      ></Attachment>
                    ))}
                  </List>
                </React.Fragment>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
export default VideoContainer;
