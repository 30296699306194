import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./auth/AuthContext.js";
import "./App.css";
import "../styles/main.scss";
import Home from "./Home.jsx";
import VideoContainer from "./PlainVideoContainer.jsx";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import VideoBackground from "./VideoBackground.js";

function App() {
  // ---- Theme settings -----
  const [darkState, setDarkState] = useState(true);
  const [locale, setLocale] = useState("en");
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = "#43418D";
  const lightPrimaryColor = darkState ? "#A4A3EE" : "#A4A3EE";
  const extraPrimaryColor = darkState ? "#605DF9" : "#605DF9";
  const darkPrimaryColor = darkState ? "#2C2C3A" : "#2C2C3A";
  const linearPinkColor = darkState
    ? "linear-gradient(233.3deg, #FF0D63 -28.04%, #605DF9 123.21%)"
    : "linear-gradient(233.3deg, #FF0D63 -28.04%, #605DF9 123.21%)";
  const transparentDark = darkState
    ? "rgba(164, 163, 238, 0.2);"
    : "rgba(164, 163, 238, 0.2);";
  const mainSecondaryColor = darkState ? "#f50057" : "#f50057";
  const backgroundColor = darkState ? "#191919" : "#F1F1F8";
  const mainGradient = darkState
    ? "linear-gradient(256.43deg, #1C1C1C 26.14%, #232323 52.61%)"
    : "linear-gradient(222.41deg, #D2D2E7 3.03%, #E4E1F5 99%)";
  const videoBackground = darkState
    ? "rgba(0, 0, 0, 0.69);"
    : "rgba(255, 255, 255, 0.69);";
  const lightHeaderColor = darkState ? "#A4A3EE" : mainPrimaryColor;
  const backdrop = darkState
    ? "rgba(0, 0, 0, 0.8)"
    : "rgba(255, 255, 255, 0.8)";
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        light: lightPrimaryColor,
        main: mainPrimaryColor,
        dark: darkPrimaryColor,
        transparentDark: transparentDark,
        extra: extraPrimaryColor,
        lightHeaderColor: lightHeaderColor,
        linearPink: linearPinkColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      background: {
        paper: backgroundColor,
        gradient: mainGradient,
        videoBackground: videoBackground,
        backdrop: backdrop,
      },
    },
    typography: {
      fontFamily: "Open sans",
      fontSize: 16,
      htmlFontSize: 16,
      h1: {
        fontFamily: "Raleway",
        fontWeight: 600,
        fontSize: 64,
      },
      h2: {
        fontFamily: "Raleway",
        fontWeight: 600,
        fontSize: 22,
      },
      h4: {
        fontFamily: "Raleway",
        fontWeight: 600,
        lineHeight: 2,
      },
      button: {
        textTransform: "none",
      },
      caption: {
        fontFamily: "Raleway",
        fontWeight: 800,
        fontSize: 24,
        letterSpacing: ".2em",
      },
      body1: {
        fontFamily: "Open sans",
        fontSize: 16,
        fontWeight: 300,
      },
    },
    shape: {
      borderRadius: 7,
    },
    themeName: "Music loop theme",
  });

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  // ---- end of Theme settings ----

  return (
    <AuthProvider>
      <MuiThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Home
                  {...props}
                  handleThemeChange={handleThemeChange}
                  darkState={darkState}
                />
              )}
            />
            <Route
              path="/explore"
              exact
              render={(props) => (
                <Home
                  {...props}
                  explore={true}
                  handleThemeChange={handleThemeChange}
                  darkState={darkState}
                />
              )}
            />
            <Route
              path="/share/:user_id/:item_type/:item_id/:share_record_id/:millisec"
              exact
              render={(props) => (
                <Home
                  {...props}
                  explore={true}
                  share
                  handleThemeChange={handleThemeChange}
                  darkState={darkState}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </AuthProvider>
  );
}

export default App;

// {authState.isAuthenticated && userInfo ? (
//   <Container className="home">
//     <FormControlLabel control={<Switch onClick={toggleDarkMode} />} />
//   </Container>
// ) : (
//   <Container className="landing-page"></Container>
// )}
