import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useAuth } from "./AuthContext";
import firebase from "firebase";
import axios from "axios";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Icon, InlineIcon } from "@iconify/react";
import mail24 from "@iconify/icons-octicon/mail-24";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "../i18n";

const Signup = withTranslation()((props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConf, setPasswordConf] = React.useState("");
  const { signup } = useAuth();
  const [error, setError] = React.useState(false);
  const [emailVerification, setEmailVerification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: window.location.href,
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // Use email link authentication and do not require password.
        // Note this setting affects new users only.
        // For pre-existing users, they will still be prompted to provide their
        // passwords on sign-in.
        // Allow the user the ability to complete sign-in cross device, including
        // the mobile apps specified in the ActionCodeSettings object below.
        forceSameDevice: false,
        emailLinkSignIn: function () {
          return {
            // Additional state showPromo=1234 can be retrieved from URL on
            // sign-in completion in signInSuccess callback by checking
            // window.location.href.
            url: window.location.href,
            // Custom FDL domain.
            // Always true for email link sign-in.
            handleCodeInApp: true,
            // Whether to handle link in iOS app if installed.
          };
        },
      },
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult) {
        if (
          !authResult.user.emailVerified &&
          authResult.additionalUserInfo.providerId === "password"
        ) {
          firebase.auth().currentUser.sendEmailVerification({
            url: "https://youlearn-global.netlify.app/",
          });
          setEmailVerification(true);
          return false;
        } else {
          return true;
        }
      },
    },
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (password != passwordConf) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      await signup(email, password);
    } catch {
      setLoading(false);
      setError(true);
      return;
    }

    //signup(email, password)
    setLoading(false);
  }

  return (
    <div>
      <h2 style={{ marginBottom: "70px", textAlign: "center", color: "black" }}>
        {props.t("signIn")}
      </h2>
      <StyledFirebaseAuth
        uiCallback={(ui) => ui.disableAutoSignIn()}
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      ></StyledFirebaseAuth>
      {error && <p style={{ color: "black" }}>ERROR</p>}
      {/*<form className="Signup" onSubmit={handleSubmit}>
                { currentUser && currentUser.email}
                <label>Your email</label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email"></input>
                <label>Your password</label>
                <input onChange={(e) => setPassword(e.target.value)} value={password} type="password"></input>
                <label>Password Confirmation</label>
                <input onChange={(e) => setPasswordConf(e.target.value)} value={passwordConf} type="password"></input>
                <Button disabled={loading} type="submit">Signup</Button>
            </form>*/}
      {emailVerification && (
        <div style={{ textAlign: "center" }}>
          <Icon icon={mail24} style={{ color: "black", fontSize: "36px" }} />
          <p style={{ color: "black" }}>
            staðfestingarpóstur hefur verið sendur á netfangið{" "}
            {firebase.auth().currentUser.email} <br />
            Vinsamlegast staðfestu netfangið
          </p>
        </div>
      )}
    </div>
  );
});

export default Signup;
