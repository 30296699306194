import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AddCollectionDialog from "./AddCollectionDialog.jsx";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../auth/AuthContext";
import { dbFormat } from "../../helpers";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { dynamicSort } from "../../helpers";
import { isMobileOnly } from "react-device-detect";
import i18n from "../i18n";
import { withTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  contextMenuItem: {},
  menu: {
    position: "absolute !important",
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CollectionTab = withStyles((theme) => ({
  root: {
    fontWeight: 300,
    borderRadius: 4,
    background: "linear-gradient(35deg, #464649 18.01%, #353536 81.65%)", //TODO THEME
    minHeight: isMobileOnly ? "12px" : "40px",
    color: theme.palette.text.primary,
    padding: isMobileOnly ? "4px 10px 6px 10px" : "7px 15px",
    margin: "10px 10px",
    marginLeft: 0,
    minWidth: "50px !important",
    transition: "background .5s ease, color .5s ease",
    maxWidth: "none",
  },
  selected: {
    background: theme.palette.primary.main,
    color: "#fff !important",
  },
}))(Tab);

const CollectionTabs = withStyles((theme) => ({
  root: {},
  selected: {
    background: theme.palette.primary.main,
    color: "#fff !important",
  },
}))(Tab);

const Collections = withTranslation()((props) => {
  const {
    currentUser,
    accountInfo,
    getToken,
    handleOrgChange,
    displayMessage,
  } = useAuth();

  const [collections, setCollections] = useState(null);
  const [value, setValue] = useState(null);
  const [manageCollectionOpen, setManageCollectionOpen] = useState(false);
  const [pseudoCache, setPseudoCache] = useState({});

  const handleChange = (event, newValue) => {
    var temp = pseudoCache;
    temp[props.category] = collections[newValue].id;
    setPseudoCache(temp);
    props.setCollection({
      name: collections[newValue].name,
      teacher: collections[newValue].teacher,
    });
    props.setCollectionId(collections[newValue].id);
    setValue(newValue);
    if (accountInfo.role == "student") {
      langChange(collections[newValue].orgName);
      handleOrgChange(collections[newValue].orgName);
    }
  };

  const langChange = (newOrg) => {
    const isOrgs = [
      "Tónlistarskóli FÍH",
      "Tónlistarskólinn á Tröllaskaga",
      "Tónlistarskóli Garðarbæjar",
      "Tónlistarskóli Árbæjar",
      "Tónlistarskólinn á Egilsstöðum",
      "Jón Hilmar",
      "Tónlistarskóli Húsavíkur",
      "Tónsalir",
    ];
    if (
      isOrgs.some((org) => {
        return org == newOrg;
      })
    ) {
      i18n.changeLanguage("is");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const getStudentCols = () => {
    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/student_collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            email: accountInfo.email,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          const formattedCategories = data.map((collection) => {
            return {
              updated: collection.updated
                ? new Date(collection.updated)
                : new Date(0),
              name: collection.name + " - " + collection.display_name,
              id: collection.id,
              teacher: false,
              orgName: collection.org_name,
            };
          });
          setCollections(formattedCategories.sort(dynamicSort("name")));

          if (accountInfo.collection_id) {
            const collectionIndex = formattedCategories.findIndex(
              (category) => {
                return category.id == accountInfo.collection_id;
              }
            );
            if (collectionIndex != -1) {
              props.setCollectionId(formattedCategories[collectionIndex].id);
              setValue(collectionIndex);
              handleOrgChange(formattedCategories[collectionIndex].orgName);
              langChange(formattedCategories[collectionIndex].orgName);
              props.setCollection({
                name: formattedCategories[collectionIndex].name,
                teacher: false,
              });
            } else {
              props.setCollectionId(formattedCategories[0].id);
              setValue(0);
              handleOrgChange(formattedCategories[0].orgName);
              langChange(formattedCategories[0].orgName);
              props.setCollection({
                name: formattedCategories[0].name,
                teacher: false,
              });
            }
          } else {
            props.setCollectionId(formattedCategories[0].id);
            setValue(0);
            handleOrgChange(formattedCategories[0].orgName);
            langChange(formattedCategories[0].orgName);
            props.setCollection({
              name: formattedCategories[0].name,
              teacher: false,
            });
          }
        })
        .catch((error) => {});
    });
  };

  const loadCategory = (category) => {
    if (!category) {
      props.setCategory("personal");
    }
    props.setCollection({
      name: "",
      teacher: true,
    });
    props.setCollectionId(-2);
    if (category == null) {
      return;
    }
    setCollections(null);
    if (category == "courses") {
      getStudentCols();
    } else {
      getToken(props.explore ? "explore" : false).then((idToken) => {
        var user = props.userId;
        if (
          category != "personal" &&
          category != "students" &&
          category != "shared"
        ) {
          user = accountInfo.org;
        }
        fetch(
          `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${user}/collections/${category}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        )
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            var formattedCategories = data.map((collection) => {
              if (category == "shared") {
                return {
                  updated: collection.updated
                    ? new Date(collection.updated)
                    : new Date(0),
                  name: collection.name + " - " + collection.display_name,
                  id: collection.id,
                  teacher: true,
                  orgName: collection.org_name,
                };
              } else {
                return {
                  updated: collection.updated
                    ? new Date(collection.updated)
                    : new Date(0),
                  name: collection.name,
                  id: collection.id,
                  teacher: true,
                  orgName: collection.org_name,
                };
              }
            });
            if (category == "students") {
              formattedCategories = formattedCategories.filter((collection) => {
                return collection.orgName == accountInfo.name;
              });
              setCollections(formattedCategories.sort(dynamicSort("name")));
            } else {
              setCollections(formattedCategories.sort(dynamicSort("name")));
            }
            if (formattedCategories.length == 0) {
              props.setCollection({
                name: "Ekkert safn fannst",
                teacher: true,
              });
              props.setCollectionId(-1);
            } else {
              // if accountInfo.collection_id = if someone has entered a collection before using youlearn
              // if !value = if someone has entered a collection before in this session
              if (accountInfo.collection_id && value == null) {
                const collectionIndex = formattedCategories.findIndex(
                  (category) => {
                    return category.id == accountInfo.collection_id;
                  }
                );
                if (collectionIndex != -1) {
                  props.setCollectionId(
                    formattedCategories[collectionIndex].id
                  );
                  props.setCollection({
                    name: formattedCategories[collectionIndex].name,
                    teacher: true,
                  });
                  setValue(collectionIndex);
                  //handleOrgChange(formattedCategories[collectionIndex].orgName);
                } else {
                  props.setCollection({
                    name: formattedCategories[0].name,
                    teacher: true,
                  });
                  props.setCollectionId(formattedCategories[0].id);
                  setValue(0);
                  //handleOrgChange(formattedCategories[0].orgName);
                }
              } else {
                if (pseudoCache[props.category]) {
                  const collectionIndex = formattedCategories.findIndex(
                    (col) => {
                      return pseudoCache[props.category] == col.id;
                    }
                  );

                  if (collectionIndex != -1) {
                    props.setCollectionId(
                      formattedCategories[collectionIndex].id
                    );
                    setValue(collectionIndex);
                    props.setCollection({
                      name: formattedCategories[collectionIndex].name,
                      teacher: true,
                    });
                  }
                } else {
                  props.setCollectionId(formattedCategories[0].id);
                  setValue(0);
                  props.setCollection({
                    name: formattedCategories[0].name,
                    teacher: true,
                  });
                }
              }
            }
          })
          .catch((err) => {});
      });
    }
  };

  const copyCollection = (dep, category, displayName, reciever) => {
    var user = accountInfo.uid;
    if (dep) {
      user = accountInfo.org;
    } else if (reciever) {
      user = reciever;
    }
    getToken().then((idToken) => {
      var copiedCol = collections[props.contextItemIndex];
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/users/${user}/collections`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            name: dbFormat(copiedCol.name),
            oldId: copiedCol.id,
            category: category,
            org_name: accountInfo.name,
            type: 2,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          displayMessage(
            "success",
            copiedCol.name + " var afritað í " + displayName
          );
          props.setCopyColMenuState(0);
        })
        .catch((err) => {
          displayMessage("error", "Villa kom upp");
        });
    });
  };

  const addCollection = (newCollection) => {
    setCollections([...collections, newCollection]);
    setValue(collections.length);
    props.setCollection({ name: newCollection.name, teacher: true });
    props.setCollectionId(newCollection.id);
  };

  const handleCloseDialog = (event) => {
    setManageCollectionOpen(false);
  };

  useEffect(() => {
    loadCategory(props.category);
  }, [props.category]);

  useEffect(() => {
    if (props.editObj) {
      if (props.editObj.type === "collection") {
        var newArr = collections;
        var updateId = newArr[props.editObj.index].id;
        if (props.editObj.name === null) {
          getToken().then((idToken) => {
            newArr.splice(props.editObj.index, 1);
            fetch(
              `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${updateId}`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return Promise.reject();
                }
                setCollections([...newArr]);
                props.setCollection({
                  name: newArr[0].name,
                  teacher: newArr[0].teacher,
                });
                props.setCollectionId(newArr[0].id);
                setValue(0);
                return response.json();
              })
              .catch((err) => {});
          });
        } else {
          getToken().then((idToken) => {
            newArr[props.editObj.index].name = props.editObj.name;
            fetch(
              `${process.env.REACT_APP_RESOURCE_SERVER}/api/collections/${updateId}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                  name: dbFormat(props.editObj.name),
                }),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return Promise.reject();
                }
                return response.json();
              })
              .then((data) => {
                setCollections([...newArr]);
              })
              .catch((err) => {
                /* eslint-disable no-console */
              });
          });
        }
      } else {
        //setEditItem(props.editObj);
      }
    }
  }, [props.editObj.changed]);

  return (
    <div>
      {!collections && (
        <div style={{ marginTop: "-15px", marginBottom: "11px" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Skeleton width={100} height={70} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton width={70} height={70} animation="wave" />
            </Grid>
            <Grid item>
              <Skeleton width={120} height={70} animation="wave" />
            </Grid>
          </Grid>
        </div>
      )}

      {collections && props.category != null && (
        <div>
          <div className="collection-tab-container">
            {collections.length == 0 && (
              <p style={{ marginTop: "20x", paddingBottom: "13px" }}>
                {props.t("noCols")}
              </p>
            )}
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable collection bar"
            >
              {collections.length != 0 &&
                collections.map((collection, index) => (
                  <CollectionTab
                    name={collection.name}
                    index={index}
                    component="div"
                    role={collection.teacher}
                    className={
                      collection.teacher
                        ? "collection-tab teacher"
                        : "collection-tab"
                    }
                    key={collection.id}
                    label={
                      <div>
                        <span
                          name={collection.name}
                          index={index}
                          className={
                            collection.teacher
                              ? "collection-name teacher"
                              : "collection-name"
                          }
                        >
                          {collection.name}
                        </span>
                      </div>
                    }
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>
            )
            {(props.category == "personal" || props.category == "students") && (
              <AddCollectionDialog
                category={props.category}
                userId={props.userId}
                addCollection={addCollection}
              />
            )}
            {!props.explore &&
              props.category !== "shared" &&
              props.category !== null &&
              props.category !== "personal" &&
              props.category !== "students" &&
              props.category !== "courses" && (
                <AddCollectionDialog
                  category={props.category}
                  userId={
                    accountInfo.departments ? accountInfo.departments.org : 0
                  }
                  addCollection={addCollection}
                />
              )}
          </div>
        </div>
      )}
      {collections &&
        collections.map((collection, index) => (
          <TabPanel
            style={{
              marginLeft: "-25px",
            }}
            key={collection.id}
            value={value}
            index={index}
            className="collection-tab"
          ></TabPanel>
        ))}

      <Menu
        disableAutoFocusItem
        keepMounted="false"
        open={props.contextState.mouseY !== null && props.copyColMenuState == 1}
        onClose={() => props.setCopyColMenuState(0)}
        anchorReference="anchorPosition"
        anchorPosition={
          props.contextState.mouseY !== null &&
          props.contextState.mouseX !== null
            ? {
                top: props.contextState.mouseY + 33,
                left: props.contextState.mouseX + 112,
              }
            : undefined
        }
      >
        {accountInfo.departments &&
          accountInfo.departments
            .filter((item) => {
              return props.category != item.id;
            })
            .map((item) => {
              return (
                <MenuItem
                  onClick={() => copyCollection(true, item.id, item.name)}
                >
                  {item.name}
                </MenuItem>
              );
            })}
        {props.category != "students" && accountInfo.name != "Mitt svæði" && (
          <MenuItem
            onClick={() => copyCollection(false, "students", "Nemenda söfn")}
          >
            Nemendur
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            copyCollection(
              false,
              "personal",
              "user",
              "7Q3IO6yOJ0caD4NqBRWbjOQzo1v2"
            )
          }
        >
          User
        </MenuItem>
        {props.category != "personal" && accountInfo.name != "Mitt svæði" && (
          <MenuItem
            onClick={() => copyCollection(false, "personal", "Mitt svæði")}
          >
            Mín söfn
          </MenuItem>
        )}
      </Menu>
    </div>
  );
});

export default Collections;
