import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogLayout from "./layout/DialogLayout";
import { dbFormat } from "./../helpers";
import { useAuth } from "./auth/AuthContext";
import { TextField as YlTextField } from "./InputFields";
import { withTranslation } from "react-i18next";
import { checkPropTypes } from "prop-types";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    // textAlign: "center",
  },
  marginBottom: {
    marginBottom: 20,
  },
  title: {
    fontSize: 32,
    lineHeight: 1,
    marginTop: 0,
  },
  topInput: {
    margin: theme.spacing(1),
    marginBottom: 19,
  },
  center: {
    textAlign: "center",
  },
  input: {
    width: "100%",
  },
  inputLabel: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
}));

const AddAttachmentDialog = withTranslation()((props) => {
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [attachmentName, setAttachmentName] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const { userValidated, getToken, displayMessage } = useAuth();
  useEffect(() => {
    if (props.open === true) {
      if (userValidated) {
        props.setContextState({ mouseX: null, mouseY: null });
        setOpen(true);
      }
    }
  }, [props.open]);

  const handleClose = () => {
    setAttachmentName("");
    setAttachmentUrl("");
    props.setAddAttachOpen(false);
    setOpen(false);
  };

  const checkUrl = (value) => {
    setAttachmentUrl(value);
    if (value.includes("dropbox")) {
      var link = value;
      var splitLink = link.split("/");
      if (splitLink.length >= 4) {
        if (splitLink[3] == "sh") {
          return;
        }
      }
      if (splitLink.length >= 6) {
        var fileName = splitLink[splitLink.length - 1].slice(0, -5);
        var splitName = fileName.split("%20");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += " " + splitName[index];
        }
        splitName = fileName.split("%C3%89");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "É" + splitName[index];
        }
        splitName = fileName.split("%C3%A9");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "é" + splitName[index];
        }
        splitName = fileName.split("%C3%BD");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ý" + splitName[index];
        }
        splitName = fileName.split("%C3%B0");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ð" + splitName[index];
        }
        splitName = fileName.split("%C3%BE");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "þ" + splitName[index];
        }
        splitName = fileName.split("%C3%B6");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ö" + splitName[index];
        } //%C3%B3
        splitName = fileName.split("%C3%B3");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ó" + splitName[index];
        } //%C3%8D
        splitName = fileName.split("%C3%8D");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "í" + splitName[index];
        }
        splitName = fileName.split("%C3%90");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ð" + splitName[index];
        }
        splitName = fileName.split("%C3%86");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Æ" + splitName[index];
        }
        splitName = fileName.split("%C3%9E");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Þ" + splitName[index];
        }
        splitName = fileName.split("%C3%93");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ó" + splitName[index];
        }
        splitName = fileName.split("%C3%96");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ö" + splitName[index];
        }
        splitName = fileName.split("%C3%9D");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Ý" + splitName[index];
        }
        splitName = fileName.split("%C3%A6");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "æ" + splitName[index];
        }
        splitName = fileName.split("%C3%AD");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "í" + splitName[index];
        }
        splitName = fileName.split("%C3%A1");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "á" + splitName[index];
        }
        splitName = fileName.split("%C3%81");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "Á" + splitName[index];
        }
        splitName = fileName.split("%28");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "(" + splitName[index];
        }
        splitName = fileName.split("%29");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += ")" + splitName[index];
        }
        splitName = fileName.split("%C3%BA");
        fileName = splitName[0];
        for (let index = 1; index < splitName.length; index++) {
          fileName += "ú" + splitName[index];
        }

        const name = fileName.split("?")[0];
        setAttachmentName(name);
      }
    }
  };

  const handleSave = (e) => {
    if (attachmentName == "") {
      setError(true);
      return;
    }
    if (e) {
      e.preventDefault();
    }

    getToken().then((idToken) => {
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${props.videoId}/attachments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            name: dbFormat(attachmentName),
            link: attachmentUrl,
            video_id: props.videoId,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          displayMessage(
            "success",
            attachmentName +
              " " +
              props.t("wasAddedTo") +
              " " +
              props.videoName +
              " " +
              props.t("asAttachment")
          );
          props.setAttachmentChange(!props.attachmentChange);
        })
        .catch((error) => {
          displayMessage("error", "Error");
        });
    });

    handleClose();
  };
  const renderTitle = () => {
    return (
      <React.Fragment>
        {props.t("addAttachmentTo") + " " + props.videoName}
      </React.Fragment>
    );
  };

  return (
    <DialogLayout
      handleClose={handleClose}
      handleSave={handleSave}
      displayPrimaryBtn={true}
      displaySecondaryBtn={true}
      primaryBtnText="Vista"
      secondaryBtnText="Hætta við"
      title={renderTitle()}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <p>{props.t("shareDropboxLink")}</p>
      <form onSubmit={(e) => handleSave(e)}>
        <YlTextField
          autoFocus
          margin="dense"
          id="url"
          label="URL"
          type="url"
          value={attachmentUrl}
          onChange={checkUrl}
          fullWidth
        />
      </form>
      <br></br>
      <form onSubmit={(e) => handleSave(e)}>
        <YlTextField
          margin="dense"
          id="name"
          label={props.t("name")}
          type="name"
          helperText={error ? props.t("chooseName") : ""}
          value={attachmentName}
          onChange={setAttachmentName}
          fullWidth
        />
      </form>
    </DialogLayout>
  );
});

export default AddAttachmentDialog;
