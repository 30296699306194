import React, { useState, useEffect } from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { ReactComponent as SelectIcon } from "../assets/icons/chevron-down.svg";
import { Autocomplete } from "@material-ui/lab";
import FormHelperText from "@material-ui/core/FormHelperText";

const TextFieldInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: 22,
    },
    borderRadius: 15,
    position: "relative",
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(164, 163, 238, 0.19)"
        : "rgba(67, 65, 141, 0.1)",
    border: "#E0DFED",
    fontSize: 16,
    padding: "6px 20px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Open Sans",
    border: "1px solid transparent",
    "& svg": {
      marginLeft: -5,
      marginRight: 15,
    },
    "&:focus-within": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "#A4A3EE",
    },
  },
  input: {
    "&.MuiInputBase-multiline": {
      padding: 0,
    },
  },
}))(InputBase);

const SelectFieldInput = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingBottom: 12,
    marginTop: 6,
    "&:after": {
      borderColor: "#A4A3EE !important",
    },
  },
  icon: {
    "& path": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  },
}))(Select);

const Label = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    transform: "none",
    fontSize: 14,
    letterSpacing: "0.6px",
    // fontWeight: 600,
    color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    "&.Mui-focused": {
      color: theme.palette.type === "dark" ? "#A4A3EE" : "#43418D",
    },
  },
}))(InputLabel);

export function SearchField({
  id,
  fullWidth,
  label,
  autoFocus,
  options,
  focused,
  onChange,
  value,
  error,
  errorMessage,
  multiline,
  rows,
  rowsMax,
  startAdornment,
  placeholder,
}) {
  return (
    <FormControl fullWidth>
      <Label shrink htmlFor={"free-solo"}>
        {label}
      </Label>
      <Autocomplete
        freeSolo
        disableClearable
        options={options}
        renderInput={(params) => {
          return (
            <TextFieldInput
              {...params}
              fullWidth
              id="free-solo"
              value={value}
              margin="normal"
              onChange={(e) => onChange(e.target.value)}
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
              multiline={multiline}
              rows={rows}
              rowsMax={rowsMax}
              startAdornment={startAdornment}
              placeholder={placeholder}
            />
          );
        }}
      />
      {error && (
        <FormHelperText id={id + "-helper-text"}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export function TextField({
  id,
  fullWidth,
  label,
  autoFocus,
  focused,
  onChange,
  value,
  error,
  errorMessage,
  multiline,
  rows,
  rowsMax,
  startAdornment,
  placeholder,
}) {
  return (
    <FormControl fullWidth>
      <Label shrink htmlFor={id}>
        {label}
      </Label>
      <TextFieldInput
        fullWidth
        autoFocus={autoFocus}
        focused={focused}
        id={id}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        startAdornment={startAdornment}
        placeholder={placeholder}
      />
      {error && (
        <FormHelperText id={id + "-helper-text"}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

export function SelectField({
  id,
  fullWidth,
  label,
  autoFocus,
  focused,
  onChange,
  value,
  error,
  errorMessage,
  multiline,
  rows,
  rowsMax,
  startAdornment,
  placeholder,
  children,
}) {
  return (
    <FormControl fullWidth>
      <Label shrink htmlFor={id + "-label"}>
        {label}
      </Label>
      <SelectFieldInput
        labelId={id + "-label"}
        id={id}
        value={value}
        onChange={onChange}
        IconComponent={SelectIcon}
      >
        {children}
      </SelectFieldInput>
      {error && (
        <FormHelperText id={id + "-helper-text"}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}
