import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userValidated, setUserValidated] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    email: "youlearn@youlearn.is",
    name: "Velkomin",
    role: "teacher",
    collection_id: 1,
    video_id: 1,
  });
  const [pending, setPending] = useState(false);
  const [org, setOrg] = useState("velkomin");
  const [pseudoCache, setPseudoCache] = useState([]);
  const [currSubCollection, setCurrSubCollection] = useState("");
  const [snackBarInfo, setSnackBarInfo] = useState({
    type: "success",
    message: "Youlearn",
  });
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  function sendSignInLinkToEmail(emailList) {
    for (let index = 0; index < emailList.length; index++) {
      auth
        .sendSignInLinkToEmail(emailList[index], {
          url: process.env.REACT_APP_FRONT_END,
          handleCodeInApp: true,
        })
        .then(function () {
          // The link was successfully sent. Inform the user. Save the email
          // locally so you don't need to ask the user for it again if they open
          // the link on the same device.
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function handleOrgChange(orgName) {
    var accInfo = accountInfo;
    if (accountInfo.role === "student") {
      accInfo.name = orgName;
      setAccountInfo(accInfo);
    } else {
      for (let index = 0; index < accountInfo.orgs.length; index++) {
        if (accountInfo.orgs[index].name == orgName) {
          accInfo.name = orgName;
          accInfo.org = accountInfo.orgs[index].id;
          accInfo.role = accountInfo.orgs[index].role;
          accInfo.departments = accountInfo.orgs[index].departments;
          accInfo.orgs = accountInfo.orgs;
          setAccountInfo(accInfo);
          setOrg(orgName);
          getToken(currentUser).then((idToken) => {
            const body = { org: orgName };
            axios
              .put(
                process.env.REACT_APP_RESOURCE_SERVER +
                  "/api/accounts/" +
                  currentUser.uid,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )
              .catch(() => {});
          });
        }
      }
    }
  }

  function displayMessage(type, message, callback) {
    if (type == "close") {
      setSnackBarOpen(false);
    } else {
      setSnackBarInfo({ type, message, callback });
      setSnackBarOpen(true);
    }

    setTimeout(() => {
      if (document.getElementById("snack-bar-button")) {
        document.getElementById("snack-bar-button").focus();
      }
    }, 100);
    //setSnackBarOpen(true)
  }

  function signOut() {
    return auth.signOut();
  }

  const getToken = async function (user) {
    if (user) {
      if (user == "explore") {
        return "n6dkzNQneoXkbuCHgd4nG9iHtjh2";
      }
      return user.getIdToken(true);
    }
    if (currentUser) {
      return currentUser.getIdToken(true);
    }
    return "n6dkzNQneoXkbuCHgd4nG9iHtjh2";
  };
  useEffect(() => {
    setPending(true);
    const unsubscriber = auth.onAuthStateChanged((user) => {
      if (!user) {
        setPending(false);
        setCurrentUser(user);
        return;
      }
      if (user.emailVerified) {
        setCurrentUser(user);
        getToken(user).then((idToken) => {
          const body = { email: user.email, displayName: user.displayName };
          axios
            .post(
              process.env.REACT_APP_RESOURCE_SERVER +
                "/api/accounts/" +
                user.uid,
              body,
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then((response) => {
              if (response.data.role == "none" && !response.data.student) {
                var accInfo = {
                  name: user.displayName,
                  role: "none",
                  collection_id: response.data.collection_id,
                  video_id: response.data.video_id,
                  email: user.email,
                  category: response.data.category,
                };
                setAccountInfo(accInfo);
                setUserValidated(true);
                setPending(false);
              } else if (
                response.data.role == "none" &&
                response.data.student
              ) {
                var accInfo = {
                  name: "Velkomin",
                  role: "student",
                  collection_id: response.data.collection_id,
                  video_id: response.data.video_id,
                  email: user.email,
                  category: response.data.category,
                };
                setAccountInfo(accInfo);
                setUserValidated(true);
                setPending(false);
              } else {
                setAccountInfo(response.data);
                setUserValidated(true);
                setPending(false);
              }
            })
            .catch((error) => {
              setCurrentUser(null);
            });
        });
      } else {
        displayMessage(
          "info",
          'Netfangið "' + user.email + '" hefur ekki verið staðfest'
        );
        setPending(false);
        setUserValidated(false);
      }
    });
    return unsubscriber;
  }, []);

  const value = {
    pseudoCache,
    snackBarInfo,
    snackBarOpen,
    pending,
    accountInfo,
    userValidated,
    currentUser,
    currSubCollection,
    setPseudoCache,
    displayMessage,
    setCurrSubCollection,
    signOut,
    getToken,
    handleOrgChange,
    sendSignInLinkToEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
