import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonTranslationEN from "./assets/commonEn.json";
import commonTranslationIs from "./assets/commonIs.json";

const resources = {
  en: {
    // Namspaces
    common: commonTranslationEN,
  },
  is: {
    // Namspaces
    common: commonTranslationIs,
  },
};

i18n

  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources,

    // Set default namespace
    defaultNS: "common",

    lng: "en",

    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
  });

export default i18n;
