import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import removeIcon from "../images/AddNewCategory.png";
import { Icon } from "@iconify/react";
import checkCircleFill from "@iconify/icons-ph/check-circle-fill";
import { TextField } from "@material-ui/core";
import "./i18n";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

const StudentTable = withTranslation()((props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{props.t("email")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.students.map((student) => (
              <TableRow key={student}>
                <TableCell component="th" scope="row">
                  {student}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => props.handleStudentRemove(student)}
                    color="white"
                    size="medium"
                  >
                    <img src={removeIcon}></img>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {(!props.max || props.students.length == 0) && (
              <TableRow
                style={{
                  border: "none",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                key={-1}
              >
                <TableCell
                  scope="row"
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "12px",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.handleAddStudent();
                    }}
                  >
                    <TextField
                      label={props.t("email")}
                      type="text"
                      onChange={(e) => props.setEmail(e.target.value)}
                      value={props.email}
                      required
                    />
                  </form>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  align="right"
                >
                  <IconButton
                    onClick={() => {
                      if (props.email != "") {
                        props.handleAddStudent();
                      }
                    }}
                    color="white"
                    size="medium"
                  >
                    <Icon
                      icon={checkCircleFill}
                      style={{
                        color: "#605df9",
                        fontSize: "30px",
                        paddingBottom: "0px",
                      }}
                    />
                  </IconButton>
                  {props.t("add")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
});

export default StudentTable;
