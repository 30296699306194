import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.videoBackground,
  },
}));
// Basic component with logout button
const VideoBackground = (props) => {
  const classes = useStyles();
  //console.log(classes);
  return (
    <div
      droppable="true"
      className={"video-background " + classes.root + " " + props.cssClass}
    >
      {props.children}
    </div>
  );
};

export default VideoBackground;
