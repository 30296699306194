import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./auth/AuthContext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    Button: {
      marginTop: "200px",
    },
  },
}));

export default function CustomSnackBar() {
  const classes = useStyles();
  const { displayMessage, snackBarInfo, snackBarOpen } = useAuth();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    displayMessage("close");
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackBarInfo.type}>
          {snackBarInfo.message}
          {snackBarInfo.callback && (
            <>
              <br></br>
              <Button
                id="snack-bar-button"
                style={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  marginTop: "-8px",
                  marginBottom: "-6px",
                  float: "right",
                }}
                onClick={() => {
                  snackBarInfo.callback();
                  handleClose();
                }}
              >
                {snackBarInfo.message.includes("share") ? "Copy" : "Ahead"}{" "}
                <ArrowForwardIcon></ArrowForwardIcon>
              </Button>
            </>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}
