import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withTranslation } from "react-i18next";
import "../i18n";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MemberInput = withTranslation()((props) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
    props.handleStaffMemberChange(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">
          {props.t("staffMember")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={email}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {props.staffMembers.map((staffMember) => {
            return (
              <MenuItem key={staffMember.email} value={staffMember.email}>
                {staffMember.email}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{props.t("selectMemberEmail")}</FormHelperText>
      </FormControl>
    </div>
  );
});

export default MemberInput;
