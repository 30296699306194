import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useAuth } from "./auth/AuthContext";
import { withTranslation } from "react-i18next";
import "./i18n";

// Basic component with logout button
const Logout = withTranslation()((props) => {
  const { signOut } = useAuth();
  return (
    <ListItem button onClick={signOut}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary={props.t("signOut")} />
    </ListItem>
  );
});

export default Logout;
