import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { dynamicSort, getFileType } from "../../helpers";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useAuth } from "../auth/AuthContext";
import AlertMessage from "../AlertMessage";
import { Icon, InlineIcon } from "@iconify/react";
import folderIcon from "@iconify/icons-akar-icons/folder";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: "25px !important",
    // marginLeft: "15px !important",
  },
  sub: {
    borderBottom: "1px solid #605DF9",
    fontFamily: "Raleway",
    padding: 0,
    marginBottom: 7,
  },
  subText: {
    fontWeight: 600,
  },
}));
export function Subcollection(props) {
  const { getToken, setCurrSubCollection } = useAuth();
  const classes = useStyles();
  var teacherItem = "";
  if (props.teacher) {
    teacherItem = "teacher-item ";
  }
  var textElLastHovered = false;
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const handleClick = () => {
    props.toggleOpen(props.item.id);
  };

  const removeHover = (e) => {
    var hovered = document.getElementsByClassName("sub-drag-hover");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("sub-drag-hover");
    }
    hovered = document.getElementsByClassName("drag-hover-top");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-top");
    }
    hovered = document.getElementsByClassName("drag-hover-bottom");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-bottom");
    }
  };

  const handleSelect = (e) => {
    var selectedElement = e.target;
    var clickedId = e.target.id;
    if (!clickedId) {
      selectedElement = e.target.parentElement.parentElement;
      clickedId = selectedElement.id;
    }
    const selectedVideo = props.item.vidList.find(
      (element) => element.id == clickedId
    );
    props.clearAndSetSelected(selectedVideo);
    selectedElement.classList.add("selected-item");
    setCurrSubCollection(props.item.name);
  };

  const dragStart = (e) => {
    props.setIgnoreFirstHover(true);
    var clickedId = e.target.id;
    if (!clickedId) {
      clickedId = e.target.parentElement.parentElement.id;
    }
    props.setDraggingInSubcollection(props.item.id);
    var index = props.item.vidList.findIndex(
      (element) => element.id == clickedId
    );
    setDraggedIndex(index);
    const transferItem = props.item.vidList[index];
    var element = document.getElementById("dragPoser");
    document.getElementById("dragText").innerHTML = transferItem.name;
    e.dataTransfer.setDragImage(element, -10, -10);

    e.dataTransfer.setData(
      "item",
      transferItem.id +
        " " +
        transferItem.start +
        " " +
        transferItem.ending +
        " " +
        transferItem.youtube_id +
        " " +
        transferItem.name
    );
  };

  const dragListEndEnter = (e) => {
    if (props.isSubDrag) {
      removeHover();
      return;
    }
    textElLastHovered = true;
    removeHover();
    if (props.draggingInSubcollection != props.item.id) {
      e.target.classList.add("drag-hover-top");
    }
  };
  const dragListEndDrop = (e) => {
    e.preventDefault();
    const item = e.dataTransfer.getData("item");
    getToken().then((idToken) => {
      removeHover();
      if (props.draggingInSubcollection == props.item.id) {
        return;
      }
      const splitData = item.split(" ", 4);
      var droppedId = splitData[0];
      if (droppedId == "sub") {
        return;
      }
      const droppedStart = splitData[1];
      const droppedEnding = splitData[2];
      const droppedYoutubeId = splitData[3];
      const droppedName = item.slice(
        droppedYoutubeId.length +
          droppedId.length +
          droppedStart.length +
          droppedEnding.length +
          4
      );
      droppedId = parseInt(droppedId);
      if (droppedId == document.getElementsByClassName("selected-item")[0].id) {
        setCurrSubCollection(props.item.name);
      }
      //itemElem.style.display = "block";
      var link = false;
      var linkType = "video";
      if (
        droppedYoutubeId.startsWith("http") ||
        droppedYoutubeId.startsWith("www") ||
        droppedYoutubeId.startsWith("dropbox")
      ) {
        link = true;
        linkType = getFileType(droppedYoutubeId);
      }
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${droppedId}`,
        {
          method: "PUT",
          body: JSON.stringify({ subcollectionId: props.item.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          var newArray = [...props.item.vidList];
          newArray.push({
            link: link,
            linkType: linkType,
            youtube_id: droppedYoutubeId,
            start: Number(droppedStart),
            ending: Number(droppedEnding),
            id: droppedId,
            order_no: data.order_no,
            name: droppedName,
          });
          props.updateVidList(newArray, props.item.id, droppedId);
        })
        .catch((err) => {
          return [];
        });
      props.setSubDragIndex(-1);
    });
  };

  const subDragStart = (e) => {
    props.setIgnoreFirstHover(true);
    props.setDraggingInSubcollection(-1);
    props.setSubDragIndex(props.item.id);
    var openVal = 0;
    if (props.item.open) {
      openVal = 1;
    }
    var element = document.getElementById("subDragPoser");
    document.getElementById("subDragPoserText").innerHTML = props.item.name;
    e.dataTransfer.setDragImage(element, -10, -10);
    e.dataTransfer.setData(
      "item",
      "sub " + props.item.id + " " + openVal + " " + props.item.name
    );
  };

  const dragOver = (e) => {
    if (!props.isSubDrag) {
      e.preventDefault();
    }
  };

  const dragOverSub = (e) => {
    e.preventDefault();
  };
  // gerir bara last drag leave og málið er dautt

  const dragEnter = (e) => {
    if (props.ignoreFirstHover) {
      props.setIgnoreFirstHover(false);
      return;
    }

    if (props.isSubDrag) {
      removeHover();
      e.target.classList.add("not-allowed-cursor");
      return;
    }
    textElLastHovered = false;
    var hovered = e.target;
    if (!hovered.id) {
      textElLastHovered = true;
      hovered = e.target.parentElement.parentElement;
    }
    const hoveredIndex = props.item.vidList.findIndex(
      (element) => element.id == hovered.id
    );
    removeHover();
    if (props.draggingInSubcollection != props.item.id) {
      hovered.classList.add("drag-hover-top");
      return;
    }
    if (hoveredIndex == draggedIndex) {
      return;
    }
    if (hoveredIndex >= draggedIndex) {
      hovered.classList.add("drag-hover-bottom");
      return;
    }
    hovered.classList.add("drag-hover-top");
  };

  const dragEnterSub = (e) => {
    if (props.IgnoreFirstHover) {
      props.setIgnoreFirstHover(false);
    }
    removeHover();
    if (props.isSubDrag) {
      if (props.rootDraggedIndex == props.thisIndex) {
        if (e.target.classList.contains("MuiListItemText-root")) {
          textElLastHovered = true;
          return;
        } else if (e.target.classList.contains("MuiSvgIcon-root")) {
          textElLastHovered = true;
          return;
        } else {
          textElLastHovered = false;
          return;
        }
      }
      if (props.rootDraggedIndex < props.thisIndex) {
        if (e.target.classList.contains("MuiListItemText-root")) {
          textElLastHovered = true;
          e.target.parentElement.classList.add("drag-hover-bottom");
          return;
        } else if (e.target.classList.contains("MuiSvgIcon-root")) {
          textElLastHovered = true;
          e.target.parentElement.classList.add("drag-hover-bottom");
          return;
        } else {
          textElLastHovered = false;
          e.target.classList.add("drag-hover-bottom");
          return;
        }
      }
      if (props.rootDraggedIndex > props.thisIndex) {
        if (e.target.classList.contains("MuiListItemText-root")) {
          textElLastHovered = true;
          e.target.parentElement.classList.add("drag-hover-top");
          return;
        } else if (e.target.classList.contains("MuiSvgIcon-root")) {
          textElLastHovered = true;
          e.target.parentElement.classList.add("drag-hover-top");
          return;
        } else {
          textElLastHovered = false;
          e.target.classList.add("drag-hover-top");
          return;
        }
      }
    }

    if (e.target.classList.contains("MuiListItemText-root")) {
      textElLastHovered = true;
      e.target.parentElement.classList.add("sub-drag-hover");
    } else if (e.target.classList.contains("MuiSvgIcon-root")) {
      textElLastHovered = true;
      e.target.parentElement.classList.add("sub-drag-hover");
    } else {
      textElLastHovered = false;
      e.target.classList.add("sub-drag-hover");
    }
  };

  const dragLeaveSub = (e) => {
    if (textElLastHovered) {
      return;
    }
    e.target.classList.remove("sub-drag-hover");
  };

  const dropSub = (e) => {
    e.preventDefault();
    removeHover();
    props.setSubDragIndex(-1);

    const item = e.dataTransfer.getData("item");
    const splitData = item.split(" ", 4);
    var droppedId = splitData[0];
    if (droppedId == "sub") {
      droppedId = splitData[1];
      if (droppedId == props.item.id) {
        return;
      }
      droppedId = splitData[1];
      const open = splitData[2];

      var boolOpen = false;
      if (open === "1") {
        boolOpen = true;
      }
      const subName = item.slice(droppedId.length + 6);
      props.arrangeSubcollection(props.item.id, {
        id: parseInt(droppedId),
        name: subName,
        open: boolOpen,
      });
      return;
    }

    getToken().then((idToken) => {
      const droppedStart = splitData[1];
      const droppedEnding = splitData[2];
      const droppedYoutubeId = splitData[3];
      const droppedName = item.slice(
        droppedYoutubeId.length +
          droppedId.length +
          droppedStart.length +
          droppedEnding.length +
          4
      );
      droppedId = parseInt(droppedId);

      if (props.item.vidList.find((element) => element.id == droppedId)) {
        return;
      }
      if (droppedId == document.getElementsByClassName("selected-item")[0].id) {
        setCurrSubCollection(props.item.name);
      }
      var link = false;
      var linkType = "video";
      if (
        droppedYoutubeId.startsWith("http") ||
        droppedYoutubeId.startsWith("www") ||
        droppedYoutubeId.startsWith("dropbox")
      ) {
        link = true;
        linkType = getFileType(droppedYoutubeId);
      }
      droppedId = parseInt(droppedId);
      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${droppedId}`,
        {
          method: "PUT",
          body: JSON.stringify({ subcollectionId: props.item.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          var newArr = [...props.item.vidList];
          newArr.push({
            link: link,
            linkType: linkType,
            youtube_id: droppedYoutubeId,
            id: droppedId,
            order_no: data.order_no,
            name: droppedName,
            start: Number(droppedStart),
            ending: Number(droppedEnding),
          });
          props.updateVidList(newArr, props.item.id, droppedId);
        })
        .catch((err) => {
          return [];
        });
    });
  };

  const listEndDragLeave = (e) => {};

  const drop = (e) => {
    props.setSubDragIndex(-1);
    removeHover();
    e.preventDefault();
    const item = e.dataTransfer.getData("item");
    const splitData = item.split(" ", 4);
    var droppedId = splitData[0];
    if (droppedId == "sub") {
      return;
    }

    var droppedOnId = e.target.getAttribute("id");
    if (!droppedOnId) {
      droppedOnId = e.target.parentElement.parentElement.id;
    }
    if (droppedId == droppedOnId) {
      return;
    }
    getToken().then((idToken) => {
      const droppedStart = splitData[1];
      const droppedEnding = splitData[2];
      const droppedYoutubeId = splitData[3];
      const droppedName = item.slice(
        droppedYoutubeId.length +
          droppedId.length +
          droppedStart.length +
          droppedEnding.length +
          4
      );
      var link = false;
      if (
        droppedYoutubeId.startsWith("http") ||
        droppedYoutubeId.startsWith("www") ||
        droppedYoutubeId.startsWith("dropbox")
      ) {
        link = true;
      }
      droppedId = parseInt(droppedId);
      //itemElem.style.display = "block";
      if (droppedId == document.getElementsByClassName("selected-item")[0].id) {
        setCurrSubCollection(props.item.name);
      }
      // if the child element is dropped on

      var newArray = props.item.vidList.filter(
        (element) => element.id != droppedId
      );
      var vidIndex = newArray.findIndex((element) => element.id == droppedOnId);
      if (newArray.length != props.item.vidList.length) {
        if (
          props.item.vidList.findIndex((element) => element.id == droppedId) <=
          vidIndex
        ) {
          vidIndex = vidIndex + 1;
          if (vidIndex == newArray.length) {
            //append to the back of the list
            fetch(
              `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${droppedId}`,
              {
                method: "PUT",
                body: JSON.stringify({ subcollectionId: props.item.id }),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return Promise.reject();
                }
                return response.json();
              })
              .then((data) => {
                newArray.push({
                  link: link,
                  start: Number(droppedStart),
                  ending: Number(droppedEnding),
                  youtube_id: droppedYoutubeId,
                  id: droppedId,
                  order_no: data.order_no,
                  name: droppedName,
                });
                props.updateVidList(newArray, props.item.id, droppedId);
              })
              .catch((err) => {
                return [];
              });
            return;
          }
        }
      }
      for (let index = vidIndex; index < newArray.length; index++) {
        newArray[index].order_no = newArray[index].order_no + 1;
      }
      const updatedOrderNo = newArray[vidIndex].order_no - 1;

      fetch(
        `${process.env.REACT_APP_RESOURCE_SERVER}/api/videos/${droppedId}`,
        {
          method: "PUT",
          body: JSON.stringify({
            orderNo: updatedOrderNo,
            subcollectionId: props.item.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })

        .catch((err) => {
          return [];
        });
      newArray.splice(vidIndex, 0, {
        link: link,
        order_no: updatedOrderNo,
        name: droppedName,
        id: droppedId,
        start: Number(droppedStart),
        ending: Number(droppedEnding),
        youtube_id: droppedYoutubeId,
        subcollection_id: props.item.id,
      });
      props.updateVidList(newArray, props.item.id, droppedId);
    });
  };

  return (
    <React.Fragment>
      <ListItem
        id={props.item.id}
        draggable={props.teacher}
        onDragOver={dragOverSub}
        onDragLeave={dragLeaveSub}
        onDragEnter={dragEnterSub}
        onDragStart={subDragStart}
        onDrop={dropSub}
        button
        onClick={handleClick}
        className={
          (props.teacher
            ? "sub-collection teacher-item " + classes.sub
            : "sub-collection " + classes.sub) +
          (props.item.vidList.length <= 0 ? " empty" : "")
        }
      >
        <ListItemIcon>
          <Icon
            icon={folderIcon}
            style={{ color: "#ffffff", fontSize: "18px" }}
          />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={props.item.name}
          className={classes.subText}
        />
        <ExpandMore
          className={"expand-icon " + (props.item.open ? "open" : "closed")}
        />
      </ListItem>
      {props.item.vidList && (
        <Collapse in={props.item.open}>
          <List onDragStart={dragStart} disablePadding>
            {props.item.vidList.length > 0 ? (
              props.item.vidList.map((vid, index) => (
                <ListItem
                  onClick={handleSelect}
                  draggable={props.teacher}
                  key={index}
                  onDragEnter={dragEnter}
                  onDragOver={dragOver}
                  onDrop={drop}
                  id={vid.id}
                  button
                  className={
                    vid.link
                      ? teacherItem +
                        "item-link " +
                        classes.nested +
                        " video-link"
                      : teacherItem + "video-link " + classes.nested
                  }
                >
                  <ListItemText primary={vid.name} />
                </ListItem>
              ))
            ) : (
              <AlertMessage type="info" message="Undirsafnið er tómt" />
            )}
            {props.item.open && (
              <div
                onDragLeave={listEndDragLeave}
                onDragOver={dragOver}
                onDrop={dragListEndDrop}
                onDragEnter={dragListEndEnter}
                className="list-end"
              ></div>
            )}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}
