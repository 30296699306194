import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StudentsList from "./StudentList";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useAuth } from "../auth/AuthContext";
import Select from "@material-ui/core/Select";
import { Icon } from "@iconify/react";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
import starIcon from "@iconify/icons-el/star";
import { withTranslation } from "react-i18next";
import "../i18n";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  userName: {
    color: theme.palette.primary.lightHeaderColor,
    fontSize: 16,
    fontWeight: 600,
  },
  orgName: {
    fontWeight: 600,
  },
}));
const DialogText = withStyles({
  root: {
    marginBottom: "5px",
    marginTop: "10px",
  },
})(DialogContentText);

const StudentsAdminDialog = withTranslation()((props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const { getToken, accountInfo } = useAuth();
  const [teachers, setTeachers] = useState(null);
  const [teacher, setTeacher] = useState(null);

  const getStudents = (uid) => {
    //console.log("get data hér");
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            accountInfo.org +
            "/user/" +
            uid +
            "/student_collections/",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          setStudents(response.data);
          //console.log(response.data);
        });
    });
  };

  const getTeachers = () => {
    if (accountInfo.org != null) {
      //console.log("get data hér");
      getToken().then((idToken) => {
        axios
          .get(
            process.env.REACT_APP_RESOURCE_SERVER +
              "/api/organizations/" +
              accountInfo.org +
              "/licences/",
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          )
          .then((response) => {
            const filteredLicences = response.data.filter((licence) => {
              if (licence.email == "none") {
                return false;
              } else {
                return true;
              }
            });
            setTeachers(filteredLicences);
            if (filteredLicences.length != 0) {
              setTeacher(filteredLicences[0].user_id);
              getStudents(filteredLicences[0].user_id);
            }
          });
      });
    }
  };

  const removeRelation = (id) => {
    getToken().then((idToken) => {
      axios
        .delete(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/student_collections/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data == "ok") {
            getStudents();
          }
        });
    });
  };
  const handleClickOpen = () => {
    if (accountInfo.role == "admin") {
      getTeachers();
      setOpen(true);
    }
  };

  const handleTeacherChange = (newTeacher) => {
    setTeacher(newTeacher);
    getStudents(newTeacher);
  };

  const handleClose = () => {
    //setSubcollectionName("");
    setOpen(false);
    props.closeDrawer();
  };

  return (
    <React.Fragment>
      {accountInfo.name && (
        <ListItem
          button={accountInfo.role == "admin" ? true : false}
          onClick={handleClickOpen}
        >
          <ListItemIcon>
            <Icon
              icon={starIcon}
              style={{
                marginLeft: "1px",
                color: "#f50057",
                fontSize: "22px",
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={accountInfo.name}
            classes={{ primary: classes.orgName }}
          />
        </ListItem>
      )}
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.t("teachersAndStudents")}: {accountInfo.name}
        </DialogTitle>
        <DialogContent>
          <DialogText>{props.t("teacher")}:</DialogText>
          <Select
            id="collection-select"
            value={teacher}
            onChange={(e) => handleTeacherChange(e.target.value)}
          >
            {teachers &&
              teachers.map((teacher) => (
                <MenuItem value={teacher.user_id}>
                  {teacher.email +
                    " - " +
                    (teacher.display_name
                      ? teacher.display_name
                      : props.t("teacher"))}{" "}
                </MenuItem>
              ))}
          </Select>
          {teacher && students.length != 0 && (
            <React.Fragment>
              <DialogText>{props.t("students")}:</DialogText>
              <StudentsList
                removeRelation={removeRelation}
                students={students}
              />
            </React.Fragment>
          )}
          {students.length == 0 && (
            <DialogText>{props.t("noRegisteredStudents")}</DialogText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default StudentsAdminDialog;
