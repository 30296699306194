import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import StudentItem from './StudentItem'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-6px",
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function StudentList(props) {
  const classes = useStyles();
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
    {props.students && props.students.map((student) => 
      {
        return(<StudentItem removeRelation={props.removeRelation} student={student}></StudentItem>)
      })
    }
    </List>
  );
}