import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "15px 0",
    borderRadius: 4,
  },
}));

export default function SimpleAlerts(props) {
  const classes = useStyles();

  return (
    <div className={classes.root + " " + props.cssClass + " alert-message"}>
      <Alert
        severity={props.type}
        iconMapping={{ info: <InfoRoundedIcon fontSize="inherit" /> }}
      >
        {props.message}
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
      </Alert>
    </div>
  );
}
