import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, withTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Subcollection } from "./Subcollection";
import { useAuth } from "../auth/AuthContext";
import { getFileType } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  contextMenuItem: {},
  contextMenu: {},
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

export default function CollectionSideMenu(props) {
  const classes = useStyles();
  const { setCurrSubCollection } = useAuth();
  var textElLastHovered = false;
  const [draggingInSubcollection, setDraggingInSubcollection] = useState(-1);
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const [isSubDrag, setIsSubDrag] = useState(false);
  const [ignoreFirstHover, setIgnoreFirstHover] = useState(false);

  var teacherItem = "";
  if (props.teacher) {
    teacherItem = "teacher-item ";
  }

  const dragStart = (e) => {
    setIgnoreFirstHover(true);

    var clickedId = e.target.id;
    if (!clickedId) {
      clickedId = e.target.parentElement.parentElement.id;
    }
    const index = props.items.findIndex((element) => element.id == clickedId);
    setDraggingInSubcollection(-1);
    setDraggedIndex(index);
    const transferItem = props.items[index];
    var element = document.getElementById("dragPoser");
    document.getElementById("dragText").innerHTML = transferItem.name;
    e.dataTransfer.setDragImage(element, -10, -10);
    e.dataTransfer.setData(
      "item",
      transferItem.id +
        " " +
        transferItem.start +
        " " +
        transferItem.ending +
        " " +
        transferItem.youtube_id +
        " " +
        transferItem.name
    );
  };

  const removeHover = (e) => {
    var hovered = document.getElementsByClassName("sub-drag-hover");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("sub-drag-hover");
    }
    hovered = document.getElementsByClassName("drag-hover-top");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-top");
    }
    hovered = document.getElementsByClassName("drag-hover-bottom");
    for (var i = 0; i < hovered.length; i++) {
      hovered[i].classList.remove("drag-hover-bottom");
    }
  };
  const dragEnd = (e) => {
    removeHover();
    setDraggedIndex(-1);
  };

  const dragListEndEnter = (e) => {
    if (isSubDrag) {
      return;
    }
    if (props.items.length != 0) {
      if (!props.items[0].hasOwnProperty("max_order_no")) {
        return;
      }
    }
    textElLastHovered = true;
    removeHover();
    if (draggingInSubcollection != -1) {
      e.target.classList.add("drag-hover-bottom");
    }
  };

  const setSubDragIndex = (id) => {
    if (id === -1) {
      setDraggedIndex(-1);
      setIsSubDrag(false);
      return;
    }
    setDraggedIndex(props.items.findIndex((element) => element.id == id));
    setIsSubDrag(true);
  };

  const dragListEndDrop = (e) => {
    removeHover();
    setIsSubDrag(false);
    if (draggedIndex != -1) {
      return;
    }
    e.preventDefault();
    const item = e.dataTransfer.getData("item");
    const splitData = item.split(" ", 4);
    var droppedId = splitData[0];
    if (droppedId == "sub") {
      setSubDragIndex(-1);
      return;
    }

    const droppedStart = splitData[1];
    const droppedEnding = splitData[2];
    const droppedYoutubeId = splitData[3];
    const droppedName = item.slice(
      droppedYoutubeId.length +
        droppedId.length +
        droppedStart.length +
        droppedEnding.length +
        4
    );
    droppedId = parseInt(droppedId);

    if (droppedId == document.getElementsByClassName("selected-item")[0].id) {
      setCurrSubCollection(null);
    }

    props.addVid(props.items[0].id, {
      start: Number(droppedStart),
      ending: Number(droppedEnding),
      youtube_id: droppedYoutubeId,
      name: droppedName,
      id: droppedId,
    });
    //props.addVidToBack({ start: Number(droppedStart), ending: Number(droppedEnding), youtube_id: droppedYoutubeId, name: droppedName, id: droppedId})
  };

  const handleSelect = (e) => {
    var selectedElement = e.target;
    var clickedId = e.target.id;
    if (!clickedId) {
      selectedElement = e.target.parentElement.parentElement;
      clickedId = selectedElement.id;
    }
    const selectedVideo = props.items.find(
      (element) => element.id == clickedId
    );
    props.clearAndSetSelected(selectedVideo);
    selectedElement.classList.add("selected-item");
    setCurrSubCollection(null);
  };

  const dragEnter = (e) => {
    if (isSubDrag) {
      return;
    }

    if (ignoreFirstHover) {
      setIgnoreFirstHover(false);
      return;
    }
    //if the text child element is entered
    //we give the class to its parent
    textElLastHovered = false;
    var hovered = e.target;
    if (!hovered.id) {
      textElLastHovered = true;
      hovered = e.target.parentElement.parentElement;
    }
    const hoveredIndex = props.items.findIndex(
      (element) => element.id == hovered.id
    );
    removeHover();
    if (draggedIndex == -1) {
      hovered.classList.add("drag-hover-top");
      return;
    }
    if (hoveredIndex == draggedIndex) {
      return;
    }
    if (hoveredIndex >= draggedIndex) {
      hovered.classList.add("drag-hover-bottom");
      return;
    }
    hovered.classList.add("drag-hover-top");
  };

  const drop = (e) => {
    setIsSubDrag(false);
    removeHover();
    e.preventDefault();
    const item = e.dataTransfer.getData("item");
    const splitData = item.split(" ", 4);
    var droppedId = splitData[0];
    if (droppedId == "sub") {
      droppedId = splitData[1];
      const open = splitData[2];
      const subName = item.slice(droppedId.length + 6);

      var droppedOnId = e.target.getAttribute("id");
      if (!droppedOnId) {
        droppedOnId = e.target.parentElement.parentElement.id;
      }

      var boolOpen = false;
      if (open === "1") {
        boolOpen = true;
      }

      props.arrangeSubcollection(Number(droppedOnId), {
        id: parseInt(droppedId),
        name: subName,
        open: boolOpen,
      });
      setSubDragIndex(-1);
      return;
    }
    const droppedStart = splitData[1];
    const droppedEnding = splitData[2];
    const droppedYoutubeId = splitData[3];
    const droppedName = item.slice(
      droppedYoutubeId.length +
        droppedId.length +
        droppedStart.length +
        droppedEnding.length +
        4
    );
    droppedId = parseInt(droppedId);

    droppedOnId = e.target.getAttribute("id");
    if (!droppedOnId) {
      droppedOnId = e.target.parentElement.parentElement.id;
    }
    if (droppedId == droppedOnId) {
      return;
    }
    var linkType = "video";
    var link = false;
    if (
      droppedYoutubeId.startsWith("http") ||
      droppedYoutubeId.startsWith("www") ||
      droppedYoutubeId.startsWith("dropbox")
    ) {
      linkType = getFileType(droppedYoutubeId);
      link = true;
    }

    if (droppedId == document.getElementsByClassName("selected-item")[0].id) {
      setCurrSubCollection(null);
    }
    props.addVid(droppedOnId, {
      link: link,
      linkType: linkType,
      youtube_id: droppedYoutubeId,
      name: droppedName,
      id: droppedId,
      start: Number(droppedStart),
      ending: Number(droppedEnding),
    });
  };

  useEffect(() => {
    props.updateSelected();
  }, [props.items]);

  const dragOver = (e) => {
    if (!isSubDrag) {
      e.preventDefault();
    }
  };

  return (
    <div
      className="video-list-container"
      onContextMenu={(e) => e.preventDefault()}
    >
      <List
        id="main"
        className={"video-list " + classes.root}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <div
          onDragOver={dragOver}
          onDrop={dragListEndDrop}
          onDragEnter={dragListEndEnter}
          className="list-end"
        ></div>
        {props.items.map((item, index) =>
          item.hasOwnProperty("max_order_no") ? (
            <Subcollection
              draggingInSubcollection={draggingInSubcollection}
              setDraggingInSubcollection={setDraggingInSubcollection}
              updateVidList={props.updateVidList}
              key={index}
              thisIndex={index}
              ignoreFirstHover={ignoreFirstHover}
              setIgnoreFirstHover={setIgnoreFirstHover}
              isSubDrag={isSubDrag}
              rootDraggedIndex={draggedIndex}
              setSubDragIndex={setSubDragIndex}
              toggleOpen={props.toggleOpen}
              clearAndSetSelected={props.clearAndSetSelected}
              arrangeSubcollection={props.arrangeSubcollection}
              item={item}
              teacher={props.teacher}
            ></Subcollection>
          ) : (
            <ListItem
              key={index}
              onDragEnd={dragEnd}
              onClick={handleSelect}
              draggable={props.teacher}
              id={item.id}
              onDragOver={dragOver}
              onDrop={drop}
              onDragStart={dragStart}
              onDragEnter={dragEnter}
              className={
                item.link
                  ? teacherItem + "item-link video-link"
                  : teacherItem + "video-link"
              }
              button
            >
              <ListItemText primary={item.name} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
}
//<div onDrop={dragListEndDrop} onDragEnter={dragListEndEnter} className="list-end"></div>*/
