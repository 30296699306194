import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import CollectionContainer from "./CollectionContainer";
import Collections from "./Collections";
import PlainVideoContainer from "../PlainVideoContainer";
import DownloadLink from "./DownloadLink";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { useAuth } from "../auth/AuthContext";
import { Icon, InlineIcon } from "@iconify/react";
import starIcon from "@iconify/icons-el/star";
import VideoBackground from "../VideoBackground";
import { NoDataImage } from "../assets";
import { isMobileOnly } from "react-device-detect";
import Backdrop from "@material-ui/core/Backdrop";
import "../i18n";
import { withTranslation } from "react-i18next";

var drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    height: "0px !important",
    backgroundColor: "transparent !important",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: "0px !important",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    width: "40px",
    height: "40px",
    marginLeft: "3.7px",
    color: "#605DF9",
    marginTop: 14,
    background: "#232323", // TODO THEME
    borderRadius: "0px 50px 50px 0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      padding: 22,
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    minHeight: "100vh",
    minHeight: "-webkit-fill-available",
    overflow: "hidden",
  },
  drawerHeader: {
    height: "0px !important",
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  content: {
    marginTop: "-100px",
    width: "100%",
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentShift: {
    marginTop: "-100px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    background: theme.palette.background.backdrop,
    zIndex: 1001,
  },
}));

// The Help Button

// <IconButton
// style={{
//   fontStyle: "bold",
//   fontSize: "14px",
// }}
// >
// <HelpOutlineIcon
//   style={{
//     height: "20px",
//     width: "20px",
//   }}
// ></HelpOutlineIcon>
// </IconButton>

const PersistentDrawerLeft = withTranslation()((props) => {
  const [changeInSelected, setChangeInSelected] = React.useState(false);
  const [selected, setSelected] = React.useState({
    youtube_id: "afzmwAKUppU",
    name: "Welcome to youlearn!",
    id: -2,
    start: 0,
    ending: 10,
  });
  const theme = useTheme();
  const classes = useStyles();
  const [collection, setCollection] = React.useState({
    name: "name",
    teacher: false,
  });
  const { getToken, accountInfo, currSubCollection, displayMessage } =
    useAuth();
  const [collectionId, setCollectionId] = React.useState(-1);
  const [browseVids, setBrowseVids] = React.useState("none");
  const [open, setOpen] = React.useState(!isMobileOnly && !props.share);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar style={{ marginTop: "-64px" }}></Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
      </AppBar>
      {isMobileOnly && (
        <Backdrop
          open={open}
          onClick={handleDrawerClose}
          className={classes.backdrop}
        />
      )}

      <Drawer
        className={classes.drawer + " collection-drawer-container"}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper + " collection-drawer",
        }}
      >
        <div className={classes.drawerHeader + " drawer-header"}>
          <h3 className="logo">
            <Logo /> YOULEARN
          </h3>
          <IconButton className="close-icon" onClick={handleDrawerClose}>
            <MenuOpenIcon />
          </IconButton>
        </div>
        <span>
          <p
            style={{
              float: "right",
              marginTop: "-30px",
              marginRight: "13px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            <Icon
              icon={starIcon}
              style={{
                marginBottom: "-1.5px",
                marginRight: "5px",
                color: "#f50057",
                fontSize: "15px",
              }}
            />
            {props.share
              ? "Shared Item"
              : props.explore
              ? "Explore"
              : accountInfo.name}
          </p>
        </span>
        {
          <CollectionContainer
            share={props.share}
            explore={props.explore}
            browseVids={browseVids}
            setBrowseVids={setBrowseVids}
            contextItemId={props.contextItemId}
            setSubMenuState={props.setSubMenuState}
            subMenuState={props.subMenuState}
            contextState={props.contextState}
            changeInSelected={changeInSelected}
            setChangeInSelected={setChangeInSelected}
            setCategory={props.setCategory}
            category={props.category}
            editObj={props.editObj}
            addedVid={props.addedVid}
            collectionName={collection.name}
            collectionId={collectionId}
            selected={selected}
            setSelected={setSelected}
            teacher={collection.teacher}
          ></CollectionContainer>
        }
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open && !isMobileOnly,
        })}
      >
        <div className={classes.drawerHeader} />
        {!props.share && (
          <Collections
            explore={props.explore}
            contextItemIndex={props.contextItemIndex}
            setCopyColMenuState={props.setCopyColMenuState}
            copyColMenuState={props.copyColMenuState}
            contextState={props.contextState}
            setCollection={setCollection}
            setCollectionId={setCollectionId}
            category={props.category}
            editObj={props.editObj}
            addedVid={props.addedVid}
            setCategory={props.setCategory}
            userId={props.userId}
          />
        )}
        <div droppable="true" className="dark-background">
          <VideoBackground
            cssClass={
              !selected.link && selected.id !== -1 && "player-background"
            }
          >
            {selected.link ? (
              <DownloadLink
                currSubCollection={currSubCollection}
                collectionName={collection.name}
                item={selected}
                changeInSelected={changeInSelected}
                setBrowseVids={setBrowseVids}
                browseVids={browseVids}
              ></DownloadLink>
            ) : selected.id == -1 || selected.id == -2 ? (
              <div className="no-data-container">
                <NoDataImage />
              </div>
            ) : (
              <PlainVideoContainer
                setOpenSignIn={props.setOpenSignIn}
                t={props.t}
                share={props.share}
                explore={props.explore}
                displayMessage={displayMessage}
                segmentToEdit={props.segmentToEdit}
                setSegmentAction={props.setSegmentAction}
                segmentAction={props.segmentAction}
                setSegmentToEdit={props.setSegmentToEdit}
                currSubCollection={currSubCollection}
                collectionName={collection.name}
                browseVids={browseVids}
                setBrowseVids={setBrowseVids}
                setAddedVid={props.setAddedVid}
                attachmentChange={props.attachmentChange}
                changeInSelected={changeInSelected}
                getToken={getToken}
                teacher={collection.teacher}
                student={!collection.teacher}
                video={selected}
                drawerOpen={open}
                setDrawerOpen={setOpen}
              ></PlainVideoContainer>
            )}
          </VideoBackground>
        </div>
      </main>
    </div>
  );
});
export default PersistentDrawerLeft;
