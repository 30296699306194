import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as CloseIcon } from "../../assets/icons/dialog-close-icon.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 24,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 0,
      width: 25,
    },
  },
  closeIcon: {
    "& path": {
      stroke: theme.palette.type === "dark" ? "#D6D5FD" : "#43418D",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" component="h1" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "10px 24px",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    borderRadius: 32,
    [theme.breakpoints.down("sm")]: {
      margin: 15,
      width: "100%",
      borderRadius: 22,
    },
  },
  title: {
    margin: "0 24px",
    padding: 0,
    paddingBottom: 16,
    paddingTop: 24,
    borderBottom:
      theme.palette.type === "dark"
        ? "1px solid rgba(255, 255, 255, 0.15)"
        : "1px solid rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 10,
      paddingTop: 16,
      margin: "0 15px",
    },
  },
  primaryBtn: {
    background: theme.palette.primary.main,
  },
  secondaryBtn: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    label: {
      color: "#D6D5FD", // TODO THEME
    },
  },
}));

export default function DialogLayout({
  handleClose,
  handleSave,
  displayPrimaryBtn,
  displaySecondaryBtn,
  primaryBtnText,
  secondaryBtnText,
  title,
  maxWidth,
  open,
  children,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      disableEnforceFocus
      onClose={handleClose}
      // className={classes.dialogContainer}
      aria-labelledby="Add new video"
      PaperProps={{ className: classes.dialogContainer }}
      open={open}
    >
      <DialogTitle
        id="dialog-title"
        className={classes.title}
        onClose={handleClose}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>

      <DialogActions>
        {displaySecondaryBtn && (
          <Button
            onClick={() => handleClose()}
            className={classes.secondaryBtn}
          >
            {secondaryBtnText}
          </Button>
        )}
        {displayPrimaryBtn && (
          <Button
            onClick={() => handleSave()}
            className={classes.primaryBtn}
            id={"confirm"}
          >
            {primaryBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
