import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialogTitle from "@material-ui/core/DialogTitle";
import DepartmentTable from "./DepartmentTable";
import MemberInput from "./MemberInput";
import axios from "axios";
import { useAuth } from "../auth/AuthContext";
import { Icon } from "@iconify/react";
import organizationLine from "@iconify/icons-clarity/organization-line";
import { withTranslation } from "react-i18next";
import "../i18n";

const DepartmentAccessDialog = withTranslation()((props) => {
  const { getToken, accountInfo } = useAuth();
  const [staffMembers, setStaffMembers] = React.useState([]);
  const [staffMember, setStaffMember] = React.useState(null);
  const [adding, setAdding] = React.useState(false);
  const [dept, setDept] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);

  const getData = () => {
    getToken().then((idToken) => {
      axios
        .get(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/organizations/" +
            accountInfo.org +
            "/department_memberships/",
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          setStaffMembers(response.data.staffMembers);
          setDepartments(response.data.departments);
        });
    });
  };

  const handleClickOpen = () => {
    getData();
    setOpen(true);
  };

  const handleDeptChange = (event) => {
    setDept(event.target.value);
  };

  const handleStaffMemberChange = (email) => {
    for (let index = 0; index < staffMembers.length; index++) {
      if (staffMembers[index].email == email) {
        setStaffMember(staffMembers[index]);
        return;
      }
    }
  };

  const handleStartAdding = () => {
    setDept(
      departments.filter((department) => {
        for (let index = 0; index < staffMember.memberships.length; index++) {
          if (department.name == staffMember.memberships[index].name) {
            return false;
          }
        }
        return true;
      })[0].id
    );
    setAdding(true);
  };

  const handleClose = () => {
    props.closeDrawer();
    setOpen(false);
  };

  const handleAccessActivation = () => {
    setAdding(false);
    var body = { deptId: dept, email: staffMember.email };
    getToken().then((idToken) => {
      axios
        .post(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/department_memberships/",
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          var memberIndex = staffMembers.findIndex(
            (member) => staffMember.email == member.email
          );
          var updatedMemberships = [
            ...staffMembers[memberIndex].memberships,
            {
              department_id: dept,
              name: response.data.name,
              membership_id: response.data.id,
            },
          ];
          var updatedStaffMembers = staffMembers;
          updatedStaffMembers[memberIndex].memberships = [
            ...updatedMemberships,
          ];
          setStaffMembers([...updatedStaffMembers]);
          setStaffMember(updatedStaffMembers[memberIndex]);
        });
    });
  };

  const handleAccessDeactivation = (id) => {
    getToken().then((idToken) => {
      axios
        .delete(
          process.env.REACT_APP_RESOURCE_SERVER +
            "/api/department_memberships/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data == "membership deleted") {
            var memberIndex = staffMembers.findIndex(
              (member) => staffMember.email == member.email
            );
            var updatedMemberships = staffMembers[
              memberIndex
            ].memberships.filter(
              (memberships) => id != memberships.membership_id
            );
            var updatedStaffMembers = staffMembers;
            updatedStaffMembers[memberIndex].memberships = [
              ...updatedMemberships,
            ];
            setStaffMembers([...updatedStaffMembers]);
            setStaffMember(updatedStaffMembers[memberIndex]);
          }
        });
    });
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          {" "}
          <Icon
            icon={organizationLine}
            style={{ marginLeft: "0.3px", fontSize: "23px" }}
          />
        </ListItemIcon>
        <ListItemText primary={props.t("departmentReg")} />
      </ListItem>
      <Dialog
        open={open}
        fullWidth="true"
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.t("departmentReg")}: {accountInfo.name}
        </DialogTitle>

        <MemberInput
          handleStaffMemberChange={handleStaffMemberChange}
          staffMembers={staffMembers}
        ></MemberInput>

        <DialogContent>
          {staffMember && staffMembers && (
            <DepartmentTable
              dept={dept}
              setDept={setDept}
              handleAccessDeactivation={handleAccessDeactivation}
              handleAccessActivation={handleAccessActivation}
              accessedDepartments={staffMember.memberships}
              departments={departments}
              adding={adding}
              handleDeptChange={handleDeptChange}
              staffMemberName={
                staffMember.display_name
                  ? staffMember.display_name
                  : staffMember.email
              }
              setAdding={setAdding}
              handleStartAdding={handleStartAdding}
            ></DepartmentTable>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default DepartmentAccessDialog;
