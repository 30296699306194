import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import "./i18n";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = withTranslation()((props) => {
  const classes = useStyles();

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        endIcon={props.buttonIcon}
        className={props.buttonClass}
      >
        {props.buttonText}
      </Button>
      <Dialog
        fullScreen
        open={props.open}
        className="login-dialog"
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div
              className={classes.title + " close-modal"}
              onClick={handleClose}
              aria-label="close"
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <KeyboardBackspaceIcon />
              </IconButton>
              {props.t("back")}
            </div>
          </Toolbar>
        </AppBar>
        {props.children}
      </Dialog>
    </div>
  );
});

export default FullScreenDialog;
