import Player from "@vimeo/player";

export default function VimeoInterface(props) {
  this.currentTime = 0;
  this.playbackRate = 1;
  this.playbackState = 2;
  this.time = -4;
  this.player = new Player(props.playerId, {
    url: "https://vim" + props.videoId,
    title: false,
    byline: false,
  });
  this.player.on("playbackratechange", (e) => {
    this.playbackRate = e.playbackRate;
    props.onPlaybackRateChange({ data: e.playbackRate });
  });
  this.player.on("play", () => {
    this.playbackState = 1;
    props.onPlayerStateChange({ data: 1 });
  });
  this.player.on("pause", () => {
    this.playbackState = 2;
    props.onPlayerStateChange({ data: 2 });
  });
  this.player.on("seeked", () => {
    props.onPlayerStateChange({ data: this.playbackState });
  });
  this.player.on("timeupdate", (e) => {
    this.currentTime = e.seconds;
  });
  this.player.on("loaded", () => {
    this.player.getDuration().then((result) => {
      this.time = result;
      props.onPlayerReady({ target: this });
    });
  });
  this.getAvailablePlaybackRates = () => {
    return [
      "0.5",
      "0.6",
      "0.65",
      "0.7",
      "0.75",
      "0.8",
      "0.85",
      "0.9",
      "0.95",
      "1",
      "1.05",
      "1.2",
      "1.25",
      "1.5",
      "1.75",
      "2",
    ];
  };

  this.getDuration = () => {
    return this.time;
  };

  this.getPlaybackRate = () => {
    return this.playbackRate;
  };

  this.getCurrentTime = () => {
    return this.currentTime;
  };

  this.getPlayerState = () => {
    return this.playbackState;
  };

  this.playVideo = () => {
    this.player.play();
  };
  this.setPlaybackRate = (value) => {
    this.player.setPlaybackRate(Number(value));
  };
  this.pauseVideo = () => {
    this.player.pause();
  };

  this.seekTo = (seconds) => {
    if (this.player) {
      this.player.setCurrentTime(seconds);
    }
  };

  this.destroy = () => {
    this.player.destroy();
  };
}
